/* eslint-disable react/prop-types,react/no-unescaped-entities */
import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import StepContent from "@material-ui/core/StepContent"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import IssueTypeQuestion from "./IssueTypeQuestion"
import YesNoQuestion from "./YesNoQuestion"
import InputQuestion from "./InputQuestion"
import InputDropdown from "./InputDropdown"
import InputDate from "./InputDate"
import DeviceIdInput from "./DeviceIdInput"
import CommandQueue from "./CommandQueue"
import FailPositionQuestion from "./FailPositionQuestion"
import TaskComment from "./TaskComment"
import TaskSummary from "./TaskSummary"
import PropTypes from "prop-types"
import BoardVendorForm from "./BoardVendorForm"
import BOMAlternateForm from "./BOMAlternateForm"
import BoardMiscForm from "./BoardMiscForm"
import BareBoardVersionForm from "./BareBoardVersionForm"

const ChargingPanelReplacementForm = React.memo(function ChargingPanelReplacementForm({
	taskInfo,
	badPanelId,
	setTaskInfo,
	goodPanelId,
}) {
	return (
		<div>
			<p>Replace the bad Charging Panel and recover for RMA.</p>
			<InputQuestion
				taskInfo={taskInfo}
				value={badPanelId}
				setTaskInfo={setTaskInfo}
				name={"badPanelId"}
				label={"Bad Panel ID"}
			/>
			<InputQuestion
				taskInfo={taskInfo}
				value={goodPanelId}
				setTaskInfo={setTaskInfo}
				name={"goodPanelId"}
				label={"Good Panel ID"}
			/>
		</div>
	)
})

const RecordNodeReplacementForm = React.memo(function RecordNodeReplacementForm({ takeForRMA }) {
	return (
		<div>
			<p>Record Node Replacement.{takeForRMA ? " Take for RMA." : ""}</p>
			<DeviceIdInput />
		</div>
	)
})

const TypeOfDamageForm = React.memo(function TypeOfDamageForm({ taskInfo, setTaskInfo, damageType }) {
	return (
		<div>
			<p>What is the type of damage?</p>
			<InputDropdown
				options={["Physical", "Water"]}
				taskInfo={taskInfo}
				value={damageType}
				setTaskInfo={setTaskInfo}
				name={"damageType"}
				label={"Damage Type"}
			/>
		</div>
	)
})

const ComponentDamagedForm = React.memo(function ComponentDamagedForm({
	componentName,
	activeStep,
	isDamaged,
	setTaskInfo,
}) {
	return (
		<div>
			<p>Is there any physical or water damage to the {componentName}?</p>
			<YesNoQuestion step={activeStep} value={isDamaged} setTaskInfo={setTaskInfo} name={"isDamaged"} />
		</div>
	)
})
ComponentDamagedForm.propTypes = {
	componentName: PropTypes.string.isRequired,
}

// REASON: Subset of TaskWizard prop types
// eslint-disable-next-line react/prop-types
const RMABadBatteryForm = React.memo(function RMABadBatteryForm({ useBatteryRMA, setUseBatteryRMA, rmaNumB }) {
	return (
		<>
			{useBatteryRMA ? (
				<div>
					<p>Label bad Battery and take for RMA.</p> <p>RMA # : {rmaNumB}</p>
				</div>
			) : null}
			<Button onClick={() => setUseBatteryRMA(!useBatteryRMA)} variant="contained" color="primary">
				{useBatteryRMA ? "Skip Battery RMA" : "Do Battery RMA"}
			</Button>
		</>
	)
})

// REASON: Subset of TaskWizard prop types
// eslint-disable-next-line react/prop-types
const ChargingPanelTroubleshootingForm = React.memo(function ChargingPanelTroubleshootingForm({
	panelOn,
	currentVoltageGood,
	taskInfo,
	setTaskInfo,
	voltage,
	current,
}) {
	return (
		<div>
			{panelOn === "yes" ? (
				<p>
					Unplug the Charging Panel from the node and test its open circuit voltage (VOC) and ​its short circuit current
					(ISC)
				</p>
			) : (
				<p>Test the Charging Panel's open circuit voltage (VOC) and ​its short circuit current (ISC)</p>
			)}
			<p>Is the current and voltage good?</p>
			<p>36 - 60 VOC and .25 - 2 ISC in direct sunlight and sunny conditions.</p>
			<YesNoQuestion value={currentVoltageGood} setTaskInfo={setTaskInfo} name={"currentVoltageGood"} />
			<InputQuestion
				taskInfo={taskInfo}
				value={voltage}
				setTaskInfo={setTaskInfo}
				name={"voltage"}
				label={"Open Circuit Voltage (VOC)"}
			/>
			<InputQuestion
				taskInfo={taskInfo}
				value={current}
				setTaskInfo={setTaskInfo}
				name={"current"}
				label={"Short Circuit Current (ISC)"}
			/>
		</div>
	)
})

const BatteryReplacementForm = React.memo(function BatteryReplacementForm({
	taskInfo,
	setTaskInfo,
	badBatteryId,
	goodBatteryId,
	badBatteryVendor,
	goodBatteryVendor,
}) {
	return (
		<div>
			<p>Replace the Battery and record the Battery ID and vendor.</p>
			<InputQuestion
				taskInfo={taskInfo}
				value={badBatteryId}
				setTaskInfo={setTaskInfo}
				name={"badBatteryId"}
				label={"Bad Battery ID"}
			/>
			<InputDropdown
				options={["Longway", "Ritar", "Power Kingdom"]}
				taskInfo={taskInfo}
				value={badBatteryVendor}
				setTaskInfo={setTaskInfo}
				name={"badBatteryVendor"}
				label={"Bad Battery Vendor"}
			/>
			<InputQuestion
				taskInfo={taskInfo}
				value={goodBatteryId}
				setTaskInfo={setTaskInfo}
				name={"goodBatteryId"}
				label={"Good Battery ID"}
			/>
			<InputDropdown
				options={["Longway", "Ritar", "Power Kingdom"]}
				taskInfo={taskInfo}
				value={goodBatteryVendor}
				setTaskInfo={setTaskInfo}
				name={"goodBatteryVendor"}
				label={"Good Battery Vendor"}
			/>
		</div>
	)
})

const RecordActuatorForm = React.memo(function RecordActuatorForm({
	taskInfo,
	setTaskInfo,
	goodActuatorModelNumber,
	goodActuatorProductionDate,
	goodActuatorVendorInitial,
	goodActuatorSerialNum,
}) {
	return (
		<div>
			<p>Record actuator, fill out the following fields for the actuator:</p>
			<InputDropdown
				options={actuatorModels}
				taskInfo={taskInfo}
				setTaskInfo={setTaskInfo}
				value={goodActuatorModelNumber}
				name={"goodActuatorModelNumber"}
				label={"Model Number"}
			/>
			<InputDate
				_taskInfo={taskInfo}
				value={goodActuatorProductionDate}
				setTaskInfo={setTaskInfo}
				name={"goodActuatorProductionDate"}
				_label={"Production Date"}
			/>
			<InputDropdown
				options={vendorInitial}
				taskInfo={taskInfo}
				value={goodActuatorVendorInitial}
				setTaskInfo={setTaskInfo}
				name={"goodActuatorVendorInitial"}
				label={"Vendor Initial"}
			/>
			<InputQuestion
				taskInfo={taskInfo}
				value={goodActuatorSerialNum}
				setTaskInfo={setTaskInfo}
				name={"goodActuatorSerialNum"}
				label={"Serial Number"}
			/>
		</div>
	)
})

const DefectiveActuatorForm = React.memo(function DefectiveActuatorForm({
	taskInfo,
	setTaskInfo,
	badActuatorModelNumber,
	badActuatorProductionDate,
	badActuatorVendorInitial,
	badActuatorSerialNum,
	goodActuatorModelNumber,
	goodActuatorProductionDate,
	goodActuatorVendorInitial,
	goodActuatorSerialNum,
}) {
	return (
		<div>
			<p>This actuator is defective, please fill out the following fields for the defective actuator:</p>
			<InputDropdown
				options={actuatorModels}
				taskInfo={taskInfo}
				value={badActuatorModelNumber}
				setTaskInfo={setTaskInfo}
				name={"badActuatorModelNumber"}
				label={"Model Number"}
			/>
			<InputDate
				_taskInfo={taskInfo}
				value={badActuatorProductionDate}
				setTaskInfo={setTaskInfo}
				name={"badActuatorProductionDate"}
				_label={"Production Date"}
			/>
			<InputDropdown
				options={vendorInitial}
				taskInfo={taskInfo}
				value={badActuatorVendorInitial}
				setTaskInfo={setTaskInfo}
				name={"badActuatorVendorInitial"}
				label={"Vendor Initial"}
			/>
			<InputQuestion
				taskInfo={taskInfo}
				value={badActuatorSerialNum}
				setTaskInfo={setTaskInfo}
				name={"badActuatorSerialNum"}
				label={"Serial Number"}
			/>
			<p>Replace actuator with new spare, fill out the following fields for the replacement actuator:</p>
			<InputDropdown
				options={actuatorModels}
				taskInfo={taskInfo}
				value={goodActuatorModelNumber}
				setTaskInfo={setTaskInfo}
				name={"goodActuatorModelNumber"}
				label={"Model Number"}
			/>
			<InputDate
				_taskInfo={taskInfo}
				value={goodActuatorProductionDate}
				setTaskInfo={setTaskInfo}
				name={"goodActuatorProductionDate"}
				_label={"Production Date"}
			/>
			<InputDropdown
				options={vendorInitial}
				taskInfo={taskInfo}
				value={goodActuatorVendorInitial}
				setTaskInfo={setTaskInfo}
				name={"goodActuatorVendorInitial"}
				label={"Vendor Initial"}
			/>
			<InputQuestion
				taskInfo={taskInfo}
				value={goodActuatorSerialNum}
				setTaskInfo={setTaskInfo}
				name={"goodActuatorSerialNum"}
				label={"Serial Number"}
			/>
		</div>
	)
})

const InspectCableDamageForm = React.memo(function InspectCableDamageForm({ activeStep, fixedWiring, setTaskInfo }) {
	return (
		<div>
			<p>Visually inspect for damage to cable. If cable is damaged but repairable, fix issue with splice kit.</p>
			<p>Did you have to fix cabling?</p>
			<YesNoQuestion step={activeStep} value={fixedWiring} setTaskInfo={setTaskInfo} name={"fixedWiring"} />
		</div>
	)
})
RecordNodeReplacementForm.propTypes = {
	takeForRMA: PropTypes.bool.isRequired,
}

const PleaseRunCommandForm = React.memo(function PleaseRunCommandForm({
	commandName,
	friendlyCommandName,
	commandQueue,
	taskInfo,
	setTaskInfo,
}) {
	return (
		<div>
			<p>
				Please run the <i>{friendlyCommandName || commandName}</i> command.
			</p>
			<p>Was the command successfully run?</p>
			<CommandQueue commandQueue={commandQueue} taskInfo={taskInfo} setTaskInfo={setTaskInfo} command={commandName} />
		</div>
	)
})
PleaseRunCommandForm.propTypes = {
	commandName: PropTypes.string.isRequired,
	friendlyCommandName: PropTypes.string,
}

const TestActuatorWithTableRotatorForm = React.memo(function TestActuatorWithTableRotatorForm({
	activeStep,
	movesEastWest,
	setTaskInfo,
}) {
	return (
		<>
			<p>Unplug the Actuator and test with Table Rotator. Does it move East and West (extend and retract)?</p>
			<YesNoQuestion step={activeStep} value={movesEastWest} setTaskInfo={setTaskInfo} name={"movesEastWest"} />
		</>
	)
})

const useStyles = makeStyles((theme) => ({
	root: {
		width: "60%",
		margin: "auto",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	title: {
		textAlign: "center",
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
		[theme.breakpoints.down("xs")]: {
			marginRight: theme.spacing(0),
		},
	},
	replaceAnotherBtn: {
		marginLeft: theme.spacing(1),
		[theme.breakpoints.down("xs")]: {
			marginLeft: theme.spacing(0),
		},
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
	resetContainer: {
		padding: theme.spacing(3),
		[theme.breakpoints.down("xs")]: {
			display: "flex",
			flexDirection: "column",
		},
	},
	colorIcon: {
		marginRight: theme.spacing(1.5),
		marginLeft: theme.spacing(1.5),
	},
	content: {
		padding: 0,
	},
	stepContent: {
		minWidth: "450px",
		[theme.breakpoints.down("xs")]: {
			minWidth: "250px",
		},
	},
}))

const actuatorModels = [
	"GC2003L-45",
	"GC2003ST-58 848 JOTA",
	"GC2005L-52",
	"GC2005T-52",
	"GC2005T-CN-52",
	"GC2005T-CN-52-959-JOTA",
	"GC2005S-CN-52/60",
	"GC2008S-CN-52",
	"GC2008S-CN-60",
	"GC5008-CN60",
	"GC5008-LC-CN-60",
	"GC5008S",
]
const vendorInitial = ["P", "T", "L", "U"]
const channelOptions = ["0x0C", "0x0E", "0x0F", "0x10", "0x11", "0x12", "0x13", "0x14", "0x15", "0x16", "0x17", "0x18"]
const boardVendors = ["Sigmatron", "Vario", "East West Manufacturing"]
const boardAlternates = ["A", "B", "C", "D", "E", "F", "G", "H"]

const getStepContent = (
	step,
	steps,
	taskInfo,
	setTaskInfo,
	validateStep,
	requiredAttributes,
	useBatteryRMA,
	setUseBatteryRMA,
	setWizardInfo,
) => {
	const {
		issueType,
		activeStep,
		actuatorOn,
		movesEastWest,
		badActuatorModelNumber,
		badActuatorProductionDate,
		badActuatorVendorInitial,
		badActuatorSerialNum,
		goodActuatorModelNumber,
		goodActuatorProductionDate,
		goodActuatorVendorInitial,
		goodActuatorSerialNum,
		comment,
		commandQueue,
		failPosition,
		failAngle,
		actuatorAbnormalities,
		overCurrent,
		panelOn,
		currentVoltageGood,
		batteryGood,
		badPanelId,
		goodPanelId,
		current,
		voltage,
		tracking,
		lightOn,
		eStopRespond,
		eStopRespondReset,
		protectionGood,
		nodeIdMatch,
		nodeFuse,
		eStopPulled,
		goodBatteryId,
		badBatteryId,
		goodBatteryVendor,
		badBatteryVendor,
		rmaNumN,
		rmaNumB,
		rmaNumA,
		rmaNumC,
		mechanicalIssues,
		fixedWiring,
		isDamaged,
		damageType,
		channelPanIdGood,
		goodChannel,
		goodPanId,
		badChannel,
		badPanId,
		retrofitComponent,
		boardVendor,
		bomAlternate,
		bareBoardVersion,
		batchNumber,
		generation,
		buildDate,
	} = taskInfo
	if (step === 0) {
		return <IssueTypeQuestion issueType={issueType} setTaskInfo={setTaskInfo} />
	}
	const getBoardAlternateLabel = (retroFitComponent) => {
		return (
			retroFitComponent
				.split(" ")
				.map((token) => token[0].toUpperCase() + token.substring(1))
				.join(" ") + " alternate"
		)
	}

	switch (issueType) {
		case "MI":
			switch (step) {
				case 1:
					return (
						<div>
							<p>Is the Actuator plugged in?</p>
							<YesNoQuestion step={activeStep} value={actuatorOn} setTaskInfo={setTaskInfo} name={"actuatorOn"} />
						</div>
					)
				case 2:
					if (actuatorOn === "yes") {
						return (
							<InspectCableDamageForm activeStep={activeStep} fixedWiring={fixedWiring} setTaskInfo={setTaskInfo} />
						)
					} else if (actuatorOn === "no") {
						return (
							<div>
								<TestActuatorWithTableRotatorForm
									activeStep={activeStep}
									movesEastWest={movesEastWest}
									setTaskInfo={setTaskInfo}
								/>
							</div>
						)
					}
					break
				case 3:
					if (actuatorOn === "yes" && fixedWiring === "yes") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (actuatorOn === "yes") {
						return (
							<div>
								<TestActuatorWithTableRotatorForm
									activeStep={activeStep}
									movesEastWest={movesEastWest}
									setTaskInfo={setTaskInfo}
								/>
								<p>
									Test Actuator current with Digital Voltmeter. Is the table giving an overcurrent alarm or drawing more
									than 7A?
								</p>
								<YesNoQuestion step={activeStep} value={overCurrent} setTaskInfo={setTaskInfo} name={"overCurrent"} />
							</div>
						)
					} else if (actuatorOn === "no" && movesEastWest === "yes") {
						return (
							<div>
								<p>Plug in Node</p>
							</div>
						)
					} else if (actuatorOn === "no" && movesEastWest === "no") {
						return (
							<DefectiveActuatorForm
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								badActuatorModelNumber={badActuatorModelNumber}
								badActuatorProductionDate={badActuatorProductionDate}
								badActuatorVendorInitial={badActuatorVendorInitial}
								badActuatorSerialNum={badActuatorSerialNum}
								goodActuatorModelNumber={goodActuatorModelNumber}
								goodActuatorProductionDate={goodActuatorProductionDate}
								goodActuatorVendorInitial={goodActuatorVendorInitial}
								goodActuatorSerialNum={goodActuatorSerialNum}
							/>
						)
					}
					break
				case 4:
					if (actuatorOn === "yes" && fixedWiring === "yes") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					} else if (actuatorOn === "yes" && movesEastWest === "yes" && overCurrent === "no") {
						return <RecordNodeReplacementForm takeForRMA={true} setWizardInfo={setWizardInfo} />
					} else if (actuatorOn === "yes" && movesEastWest === "yes" && overCurrent === "yes") {
						return (
							<div>
								<p>Inspect table bearings and alignment for any abnormalities.</p>
								<p>Are there mechanical issues with the table?</p>
								<YesNoQuestion value={mechanicalIssues} setTaskInfo={setTaskInfo} name={"mechanicalIssues"} />
							</div>
						)
					} else if (actuatorOn === "no" && movesEastWest === "yes") {
						return (
							<RecordActuatorForm
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								goodActuatorModelNumber={goodActuatorModelNumber}
								goodActuatorProductionDate={goodActuatorProductionDate}
								goodActuatorVendorInitial={goodActuatorVendorInitial}
								goodActuatorSerialNum={goodActuatorSerialNum}
							/>
						)
					} else if (actuatorOn === "yes" && movesEastWest === "no") {
						return (
							<DefectiveActuatorForm
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								badActuatorModelNumber={badActuatorModelNumber}
								badActuatorProductionDate={badActuatorProductionDate}
								badActuatorVendorInitial={badActuatorVendorInitial}
								badActuatorSerialNum={badActuatorSerialNum}
								goodActuatorModelNumber={goodActuatorModelNumber}
								goodActuatorProductionDate={goodActuatorProductionDate}
								goodActuatorVendorInitial={goodActuatorVendorInitial}
								goodActuatorSerialNum={goodActuatorSerialNum}
							/>
						)
					} else if (actuatorOn === "no" && movesEastWest === "no") {
						return (
							<div>
								<p>Label failed actuator. What position did actuator fail?</p>
								<FailPositionQuestion taskInfo={taskInfo} value={failPosition} setTaskInfo={setTaskInfo} />
								<p>Please estimate the angle to the best of your ability.</p>
								<InputDropdown
									options={[45, 25, 0]}
									taskInfo={taskInfo}
									value={failAngle}
									setTaskInfo={setTaskInfo}
									name={"failAngle"}
									label={"Fail Angle"}
								/>
								<InputDropdown
									options={["External Physical Damage", "Loud Noise", "None"]}
									taskInfo={taskInfo}
									value={actuatorAbnormalities}
									setTaskInfo={setTaskInfo}
									name={"actuatorAbnormalities"}
									label={"Actuator Abnormalities"}
								/>
							</div>
						)
					}
					break
				case 5:
					if (actuatorOn === "yes" && movesEastWest === "yes" && overCurrent === "no") {
						return (
							<RecordActuatorForm
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								goodActuatorModelNumber={goodActuatorModelNumber}
								goodActuatorProductionDate={goodActuatorProductionDate}
								goodActuatorVendorInitial={goodActuatorVendorInitial}
								goodActuatorSerialNum={goodActuatorSerialNum}
							/>
						)
					} else if (
						actuatorOn === "yes" &&
						movesEastWest === "yes" &&
						overCurrent === "yes" &&
						mechanicalIssues === "no"
					) {
						return (
							<DefectiveActuatorForm
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								badActuatorModelNumber={badActuatorModelNumber}
								badActuatorProductionDate={badActuatorProductionDate}
								badActuatorVendorInitial={badActuatorVendorInitial}
								badActuatorSerialNum={badActuatorSerialNum}
								goodActuatorModelNumber={goodActuatorModelNumber}
								goodActuatorProductionDate={goodActuatorProductionDate}
								goodActuatorVendorInitial={goodActuatorVendorInitial}
								goodActuatorSerialNum={goodActuatorSerialNum}
							/>
						)
					} else if (
						actuatorOn === "yes" &&
						movesEastWest === "yes" &&
						overCurrent === "yes" &&
						mechanicalIssues === "yes"
					) {
						return (
							<div>
								<p>Mechanical Issues must be fixed before any component replacements are made.</p>
							</div>
						)
					} else if (actuatorOn === "no" && movesEastWest === "yes") {
						return (
							<PleaseRunCommandForm
								commandQueue={commandQueue}
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								commandName={"Replace Actuator"}
							/>
						)
					} else if (actuatorOn === "yes" && movesEastWest === "no") {
						return (
							<div>
								<p>Label failed actuator. What position did actuator fail?</p>
								<FailPositionQuestion taskInfo={taskInfo} value={failPosition} setTaskInfo={setTaskInfo} />
								<p>Please estimate the angle to the best of your ability.</p>
								<InputDropdown
									options={[45, 25, 0]}
									taskInfo={taskInfo}
									value={failAngle}
									setTaskInfo={setTaskInfo}
									name={"failAngle"}
									label={"Fail Angle"}
								/>
								<InputDropdown
									options={["External Physical Damage", "Loud Noise", "None"]}
									taskInfo={taskInfo}
									value={actuatorAbnormalities}
									setTaskInfo={setTaskInfo}
									name={"actuatorAbnormalities"}
									label={"Actuator Abnormalities"}
								/>
							</div>
						)
					} else if (actuatorOn === "no" && movesEastWest === "no") {
						return (
							<PleaseRunCommandForm
								commandQueue={commandQueue}
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								commandName={"Replace Actuator"}
							/>
						)
					}
					break
				case 6:
					if (actuatorOn === "yes" && movesEastWest === "yes" && overCurrent === "no") {
						return (
							<div>
								<p>Recover bad Node for RMA. Label Node with RMA #.</p>
								<p>RMA # : {rmaNumN}</p>
							</div>
						)
					} else if (actuatorOn === "yes" && movesEastWest === "no") {
						return (
							<PleaseRunCommandForm
								commandQueue={commandQueue}
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								commandName={"Replace Actuator"}
							/>
						)
					} else if (
						actuatorOn === "yes" &&
						movesEastWest === "yes" &&
						overCurrent === "yes" &&
						mechanicalIssues === "yes"
					) {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (
						actuatorOn === "yes" &&
						movesEastWest === "yes" &&
						overCurrent === "yes" &&
						mechanicalIssues === "no"
					) {
						return (
							<div>
								<p>Recover bad Actuator for RMA. Label Actuator with RMA #.</p>
								<p>RMA # : {rmaNumA}</p>
							</div>
						)
					} else if (actuatorOn === "no" && movesEastWest === "no") {
						return (
							<div>
								<p>Recover bad Actuator for RMA. Label Actuator with RMA #.</p>
								<p>RMA # : {rmaNumA}</p>
							</div>
						)
					} else if (actuatorOn === "no" && movesEastWest === "yes") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					}
					break
				case 7:
					if (actuatorOn === "yes" && movesEastWest === "yes" && overCurrent === "no") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (actuatorOn === "no" && movesEastWest === "no") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (actuatorOn === "no" && movesEastWest === "yes") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					} else if (
						actuatorOn === "yes" &&
						movesEastWest === "yes" &&
						overCurrent === "yes" &&
						mechanicalIssues === "yes"
					) {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					} else if (
						actuatorOn === "yes" &&
						movesEastWest === "yes" &&
						overCurrent === "yes" &&
						mechanicalIssues === "no"
					) {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (actuatorOn === "yes" && movesEastWest === "no") {
						return (
							<div>
								<p>Recover bad Actuator for RMA. Label Actuator with RMA #.</p>
								<p>RMA # : {rmaNumA}</p>
							</div>
						)
					}
					break
				case 8:
					if (actuatorOn === "yes" && movesEastWest === "no") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (actuatorOn === "yes" && movesEastWest === "yes" && overCurrent === "no") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					} else if (actuatorOn === "no" && movesEastWest === "no") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					} else if (
						actuatorOn === "yes" &&
						movesEastWest === "yes" &&
						overCurrent === "yes" &&
						mechanicalIssues === "no"
					) {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					}
					break
				case 9:
					if (actuatorOn === "yes" && movesEastWest === "no") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					}
					break
				default:
					return "Unknown step"
			}
			break
		case "CI":
			switch (step) {
				case 1:
					return (
						<div>
							<p>Visually inspect for cabling errors in the node, charging panel, and battery.</p>
							<p>Did you fix wiring?</p>
							<YesNoQuestion step={activeStep} value={fixedWiring} setTaskInfo={setTaskInfo} name={"fixedWiring"} />
						</div>
					)
				case 2:
					if (fixedWiring === "no") {
						return (
							<ComponentDamagedForm
								componentName={"battery"}
								activeStep={activeStep}
								isDamaged={isDamaged}
								setTaskInfo={setTaskInfo}
							/>
						)
					} else if (fixedWiring === "yes") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					}
					break
				case 3:
					if (fixedWiring === "no" && isDamaged === "no") {
						return (
							<div>
								<p>Is the Charging Panel properly plugged in to the node?</p>
								<YesNoQuestion value={panelOn} setTaskInfo={setTaskInfo} name={"panelOn"} />
							</div>
						)
					} else if (fixedWiring === "yes") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					} else if (fixedWiring === "no" && isDamaged === "yes") {
						return <TypeOfDamageForm taskInfo={taskInfo} setTaskInfo={setTaskInfo} damageType={damageType} />
					}
					break
				case 4:
					if (panelOn === "yes" || panelOn === "no") {
						return (
							<ChargingPanelTroubleshootingForm
								panelOn={panelOn}
								currentVoltageGood={currentVoltageGood}
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								voltage={voltage}
								current={current}
							/>
						)
					} else if (isDamaged === "yes") {
						return (
							<ChargingPanelReplacementForm
								taskInfo={taskInfo}
								badPanelId={badPanelId}
								goodPanelId={goodPanelId}
								setTaskInfo={setTaskInfo}
							/>
						)
					}
					break
				case 5:
					if (panelOn === "yes" && currentVoltageGood === "yes") {
						return <RecordNodeReplacementForm takeForRMA={true} setWizardInfo={setWizardInfo} />
					} else if (panelOn === "no" && currentVoltageGood === "yes") {
						return (
							<div>
								<p>Plug in Node</p>
							</div>
						)
					} else if (currentVoltageGood === "no") {
						return (
							<ChargingPanelReplacementForm
								taskInfo={taskInfo}
								badPanelId={badPanelId}
								goodPanelId={goodPanelId}
								setTaskInfo={setTaskInfo}
							/>
						)
					} else if (isDamaged === "yes") {
						return (
							<PleaseRunCommandForm
								commandQueue={commandQueue}
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								commandName={"Replace Charging Panel"}
							/>
						)
					}
					break
				case 6:
					if (panelOn === "yes" && currentVoltageGood === "yes") {
						return (
							<div>
								<p>Record good Charging Panel.</p>
								<InputQuestion
									taskInfo={taskInfo}
									value={goodPanelId}
									setTaskInfo={setTaskInfo}
									name={"goodPanelId"}
									label={"Good Panel ID"}
								/>
							</div>
						)
					} else if (panelOn === "no" && currentVoltageGood === "yes") {
						return (
							<div>
								<p>Record good Charging Panel.</p>
								<InputQuestion
									taskInfo={taskInfo}
									value={goodPanelId}
									setTaskInfo={setTaskInfo}
									name={"goodPanelId"}
									label={"Good Panel ID"}
								/>
							</div>
						)
					} else if (currentVoltageGood === "no") {
						return (
							<PleaseRunCommandForm
								commandQueue={commandQueue}
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								commandName={"Replace Charging Panel"}
							/>
						)
					} else if (isDamaged === "yes") {
						return (
							<div>
								<p>Recover bad Charging Panel for RMA. Label Charging Panel with RMA #.</p>
								<p>RMA # : {rmaNumC}</p>
							</div>
						)
					}
					break
				case 7:
					if (panelOn === "yes" && currentVoltageGood === "yes") {
						return (
							<div>
								<p>Recover bad Node for RMA. Label Node with RMA #.</p>
								<p>RMA # : {rmaNumN}</p>
							</div>
						)
					} else if (panelOn === "no" && currentVoltageGood === "yes") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (currentVoltageGood === "no") {
						return (
							<div>
								<p>Recover bad Charging Panel for RMA. Label Charging Panel with RMA #.</p>
								<p>RMA # : {rmaNumC}</p>
							</div>
						)
					} else if (isDamaged === "yes") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					}
					break
				case 8:
					if (panelOn === "yes" && currentVoltageGood === "yes") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (panelOn === "no" && currentVoltageGood === "yes") {
						return <TaskSummary setTaskInfo={setTaskInfo} requiredAttributes={requiredAttributes} taskInfo={taskInfo} />
					} else if (currentVoltageGood === "no") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (isDamaged === "yes") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					}
					break
				case 9:
					if (panelOn === "yes" && currentVoltageGood === "yes") {
						return <TaskSummary setTaskInfo={setTaskInfo} requiredAttributes={requiredAttributes} taskInfo={taskInfo} />
					} else if (currentVoltageGood === "no") {
						return <TaskSummary setTaskInfo={setTaskInfo} requiredAttributes={requiredAttributes} taskInfo={taskInfo} />
					}
					break
				default:
					return "Unknown step"
			}
			break
		case "BI":
			switch (step) {
				case 1:
					return (
						<div>
							<p>Visually inspect for cabling errors in the node, charging panel, and battery.</p>
							<p>Did you fix wiring?</p>
							<YesNoQuestion step={activeStep} value={fixedWiring} setTaskInfo={setTaskInfo} name={"fixedWiring"} />
						</div>
					)
				case 2:
					if (fixedWiring === "no") {
						return (
							<ComponentDamagedForm
								componentName={"battery"}
								activeStep={activeStep}
								isDamaged={isDamaged}
								setTaskInfo={setTaskInfo}
							/>
						)
					} else if (fixedWiring === "yes") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					}
					break
				case 3:
					if (fixedWiring === "no" && isDamaged === "no") {
						return (
							<div>
								<p>Is the Charging Panel properly plugged in to the node?</p>
								<YesNoQuestion value={panelOn} setTaskInfo={setTaskInfo} name={"panelOn"} />
							</div>
						)
					} else if (fixedWiring === "yes") {
						return <TaskSummary setTaskInfo={setTaskInfo} requiredAttributes={requiredAttributes} taskInfo={taskInfo} />
					} else if (fixedWiring === "no" && isDamaged === "yes") {
						return <TypeOfDamageForm taskInfo={taskInfo} setTaskInfo={setTaskInfo} damageType={damageType} />
					}
					break
				case 4:
					if (panelOn === "yes" || panelOn === "no") {
						return (
							<ChargingPanelReplacementForm
								taskInfo={taskInfo}
								badPanelId={badPanelId}
								goodPanelId={goodPanelId}
								setTaskInfo={setTaskInfo}
							/>
						)
					} else if (isDamaged === "yes") {
						return (
							<BatteryReplacementForm
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								goodBatteryId={goodBatteryId}
								badBatteryId={badBatteryId}
								badBatteryVendor={badBatteryVendor}
								goodBatteryVendor={goodBatteryVendor}
							/>
						)
					}
					break
				case 5:
					if (panelOn === "yes" && currentVoltageGood === "yes") {
						return (
							<BatteryReplacementForm
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								goodBatteryId={goodBatteryId}
								badBatteryId={badBatteryId}
								badBatteryVendor={badBatteryVendor}
								goodBatteryVendor={goodBatteryVendor}
							/>
						)
					} else if (panelOn === "no" && currentVoltageGood === "yes") {
						return (
							<div>
								<p>Plug in Node</p>
							</div>
						)
					} else if (currentVoltageGood === "no") {
						return (
							<ChargingPanelReplacementForm
								taskInfo={taskInfo}
								badPanelId={badPanelId}
								goodPanelId={goodPanelId}
								setTaskInfo={setTaskInfo}
							/>
						)
					} else if (isDamaged === "yes") {
						return (
							<div>
								<RMABadBatteryForm
									useBatteryRMA={useBatteryRMA}
									setUseBatteryRMA={setUseBatteryRMA}
									rmaNumB={rmaNumB}
								/>
							</div>
						)
					}
					break
				case 6:
					if (panelOn === "yes" && currentVoltageGood === "yes") {
						return (
							<div>
								<RMABadBatteryForm
									useBatteryRMA={useBatteryRMA}
									setUseBatteryRMA={setUseBatteryRMA}
									rmaNumB={rmaNumB}
								/>
							</div>
						)
					} else if (panelOn === "no" && currentVoltageGood === "yes") {
						return (
							<div>
								<p>
									Check health of battery by analyzing “Battery Voltage vs. Time” graph and determine if battery
									replacement is needed.
								</p>
								<p>Is the Battery in good condition?</p>
								<YesNoQuestion value={batteryGood} setTaskInfo={setTaskInfo} name={"batteryGood"} />
							</div>
						)
					} else if (currentVoltageGood === "no") {
						return (
							<div>
								<p>
									Check health of battery by analyzing “Battery Voltage vs. Time” graph and determine if battery
									replacement is needed.
								</p>
								<p>Is the Battery in good condition?</p>
								<YesNoQuestion value={batteryGood} setTaskInfo={setTaskInfo} name={"batteryGood"} />
							</div>
						)
					} else if (isDamaged === "yes") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					}
					break
				case 7:
					if (panelOn === "yes" && currentVoltageGood === "yes") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (panelOn === "no" && currentVoltageGood === "yes" && batteryGood === "yes") {
						return (
							<div>
								<p>Battery Alarm was due to unplugged Charging Panel, battery is in okay condition.</p>
							</div>
						)
					} else if (panelOn === "no" && currentVoltageGood === "yes" && batteryGood === "no") {
						return (
							<BatteryReplacementForm
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								goodBatteryId={goodBatteryId}
								badBatteryId={badBatteryId}
								badBatteryVendor={badBatteryVendor}
								goodBatteryVendor={goodBatteryVendor}
							/>
						)
					} else if (currentVoltageGood === "no" && batteryGood === "yes") {
						return (
							<div>
								<p>Recover bad Charging Panel for RMA. Label Charging Panel with RMA #.</p>
								<p>RMA # : {rmaNumC}</p>
							</div>
						)
					} else if (currentVoltageGood === "no" && batteryGood === "no") {
						return (
							<BatteryReplacementForm
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								goodBatteryId={goodBatteryId}
								badBatteryId={badBatteryId}
								badBatteryVendor={badBatteryVendor}
								goodBatteryVendor={goodBatteryVendor}
							/>
						)
					} else if (isDamaged === "yes") {
						return <TaskSummary setTaskInfo={setTaskInfo} requiredAttributes={requiredAttributes} taskInfo={taskInfo} />
					}
					break
				case 8:
					if (panelOn === "yes" && currentVoltageGood === "yes") {
						return <TaskSummary setTaskInfo={setTaskInfo} requiredAttributes={requiredAttributes} taskInfo={taskInfo} />
					} else if (panelOn === "no" && currentVoltageGood === "yes" && batteryGood === "no") {
						return (
							<div>
								<RMABadBatteryForm
									useBatteryRMA={useBatteryRMA}
									setUseBatteryRMA={setUseBatteryRMA}
									rmaNumB={rmaNumB}
								/>
							</div>
						)
					} else if (panelOn === "no" && currentVoltageGood === "yes" && batteryGood === "yes") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (currentVoltageGood === "no" && batteryGood === "yes") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (currentVoltageGood === "no" && batteryGood === "no") {
						return (
							<div>
								<p>Label bad Charging Panel and take for RMA.</p>
								<p>RMA # : {rmaNumC}</p>
								<RMABadBatteryForm
									useBatteryRMA={useBatteryRMA}
									setUseBatteryRMA={setUseBatteryRMA}
									rmaNumB={rmaNumB}
								/>
							</div>
						)
					}
					break
				case 9:
					if (panelOn === "no" && currentVoltageGood === "yes" && batteryGood === "no") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (currentVoltageGood === "no" && batteryGood === "no") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (panelOn === "no" && currentVoltageGood === "yes" && batteryGood === "yes") {
						return <TaskSummary setTaskInfo={setTaskInfo} requiredAttributes={requiredAttributes} taskInfo={taskInfo} />
					} else if (currentVoltageGood === "no" && batteryGood === "yes") {
						return <TaskSummary setTaskInfo={setTaskInfo} requiredAttributes={requiredAttributes} taskInfo={taskInfo} />
					}
					break
				case 10:
					if (panelOn === "no" && currentVoltageGood === "yes" && batteryGood === "no") {
						return <TaskSummary setTaskInfo={setTaskInfo} requiredAttributes={requiredAttributes} taskInfo={taskInfo} />
					} else if (currentVoltageGood === "no" && batteryGood === "no") {
						return <TaskSummary setTaskInfo={setTaskInfo} requiredAttributes={requiredAttributes} taskInfo={taskInfo} />
					}
					break
				default:
					return "Unknown step"
			}
			break
		case "NC":
			switch (step) {
				case 1:
					return (
						<div>
							<p>Does the Node ID match what is on the label?</p>
							<YesNoQuestion step={activeStep} value={nodeIdMatch} setTaskInfo={setTaskInfo} name={"nodeIdMatch"} />
						</div>
					)
				case 2:
					if (nodeIdMatch === "yes") {
						return (
							<InspectCableDamageForm activeStep={activeStep} fixedWiring={fixedWiring} setTaskInfo={setTaskInfo} />
						)
					} else if (nodeIdMatch === "no") {
						return (
							<div>
								<p>Replace Node ID to match the label</p>
								<DeviceIdInput />
							</div>
						)
					}
					break
				case 3:
					if (nodeIdMatch === "yes" && fixedWiring === "no") {
						return (
							<ComponentDamagedForm
								componentName={"node"}
								activeStep={activeStep}
								isDamaged={isDamaged}
								setTaskInfo={setTaskInfo}
							/>
						)
					} else if (nodeIdMatch === "yes" && fixedWiring === "yes") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (nodeIdMatch === "no") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					}
					break
				case 4:
					if (nodeIdMatch === "yes" && fixedWiring === "no" && isDamaged === "no") {
						return (
							<div>
								<p>Take down the node and use Tera Term or Hercules to check the Channel and Pan ID</p>
								<p>Is the node on the correct Channel and Pan ID?</p>
								<YesNoQuestion
									step={activeStep}
									value={channelPanIdGood}
									setTaskInfo={setTaskInfo}
									name={"channelPanIdGood"}
								/>
							</div>
						)
					} else if (nodeIdMatch === "no") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					} else if (nodeIdMatch === "yes" && fixedWiring === "yes") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					} else if (nodeIdMatch === "yes" && fixedWiring === "no" && isDamaged === "yes") {
						return <TypeOfDamageForm taskInfo={taskInfo} setTaskInfo={setTaskInfo} damageType={damageType} />
					}
					break
				case 5:
					if (nodeIdMatch === "yes" && fixedWiring === "no" && isDamaged === "no" && channelPanIdGood === "yes") {
						return (
							<div>
								<p>Is the E-stop pulled?</p>
								<YesNoQuestion step={activeStep} value={eStopPulled} setTaskInfo={setTaskInfo} name={"eStopPulled"} />
								<p>Does the Node have a fuse?</p>
								<YesNoQuestion step={activeStep} value={nodeFuse} setTaskInfo={setTaskInfo} name={"nodeFuse"} />
							</div>
						)
					} else if (nodeIdMatch === "yes" && fixedWiring === "no" && isDamaged === "yes") {
						return <RecordNodeReplacementForm takeForRMA={true} setWizardInfo={setWizardInfo} />
					} else if (nodeIdMatch === "yes" && fixedWiring === "no" && isDamaged === "no" && channelPanIdGood === "no") {
						return (
							<div>
								<p>Record the bad Pan ID and Channel</p>
								<InputQuestion
									taskInfo={taskInfo}
									value={badPanId}
									setTaskInfo={setTaskInfo}
									name={"badPanId"}
									label={"Bad Pan ID"}
								/>
								<InputDropdown
									options={channelOptions}
									taskInfo={taskInfo}
									value={badChannel}
									setTaskInfo={setTaskInfo}
									name={"badChannel"}
									label={"Bad Channel"}
								/>
								<p>Record the good Pan ID and Channel</p>
								<InputQuestion
									taskInfo={taskInfo}
									value={goodPanId}
									setTaskInfo={setTaskInfo}
									name={"goodPanId"}
									label={"Good Pan ID"}
								/>
								<InputDropdown
									options={channelOptions}
									taskInfo={taskInfo}
									value={goodChannel}
									setTaskInfo={setTaskInfo}
									name={"goodChannel"}
									label={"Good Channel"}
								/>
							</div>
						)
					}
					break
				case 6:
					if (eStopPulled === "yes" && nodeFuse === "yes") {
						return <div>E-stop has been pulled and the node has a fuse. Please proceed to the next step.</div>
					} else if (eStopPulled === "no" && nodeFuse === "yes") {
						return <div>Pull the E-stop.</div>
					} else if (eStopPulled === "yes" && nodeFuse === "no") {
						return <div>Replace missing fuse.</div>
					} else if (eStopPulled === "no" && nodeFuse === "no") {
						return <div>Replace missing fuse and pull the E-stop.</div>
					} else if (nodeIdMatch === "yes" && fixedWiring === "no" && isDamaged === "yes") {
						return (
							<div>
								<p>Recover bad Node for RMA. Label Node with RMA #.</p>
								<p>RMA # : {rmaNumN}</p>
							</div>
						)
					} else if (nodeIdMatch === "yes" && fixedWiring === "no" && isDamaged === "no" && channelPanIdGood === "no") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					}
					break
				case 7:
					if (nodeIdMatch === "yes" && fixedWiring === "no" && isDamaged === "no" && channelPanIdGood === "yes") {
						return (
							<div>
								<p>Is the light on or blinking?</p>
								<YesNoQuestion step={activeStep} value={lightOn} setTaskInfo={setTaskInfo} name={"lightOn"} />
							</div>
						)
					} else if (nodeIdMatch === "yes" && fixedWiring === "no" && isDamaged === "yes") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (nodeIdMatch === "yes" && fixedWiring === "no" && isDamaged === "no" && channelPanIdGood === "no") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					}
					break
				case 8:
					if (lightOn === "yes") {
						return (
							<div>
								<p>Press E-stop and check if LED turns off</p>
								<p>Release E-stop and check if LED comes back on</p>
								<p>Did the E-stop respond?</p>
								<YesNoQuestion step={activeStep} value={eStopRespond} setTaskInfo={setTaskInfo} name={"eStopRespond"} />
							</div>
						)
					} else if (lightOn === "no") {
						return (
							<ChargingPanelTroubleshootingForm
								panelOn={panelOn}
								currentVoltageGood={currentVoltageGood}
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								voltage={voltage}
								current={current}
							/>
						)
					} else if (nodeIdMatch === "yes" && fixedWiring === "no" && isDamaged === "yes") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					}
					break
				case 9:
					if (lightOn === "yes" && eStopRespond === "yes") {
						return (
							<div>
								<p>Please run the “Rescue Node” command, wait 1 minute then send “Replace Node” command.</p>
								<p>Were the commands successfully run?</p>
								<CommandQueue
									commandQueue={commandQueue}
									taskInfo={taskInfo}
									setTaskInfo={setTaskInfo}
									command={"Replace Node"}
								/>
							</div>
						)
					} else if (lightOn === "yes" && eStopRespond === "no") {
						return (
							<div>
								<p>
									Remove the fuse from the node and disconnect the charging panel. Wait 30 seconds and then reconnect
									everything.
								</p>
								<p>Did the E-stop respond?</p>
								<YesNoQuestion
									step={activeStep}
									value={eStopRespondReset}
									setTaskInfo={setTaskInfo}
									name={"eStopRespondReset"}
								/>
							</div>
						)
					} else if (lightOn === "no" && currentVoltageGood === "no") {
						return (
							<div>
								<p>Replace the Charging Panel and recover for RMA.</p>
								<InputQuestion
									taskInfo={taskInfo}
									value={badPanelId}
									setTaskInfo={setTaskInfo}
									name={"badPanelId"}
									label={"Bad Panel ID"}
								/>
								<InputQuestion
									taskInfo={taskInfo}
									value={goodPanelId}
									setTaskInfo={setTaskInfo}
									name={"goodPanelId"}
									label={"Good Panel ID"}
								/>
							</div>
						)
					} else if (lightOn === "no" && currentVoltageGood === "yes") {
						return <RecordNodeReplacementForm takeForRMA={true} setWizardInfo={setWizardInfo} />
					}
					break
				case 10:
					if (lightOn === "yes" && eStopRespond === "yes") {
						return (
							<div>
								<p>Did it start tracking?</p>
								<YesNoQuestion step={activeStep} value={tracking} setTaskInfo={setTaskInfo} name={"tracking"} />
							</div>
						)
					} else if (lightOn === "yes" && eStopRespondReset === "yes") {
						return (
							<div>
								<p>Please run the “Rescue Node” command, wait 1 minute then send “Replace Node” command.</p>
								<p>Were the commands successfully run?</p>
								<CommandQueue
									commandQueue={commandQueue}
									taskInfo={taskInfo}
									setTaskInfo={setTaskInfo}
									command={"Replace Node"}
								/>
							</div>
						)
					} else if (lightOn === "yes" && eStopRespondReset === "no") {
						return <RecordNodeReplacementForm takeForRMA={true} setWizardInfo={setWizardInfo} />
					} else if (lightOn === "no" && currentVoltageGood === "no") {
						return (
							<PleaseRunCommandForm
								commandQueue={commandQueue}
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								commandName={"Replace Charging Panel"}
							/>
						)
					} else if (lightOn === "no" && currentVoltageGood === "yes") {
						return (
							<div>
								<p>Recover bad Node for RMA. Label Node with RMA #.</p>
								<p>RMA # : {rmaNumN}</p>
							</div>
						)
					}
					break
				case 11:
					if (lightOn === "yes" && eStopRespond === "yes" && tracking === "yes") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (lightOn === "yes" && eStopRespondReset === "yes") {
						return (
							<div>
								<p>Did it start tracking?</p>
								<YesNoQuestion step={activeStep} value={tracking} setTaskInfo={setTaskInfo} name={"tracking"} />
							</div>
						)
					} else if (lightOn === "yes" && eStopRespondReset === "no") {
						return (
							<div>
								<p>Recover bad Node for RMA. Label Node with RMA #.</p>
								<p>RMA # : {rmaNumN}</p>
							</div>
						)
					} else if (lightOn === "yes" && eStopRespond === "yes" && tracking === "no") {
						return <RecordNodeReplacementForm takeForRMA={true} setWizardInfo={setWizardInfo} />
					} else if (lightOn === "no" && currentVoltageGood === "no") {
						return (
							<BatteryReplacementForm
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								goodBatteryId={goodBatteryId}
								badBatteryId={badBatteryId}
								badBatteryVendor={badBatteryVendor}
								goodBatteryVendor={goodBatteryVendor}
							/>
						)
					} else if (lightOn === "no" && currentVoltageGood === "yes") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					}
					break
				case 12:
					if (lightOn === "yes" && eStopRespond === "yes" && tracking === "yes") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					} else if (lightOn === "yes" && eStopRespondReset === "yes" && tracking === "yes") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (lightOn === "yes" && eStopRespondReset === "yes" && tracking === "no") {
						return <RecordNodeReplacementForm takeForRMA={true} setWizardInfo={setWizardInfo} />
					} else if (lightOn === "yes" && eStopRespond === "yes" && tracking === "no") {
						return (
							<div>
								<p>Recover bad Node for RMA. Label Node with RMA #.</p>
								<p>RMA # : {rmaNumN}</p>
							</div>
						)
					} else if (lightOn === "yes" && eStopRespondReset === "no") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (lightOn === "no" && currentVoltageGood === "no") {
						return (
							<div>
								<p>Label bad Charging Panel and take for RMA.</p>
								<p>RMA # : {rmaNumC}</p>
								<RMABadBatteryForm
									useBatteryRMA={useBatteryRMA}
									setUseBatteryRMA={setUseBatteryRMA}
									rmaNumB={rmaNumB}
								/>
							</div>
						)
					} else if (lightOn === "no" && currentVoltageGood === "yes") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					}
					break
				case 13:
					if (lightOn === "yes" && eStopRespondReset === "yes" && tracking === "yes") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					} else if (lightOn === "yes" && eStopRespond === "yes" && tracking === "no") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (lightOn === "yes" && eStopRespondReset === "yes" && tracking === "no") {
						return (
							<div>
								<p>Recover bad Node for RMA. Label Node with RMA #.</p>
								<p>RMA # : {rmaNumN}</p>
							</div>
						)
					} else if (lightOn === "no" && currentVoltageGood === "no") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (lightOn === "yes" && eStopRespondReset === "no") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					}
					break
				case 14:
					if (lightOn === "yes" && eStopRespondReset === "yes" && tracking === "no") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (lightOn === "yes" && eStopRespond === "yes" && tracking === "no") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					} else if (lightOn === "no" && currentVoltageGood === "no") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					}
					break
				case 15:
					if (lightOn === "yes" && eStopRespondReset === "yes" && tracking === "no") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					}
					break
				default:
					return "Unknown step"
			}
			break
		case "BO":
			switch (step) {
				case 1:
					return (
						<div>
							<p>
								WARNING: Please make sure you are wearing face protection and a long sleeve shirt on top of the required
								site PPE.
							</p>
							<p>DO NOT hit E-stop. Please UNPLUG the Charging Panel.</p>
							<p>Ready to proceed?</p>
							<YesNoQuestion value={protectionGood} setTaskInfo={setTaskInfo} name={"protectionGood"} />
						</div>
					)
				case 2:
					return (
						<div>
							<p>Use short-stubby handheld Phillips screwdriver.</p>
							<p>UNSCREW front 2 lid screws to vent the node enclosure.</p>
						</div>
					)
				case 3:
					return <RecordNodeReplacementForm takeForRMA={true} setWizardInfo={setWizardInfo} />
				case 4:
					return (
						<div>
							<p>Recover bad Node for RMA. Label Node with RMA #.</p>
							<p>RMA # : {rmaNumN}</p>
						</div>
					)
				case 5:
					return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
				case 6:
					return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
				default:
					return "Unknown step"
			}
		case "EI":
			switch (step) {
				case 1:
					return (
						<div>
							<p>If the Estop is pulled, please push in the E-stop.</p>
						</div>
					)
				case 2:
					return (
						<>
							<PleaseRunCommandForm
								commandQueue={commandQueue}
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								commandName={"Reset All Node Alarms"}
							/>
							<PleaseRunCommandForm
								commandQueue={commandQueue}
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								commandName={"Tracking-Enabled"}
							/>
						</>
					)
				case 3:
					return (
						<div>
							<p>Verify visually that the table has started to move and heading towards its target angle.</p>
							<p>Did it start tracking?</p>
							<YesNoQuestion step={activeStep} value={tracking} setTaskInfo={setTaskInfo} name={"tracking"} />
						</div>
					)
				case 4:
					if (tracking === "yes") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (tracking === "no") {
						return (
							<div>
								<p>Repair or replace E-stop and re-run commands.</p>
								<PleaseRunCommandForm
									commandQueue={commandQueue}
									taskInfo={taskInfo}
									setTaskInfo={setTaskInfo}
									commandName={"Reset All Node Alarms(2)"}
									friendlyCommandName={"Reset All Node Alarms"}
								/>
								<PleaseRunCommandForm
									commandQueue={commandQueue}
									taskInfo={taskInfo}
									setTaskInfo={setTaskInfo}
									commandName={"Tracking-Enabled(2)"}
									friendlyCommandName={"Tracking-Enabled"}
								/>
							</div>
						)
					}
					break
				case 5:
					if (tracking === "yes") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					} else if (tracking === "no") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					}
					break
				case 6:
					if (tracking === "no") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					}
					break
				default:
					return "Unknown step"
			}
			break
		case "RF": {
			const componentIsTwoEightBoard = retrofitComponent === "2.8.2 board" || retrofitComponent === "2.8.3 board"
			switch (step) {
				case 1:
					return (
						<div>
							<p>What component is this campaign for?</p>
							<InputDropdown
								options={["actuator", "battery", "board", "2.8.2 board", "2.8.3 remote inclinometer", "2.8.3 board"]}
								taskInfo={taskInfo}
								value={retrofitComponent}
								setTaskInfo={setTaskInfo}
								name={"retrofitComponent"}
								label={"Retrofit Component"}
							/>
						</div>
					)
				case 2:
					if (retrofitComponent === "board") {
						return <RecordNodeReplacementForm takeForRMA={false} setWizardInfo={setWizardInfo} />
					} else if (retrofitComponent === "battery") {
						return (
							<BatteryReplacementForm
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								goodBatteryId={goodBatteryId}
								badBatteryId={badBatteryId}
								badBatteryVendor={badBatteryVendor}
								goodBatteryVendor={goodBatteryVendor}
							/>
						)
					} else if (retrofitComponent === "actuator") {
						return (
							<DefectiveActuatorForm
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								badActuatorModelNumber={badActuatorModelNumber}
								badActuatorProductionDate={badActuatorProductionDate}
								badActuatorVendorInitial={badActuatorVendorInitial}
								badActuatorSerialNum={badActuatorSerialNum}
								goodActuatorModelNumber={goodActuatorModelNumber}
								goodActuatorProductionDate={goodActuatorProductionDate}
								goodActuatorVendorInitial={goodActuatorVendorInitial}
								goodActuatorSerialNum={goodActuatorSerialNum}
							/>
						)
					} else if (retrofitComponent === "2.8.2 board") {
						return (
							<BoardVendorForm
								boardVendors={boardVendors}
								taskInfo={taskInfo}
								boardVendor={boardVendor}
								setTaskInfo={setTaskInfo}
								label={"2.8.2 Board vendor"}
							/>
						)
					} else if (retrofitComponent === "2.8.3 remote inclinometer") {
						return (
							<div>
								<p>What is the batch number?</p>
								<InputQuestion
									taskInfo={taskInfo}
									value={batchNumber}
									setTaskInfo={setTaskInfo}
									name={"batchNumber"}
									label={"Batch number"}
								/>
							</div>
						)
					} else if (retrofitComponent === "2.8.3 board") {
						return (
							<div>
								<BoardVendorForm
									boardVendors={boardVendors}
									taskInfo={taskInfo}
									boardVendor={boardVendor}
									setTaskInfo={setTaskInfo}
									label={"2.8.3 Board vendor"}
								/>
								<div>
									<p>What is the batch number?</p>
									<InputQuestion
										taskInfo={taskInfo}
										value={batchNumber}
										setTaskInfo={setTaskInfo}
										name={"batchNumber"}
										label={"Batch number"}
									/>
								</div>
							</div>
						)
					}
					break
				case 3:
					if (retrofitComponent === "board") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (retrofitComponent === "battery") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (retrofitComponent === "actuator") {
						return (
							<PleaseRunCommandForm
								commandQueue={commandQueue}
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								commandName={"Replace Actuator"}
							/>
						)
					} else if (componentIsTwoEightBoard) {
						return <RecordNodeReplacementForm takeForRMA={false} setWizardInfo={setWizardInfo} />
					} else if (retrofitComponent === "2.8.3 remote inclinometer") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					}
					break
				case 4:
					if (retrofitComponent === "board") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					} else if (retrofitComponent === "battery") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					} else if (retrofitComponent === "actuator") {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					} else if (componentIsTwoEightBoard) {
						return (
							<BOMAlternateForm
								bomAlternates={boardAlternates}
								taskInfo={taskInfo}
								bomAlternate={bomAlternate}
								setTaskInfo={setTaskInfo}
								label={getBoardAlternateLabel(retrofitComponent)}
							/>
						)
					}
					break
				case 5:
					if (retrofitComponent === "actuator") {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					} else if (componentIsTwoEightBoard) {
						return (
							<BareBoardVersionForm
								bareBoardVersions={boardAlternates}
								taskInfo={taskInfo}
								bareBoardVersion={bareBoardVersion}
								setTaskInfo={setTaskInfo}
								label={getBoardAlternateLabel(retrofitComponent)}
							/>
						)
					}
					break
				case 6:
					if (componentIsTwoEightBoard) {
						return (
							<BoardMiscForm
								taskInfo={taskInfo}
								setTaskInfo={setTaskInfo}
								generation={generation}
								buildDate={buildDate}
							/>
						)
					}
					break
				case 7:
					if (componentIsTwoEightBoard) {
						return <TaskComment taskInfo={taskInfo} value={comment} setTaskInfo={setTaskInfo} />
					}
					break
				case 8:
					if (componentIsTwoEightBoard) {
						return <TaskSummary setTaskInfo={setTaskInfo} taskInfo={taskInfo} requiredAttributes={requiredAttributes} />
					}
					break
				default:
					return "Unknown step"
			}
			break
		}
		default:
			return "Unknown step"
	}
}

const TaskWizard = ({
	taskInfo,
	setTaskInfo,
	nodeDetails,
	masterDetails,
	mLocId,
	nLocId,
	createTask,
	layoutName,
	layoutNumber,
	setWizardInfo,
	newDeviceId,
	processRunning,
}) => {
	const classes = useStyles()

	let { activeStep } = taskInfo
	const { nId, fromSouth, rowNum } = (nodeDetails[mLocId] || {})[nLocId] || {}
	const masterName = (masterDetails[mLocId] || {}).name
	const [useBatteryRMA, setUseBatteryRMA] = useState(true)

	useEffect(() => {
		setTaskInfo({ nId, fromSouth, rowNum })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskInfo.issueType])

	useEffect(() => {
		if (taskInfo.fromSouth !== fromSouth && taskInfo.rowNum !== rowNum) {
			handleReset()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fromSouth, rowNum])

	useEffect(() => {
		if (processRunning) {
			setTaskInfo({
				newNodeId: newDeviceId,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newDeviceId, processRunning])

	const validateStep = (keys) => {
		if (keys) {
			let validationCheck = keys.map((key) => {
				if (key === "protectionGood" && taskInfo[key] === "no") return false
				//if((key === 'badBatteryID' || key === 'goodBatteryID') && taskInfo[key] && taskInfo[key].length !== 6) return false

				//if((key === 'badActuatorSerialNum' || key === 'goodActuatorSerialNum') && taskInfo[key] && taskInfo[key].length !== 12) return false

				if ((key === "badPanId" || key === "goodPanId") && taskInfo[key] && taskInfo[key].length !== 4) return false

				if (
					key === "currentVoltageGood" &&
					taskInfo[key] === "yes" &&
					(!(taskInfo["voltage"] && taskInfo["voltage"] > 36 && taskInfo["voltage"] < 60) ||
						!(taskInfo["current"] && taskInfo["current"] > 0.25 && taskInfo["current"] < 2))
				) {
					return false
				} else if (
					key === "currentVoltageGood" &&
					taskInfo[key] === "no" &&
					taskInfo["voltage"] &&
					taskInfo["voltage"] > 36 &&
					taskInfo["voltage"] < 60 &&
					taskInfo["current"] &&
					taskInfo["current"] > 0.25 &&
					taskInfo["current"] < 2
				) {
					return false
				}
				return !!((taskInfo[key] && taskInfo[key].toString().trim()) || taskInfo[key] === 0)
			})
			return validationCheck.every((val) => val === true)
		}
	}

	const createRMA = (type) => {
		const masterNum = masterName.replace(/^\D+/g, "")
		const date = new Date()
		const year = date.getFullYear().toString()
		const month = (date.getMonth() + 1).toString().padStart(2, "0")
		const day = date.getDate().toString().padStart(2, "0")
		const rmaNum = `${layoutNumber}-${masterNum}-${fromSouth}-${rowNum}${type}-${month}${day}${year}`
		let rmaName = `rmaNum${type}`
		if (taskInfo[rmaName] !== rmaNum) {
			// This line was producing a warning about updating/rendering two components simultaneously
			// Wrapping calls to update states in a setTimeout is supposed to queue them
			setTimeout(() => setTaskInfo({ [rmaName]: rmaNum }), 0)
		}

		return rmaNum
	}

	const createContentInfo = () => {
		let contentInfo = {
			0: {
				label: "Issue Type",
				value: ["issueType"],
			},
		}

		const requiredAttributes = {
			issueType: "Issue Type",
			comment: "Comments",
			nId: "Node ID",
		}

		function addRequiredAttributes(otherRequiredAttributes) {
			Object.assign(requiredAttributes, otherRequiredAttributes)
		}

		//let rmaNum

		let {
			issueType,
			actuatorOn,
			movesEastWest,
			panelOn,
			currentVoltageGood,
			lightOn,
			eStopRespond,
			eStopRespondReset,
			tracking,
			overCurrent,
			batteryGood,
			nodeIdMatch,
			nodeFuse,
			eStopPulled,
			mechanicalIssues,
			fixedWiring,
			isDamaged,
			channelPanIdGood,
			retrofitComponent,
		} = taskInfo
		switch (issueType) {
			case "MI":
				contentInfo[1] = {
					label: "Is the Actuator plugged in?",
					value: ["actuatorOn"],
				}

				if (actuatorOn === "yes") {
					contentInfo[2] = {
						label: "Inspect for wire damage",
						value: ["fixedWiring"],
					}
				} else if (actuatorOn === "no") {
					contentInfo[2] = {
						label: "Test movement",
						value: ["movesEastWest"],
					}
				}

				if (actuatorOn === "yes" && fixedWiring === "yes") {
					addRequiredAttributes({
						actuatorOn: "Actuator plugged in?",
						fixedWiring: "Wiring fixed?",
					})
					contentInfo[3] = {
						label: "Notes",
					}

					contentInfo[4] = {
						label: "Review",
					}
				} else if (actuatorOn === "yes" && fixedWiring === "no") {
					contentInfo[3] = {
						label: "Test movement and overcurrent",
						value: ["movesEastWest", "overCurrent"],
					}
				}

				if (actuatorOn === "yes" && fixedWiring === "no" && movesEastWest === "yes" && overCurrent === "yes") {
					contentInfo[4] = {
						label: "Inpect table bearings and alignment",
						value: ["mechanicalIssues"],
					}

					if (mechanicalIssues === "yes") {
						addRequiredAttributes({
							actuatorOn: "Actuator plugged in?",
							movesEastWest: "Moves East and West?",
							overCurrent: "Overcurrent",
							fixedWiring: "Wiring fixed?",
							mechanicalIssues: "Mechanical Issues?",
							commandQueue: "Commands to run",
						})

						contentInfo[5] = {
							label: "Mechanical Issues must be fixed",
						}
						contentInfo[6] = {
							label: "Notes",
						}
						contentInfo[7] = {
							label: "Review",
						}
					} else if (mechanicalIssues === "no") {
						createRMA("A")
						addRequiredAttributes({
							actuatorOn: "Actuator plugged in?",
							movesEastWest: "Moves East and West?",
							overCurrent: "Overcurrent",
							fixedWiring: "Wiring fixed?",
							commandQueue: "Commands to run",

							goodActuatorModelNumber: "Good Actuator model number",
							goodActuatorProductionDate: "Good Actuator production date",
							goodActuatorVendorInitial: "Good Actuator vendor initial",
							goodActuatorSerialNum: "Good Actuator Serial Number",
							badActuatorModelNumber: "Bad Actuator model number",
							badActuatorProductionDate: "Bad Actuator production date",
							badActuatorVendorInitial: "Bad Actuator vendor initial",
							badActuatorSerialNum: "Bad Actuator Serial Number",
							rmaNumA: "RMA Number Actuator",
						})
						contentInfo[5] = {
							label: "Replace Actuator",
							value: [
								"badActuatorModelNumber",
								"badActuatorProductionDate",
								"badActuatorVendorInitial",
								"badActuatorSerialNum",
								"goodActuatorModelNumber",
								"goodActuatorProductionDate",
								"goodActuatorVendorInitial",
								"goodActuatorSerialNum",
							],
						}
						contentInfo[6] = {
							label: "Label with RMA number",
						}
						contentInfo[7] = {
							label: "Notes",
						}

						contentInfo[8] = {
							label: "Review",
						}
					}
				}

				if (actuatorOn === "yes" && fixedWiring === "no" && movesEastWest === "yes" && overCurrent === "no") {
					createRMA("N")

					addRequiredAttributes({
						actuatorOn: "Actuator plugged in?",
						movesEastWest: "Moves East and West?",
						overCurrent: "Overcurrent",
						fixedWiring: "Wiring fixed?",
						commandQueue: "Commands to run",

						newNodeId: "New Node ID",
						goodActuatorModelNumber: "Good Actuator model number",
						goodActuatorProductionDate: "Good Actuator production date",
						goodActuatorVendorInitial: "Good Actuator vendor initial",
						goodActuatorSerialNum: "Good Actuator Serial Number",
						rmaNumN: "RMA Number Node",
					})

					contentInfo[4] = {
						label: "Replace Node",
						value: ["newNodeId"],
					}

					contentInfo[5] = {
						label: "Record good Actuator",
						value: [
							"goodActuatorModelNumber",
							"goodActuatorProductionDate",
							"goodActuatorVendorInitial",
							"goodActuatorSerialNum",
						],
					}

					contentInfo[6] = {
						label: "Label with RMA number",
					}

					contentInfo[7] = {
						label: "Notes",
					}

					contentInfo[8] = {
						label: "Review",
					}
				}

				if (actuatorOn === "yes" && fixedWiring === "no" && movesEastWest === "no") {
					createRMA("A")
					addRequiredAttributes({
						actuatorOn: "Actuator plugged in?",
						movesEastWest: "Moves East and West?",
						overCurrent: "Overcurrent",
						fixedWiring: "Wiring fixed?",
						commandQueue: "Commands to run",

						failPosition: "Failure position",
						failAngle: "Failure Angle",
						goodActuatorModelNumber: "Good Actuator model number",
						goodActuatorProductionDate: "Good Actuator production date",
						goodActuatorVendorInitial: "Good Actuator vendor initial",
						goodActuatorSerialNum: "Good Actuator Serial Number",
						badActuatorModelNumber: "Bad Actuator model number",
						badActuatorProductionDate: "Bad Actuator production date",
						badActuatorVendorInitial: "Bad Actuator vendor initial",
						badActuatorSerialNum: "Bad Actuator Serial Number",
						rmaNumA: "RMA Number Actuator",
					})

					contentInfo[4] = {
						label: "Replace Actuator",
						value: [
							"badActuatorModelNumber",
							"badActuatorProductionDate",
							"badActuatorVendorInitial",
							"badActuatorSerialNum",
							"goodActuatorModelNumber",
							"goodActuatorProductionDate",
							"goodActuatorVendorInitial",
							"goodActuatorSerialNum",
						],
					}

					contentInfo[5] = {
						label: "Fail information",
						value: ["failPosition", "failAngle", "actuatorAbnormalities"],
					}

					contentInfo[6] = {
						label: 'Send "Replace Actuator" command',
						value: ["Replace Actuator"],
					}

					contentInfo[7] = {
						label: "Label with RMA number",
					}

					contentInfo[8] = {
						label: "Notes",
					}
					contentInfo[9] = {
						label: "Review",
					}
				}
				if (actuatorOn === "no" && movesEastWest === "no") {
					createRMA("A")

					addRequiredAttributes({
						actuatorOn: "Actuator plugged in?",
						movesEastWest: "Moves East and West?",
						fixedWiring: "Wiring fixed?",
						commandQueue: "Commands to run",

						failPosition: "Failure position",
						failAngle: "Failure Angle",
						actuatorAbnormalities: "Actuator Abnormalities",
						goodActuatorModelNumber: "Good Actuator model number",
						goodActuatorProductionDate: "Good Actuator production date",
						goodActuatorVendorInitial: "Good Actuator vendor initial",
						goodActuatorSerialNum: "Good Actuator Serial Number",
						badActuatorModelNumber: "Bad Actuator model number",
						badActuatorProductionDate: "Bad Actuator production date",
						badActuatorVendorInitial: "Bad Actuator vendor initial",
						badActuatorSerialNum: "Bad Actuator Serial Number",
						rmaNumA: "RMA Number Actuator",
					})

					contentInfo[3] = {
						label: "Replace Actuator",
						value: [
							"badActuatorModelNumber",
							"badActuatorProductionDate",
							"badActuatorVendorInitial",
							"badActuatorSerialNum",
							"goodActuatorModelNumber",
							"goodActuatorProductionDate",
							"goodActuatorVendorInitial",
							"goodActuatorSerialNum",
						],
					}

					contentInfo[4] = {
						label: "Fail information",
						value: ["failPosition", "failAngle", "actuatorAbnormalities"],
					}

					contentInfo[5] = {
						label: 'Send "Replace Actuator" command',
						value: ["Replace Actuator"],
					}

					contentInfo[6] = {
						label: "Label with RMA number",
					}

					contentInfo[7] = {
						label: "Notes",
					}
					contentInfo[8] = {
						label: "Review",
					}
				}
				if (actuatorOn === "no" && movesEastWest === "yes") {
					addRequiredAttributes({
						actuatorOn: "Actuator plugged in?",
						movesEastWest: "Moves East and West?",
						fixedWiring: "Wiring fixed?",
						commandQueue: "Commands to run",

						goodActuatorModelNumber: "Good Actuator model number",
						goodActuatorProductionDate: "Good Actuator production date",
						goodActuatorVendorInitial: "Good Actuator vendor initial",
						goodActuatorSerialNum: "Good Actuator Serial Number",
					})

					contentInfo[3] = {
						label: "Plug in Node",
					}

					contentInfo[4] = {
						label: "Record good Actuator",
						value: [
							"goodActuatorModelNumber",
							"goodActuatorProductionDate",
							"goodActuatorVendorInitial",
							"goodActuatorSerialNum",
						],
					}

					contentInfo[5] = {
						label: 'Send "Replace Actuator" command',
						value: ["Replace Actuator"],
					}

					contentInfo[6] = {
						label: "Notes",
					}
					contentInfo[7] = {
						label: "Review",
					}
				}
				break
			case "CI":
				contentInfo[1] = {
					label: "Inspect Wiring",
					value: ["fixedWiring"],
				}

				if (fixedWiring === "yes") {
					addRequiredAttributes({
						commandQueue: "Commands to run",

						fixedWiring: "Wiring fixed?",
					})

					contentInfo[2] = {
						label: "Notes",
					}

					contentInfo[3] = {
						label: "Review",
					}
				} else if (fixedWiring === "no") {
					contentInfo[2] = {
						label: "Inspect for physical and water damage",
						value: ["isDamaged"],
					}
				}

				if (isDamaged === "yes") {
					createRMA("C")
					addRequiredAttributes({
						commandQueue: "Commands to run",

						goodPanelId: "Good Panel ID",
						badPanelId: "Bad Panel ID",
						fixedWiring: "Wiring fixed?",
						damageType: "Damage Type",
						rmaNumC: "RMA Number Charging Panel",
					})

					contentInfo[3] = {
						label: "Record damage type",
						value: ["damageType"],
					}

					contentInfo[4] = {
						label: "Replace Charging Panel",
						value: ["badPanelId", "goodPanelId"],
					}

					contentInfo[5] = {
						label: "Replace Charging Panel Command",
						value: ["Replace Charging Panel"],
					}

					contentInfo[6] = {
						label: "Label with RMA number",
					}

					contentInfo[7] = {
						label: "Notes",
					}

					contentInfo[8] = {
						label: "Review",
					}
				}

				if (fixedWiring === "no" && isDamaged === "no") {
					contentInfo[3] = {
						label: "Is Panel plugged in?",
						value: ["panelOn"],
					}

					contentInfo[4] = {
						label: "Voltage and Current Levels",
						value: ["currentVoltageGood", "current", "voltage"],
					}
				}

				if (panelOn === "yes" && currentVoltageGood === "yes") {
					createRMA("N")
					addRequiredAttributes({
						panelOn: "Panel plugged in?",
						currentVoltageGood: "Current and Voltage levels good?",
						current: "Current",
						voltage: "Voltage",
						newNodeId: "New Node ID",
						goodPanelId: "Good Panel ID",
						commandQueue: "Commands to run",

						rmaNumN: "RMA Number Node",
					})

					contentInfo[5] = {
						label: "Replace Node, Record Node ID",
						value: ["newNodeId"],
					}

					contentInfo[6] = {
						label: "Record good Panel",
						value: ["goodPanelId"],
					}

					contentInfo[7] = {
						label: "Label with RMA number",
					}

					contentInfo[8] = {
						label: "Notes",
					}

					contentInfo[9] = {
						label: "Review",
					}
				} else if (panelOn === "no" && currentVoltageGood === "yes") {
					addRequiredAttributes({
						panelOn: "Panel plugged in?",
						currentVoltageGood: "Current and Voltage levels good?",
						current: "Current",
						voltage: "Voltage",
						goodPanelId: "Good Panel ID",
						commandQueue: "Commands to run",
					})

					contentInfo[5] = {
						label: "Plug in Node",
					}

					contentInfo[6] = {
						label: "Record good Panel",
						value: ["goodPanelId"],
					}

					contentInfo[7] = {
						label: "Notes",
					}

					contentInfo[8] = {
						label: "Review",
					}
				} else if (currentVoltageGood === "no") {
					createRMA("C")

					addRequiredAttributes({
						panelOn: "Panel plugged in?",
						currentVoltageGood: "Current and Voltage levels good?",
						current: "Current",
						voltage: "Voltage",
						goodPanelId: "Good Panel ID",
						badPanelId: "Bad Panel ID",
						commandQueue: "Commands to run",

						rmaNumC: "RMA Number Charging Panel",
					})

					contentInfo[5] = {
						label: "Replace Charging Panel",
						value: ["badPanelId", "goodPanelId"],
					}

					contentInfo[6] = {
						label: "Replace Charging Panel Command",
						value: ["Replace Charging Panel"],
					}

					contentInfo[7] = {
						label: "Label with RMA number",
					}

					contentInfo[8] = {
						label: "Notes",
					}

					contentInfo[9] = {
						label: "Review",
					}
				}
				break
			case "BI":
				contentInfo[1] = {
					label: "Inspect Wiring",
					value: ["fixedWiring"],
				}

				if (fixedWiring === "yes") {
					addRequiredAttributes({
						commandQueue: "Commands to run",

						fixedWiring: "Wiring fixed?",
					})

					contentInfo[2] = {
						label: "Notes",
					}

					contentInfo[3] = {
						label: "Review",
					}
				} else if (fixedWiring === "no") {
					contentInfo[2] = {
						label: "Inspect for physical and water damage",
						value: ["isDamaged"],
					}
				}

				if (isDamaged === "yes" && fixedWiring === "no") {
					createRMA("B")
					addRequiredAttributes({
						commandQueue: "Commands to run",

						goodBatteryId: "Good Battery ID",
						badBatteryId: "Bad Battery ID",
						goodBatteryVendor: "Good Battery Vendor",
						badBatteryVendor: "Bad Battery Vendor",
						fixedWiring: "Wiring fixed?",
						damageType: "Damage Type",
					})
					if (useBatteryRMA) {
						requiredAttributes["rmaNumB"] = "RMA Number Battery"
					} else {
						delete requiredAttributes["rmaNumB"]
					}

					contentInfo[3] = {
						label: "Record damage type",
						value: ["damageType"],
					}

					contentInfo[4] = {
						label: "Replace Battery",
						value: ["goodBatteryId", "badBatteryId", "goodBatteryVendor", "badBatteryVendor"],
					}

					contentInfo[5] = {
						label: "Label with RMA number",
					}

					contentInfo[6] = {
						label: "Notes",
					}

					contentInfo[7] = {
						label: "Review",
					}
				}

				if (fixedWiring === "no" && isDamaged === "no") {
					contentInfo[3] = {
						label: "Is Panel plugged in?",
						value: ["panelOn"],
					}

					contentInfo[4] = {
						label: "Voltage and Current Levels",
						value: ["currentVoltageGood", "current", "voltage"],
					}
				}

				if (panelOn === "yes" && currentVoltageGood === "yes") {
					createRMA("B")

					addRequiredAttributes({
						panelOn: "Panel plugged in?",
						currentVoltageGood: "Current and Voltage levels good?",
						current: "Current",
						voltage: "Voltage",
						commandQueue: "Commands to run",

						goodBatteryId: "Good Battery ID",
						badBatteryId: "Bad Battery ID",
						goodBatteryVendor: "Good Battery Vendor",
						badBatteryVendor: "Bad Battery Vendor",
					})

					if (useBatteryRMA) {
						requiredAttributes["rmaNumB"] = "RMA Number Battery"
					} else {
						delete requiredAttributes["rmaNumB"]
					}

					contentInfo[5] = {
						label: "Replace Battery",
						value: ["goodBatteryId", "badBatteryId", "goodBatteryVendor", "badBatteryVendor"],
					}

					contentInfo[6] = {
						label: "Label with RMA number",
					}

					contentInfo[7] = {
						label: "Notes",
					}

					contentInfo[8] = {
						label: "Review",
					}
				}
				if (panelOn === "no" && currentVoltageGood === "yes") {
					contentInfo[5] = {
						label: "Plug in Node",
					}

					contentInfo[6] = {
						label: "Check Battery Condition",
						value: ["batteryGood"],
					}
				}
				if (currentVoltageGood === "no") {
					contentInfo[5] = {
						label: "Replace Panel",
						value: ["badPanelId", "goodPanelId"],
					}

					contentInfo[6] = {
						label: "Check Battery Condition",
						value: ["batteryGood"],
					}
				}
				if (panelOn === "no" && currentVoltageGood === "yes" && batteryGood === "no") {
					createRMA("B")

					addRequiredAttributes({
						panelOn: "Panel plugged in?",
						currentVoltageGood: "Current and Voltage levels good?",
						current: "Current",
						voltage: "Voltage",
						batteryGood: "Battery good?",
						commandQueue: "Commands to run",

						goodBatteryId: "Good Battery ID",
						badBatteryId: "Bad Battery ID",
						goodBatteryVendor: "Good Battery Vendor",
						badBatteryVendor: "Bad Battery Vendor",
						//rmaNumB: 'RMA Number Battery',
					})

					if (useBatteryRMA) {
						requiredAttributes["rmaNumB"] = "RMA Number Battery"
					} else {
						delete requiredAttributes["rmaNumB"]
					}

					contentInfo[7] = {
						label: "Replace Battery",
						value: ["goodBatteryId", "badBatteryId", "goodBatteryVendor", "badBatteryVendor"],
					}

					contentInfo[8] = {
						label: "Label with RMA number",
					}

					contentInfo[9] = {
						label: "Notes",
					}

					contentInfo[10] = {
						label: "Review",
					}
				}
				if (panelOn === "no" && currentVoltageGood === "yes" && batteryGood === "yes") {
					addRequiredAttributes({
						panelOn: "Panel plugged in?",
						currentVoltageGood: "Current and Voltage levels good?",
						current: "Current",
						voltage: "Voltage",
						batteryGood: "Battery good?",
						commandQueue: "Commands to run",
					})

					contentInfo[7] = {
						label: "Battery is okay",
					}

					contentInfo[8] = {
						label: "Notes",
					}

					contentInfo[9] = {
						label: "Review",
					}
				}
				if (currentVoltageGood === "no" && batteryGood === "yes") {
					createRMA("C")
					addRequiredAttributes({
						panelOn: "Panel plugged in?",
						currentVoltageGood: "Current and Voltage levels good?",
						current: "Current",
						voltage: "Voltage",
						goodPanelId: "Good Panel ID",
						badPanelId: "Bad Panel ID",
						batteryGood: "Battery good?",
						commandQueue: "Commands to run",

						rmaNumC: "RMA Number Charging Panel",
					})

					contentInfo[7] = {
						label: "Label with RMA number",
					}

					contentInfo[8] = {
						label: "Notes",
					}

					contentInfo[9] = {
						label: "Review",
					}
				}
				if (currentVoltageGood === "no" && batteryGood === "no") {
					createRMA("C")
					createRMA("B")

					addRequiredAttributes({
						panelOn: "Panel plugged in?",
						currentVoltageGood: "Current and Voltage levels good?",
						current: "Current",
						voltage: "Voltage",
						goodPanelId: "Good Panel ID",
						badPanelId: "Bad Panel ID",
						batteryGood: "Battery good?",
						goodBatteryId: "Good Battery ID",
						badBatteryId: "Bad Battery ID",
						goodBatteryVendor: "Good Battery Vendor",
						badBatteryVendor: "Bad Battery Vendor",
						commandQueue: "Commands to run",

						rmaNumC: "RMA Number Charging Panel",
					})

					if (useBatteryRMA) {
						requiredAttributes["rmaNumB"] = "RMA Number Battery"
					} else {
						delete requiredAttributes["rmaNumB"]
					}

					contentInfo[7] = {
						label: "Replace Battery",
						value: ["goodBatteryId", "badBatteryId", "goodBatteryVendor", "badBatteryVendor"],
					}

					contentInfo[8] = {
						label: "Label with RMA number",
					}

					contentInfo[9] = {
						label: "Notes",
					}

					contentInfo[10] = {
						label: "Review",
					}
				}
				break
			case "NC":
				contentInfo[1] = {
					label: "Does the Node ID match what is on the label?",
					value: ["nodeIdMatch"],
				}

				if (nodeIdMatch === "yes") {
					contentInfo[2] = {
						label: "Inspect for wire damage",
						value: ["fixedWiring"],
					}
				} else if (nodeIdMatch === "no") {
					addRequiredAttributes({
						newNodeId: "New Node ID",

						nodeIdMatch: "Node ID match",
						commandQueue: "Commands to run",
					})

					contentInfo[2] = {
						label: "Replace Node ID to match the label",
						value: ["newNodeId"],
					}
					contentInfo[3] = {
						label: "Notes",
					}
					contentInfo[4] = {
						label: "Review",
					}
				}

				if (nodeIdMatch === "yes" && fixedWiring === "yes") {
					addRequiredAttributes({
						nodeIdMatch: "Node ID match",

						fixedWiring: "Wiring fixed?",
						commandQueue: "Commands to run",
					})
					contentInfo[3] = {
						label: "Notes",
					}
					contentInfo[4] = {
						label: "Review",
					}
				} else if (nodeIdMatch === "yes" && fixedWiring === "no") {
					contentInfo[3] = {
						label: "Inspect node for physical and water damage",
						value: ["isDamaged"],
					}
				}

				if (nodeIdMatch === "yes" && fixedWiring === "no" && isDamaged === "yes") {
					createRMA("N")
					addRequiredAttributes({
						newNodeId: "New Node ID",
						nodeIdMatch: "Node ID match",

						fixedWiring: "Wiring fixed?",
						damageType: "Damage Type",
						commandQueue: "Commands to run",
						rmaNumN: "RMA Number Node",
					})
					contentInfo[4] = {
						label: "Record damage type",
						value: ["damageType"],
					}
					contentInfo[5] = {
						label: "Replace Node ID to match the label",
						value: ["newNodeId"],
					}
					contentInfo[6] = {
						label: "Label with RMA number",
					}
					contentInfo[7] = {
						label: "Notes",
					}
					contentInfo[8] = {
						label: "Review",
					}
				} else if (nodeIdMatch === "yes" && fixedWiring === "no" && isDamaged === "no") {
					contentInfo[4] = {
						label: "Check Pan ID and Channel",
						value: ["channelPanIdGood"],
					}
				}

				if (nodeIdMatch === "yes" && fixedWiring === "no" && isDamaged === "no" && channelPanIdGood === "no") {
					addRequiredAttributes({
						newNodeId: "New Node ID",

						nodeIdMatch: "Node ID match",
						fixedWiring: "Wiring fixed?",
						badPanId: "Bad Pan ID",
						badChannel: "Bad Channel",
						goodPanId: "Good Pan ID",
						goodChannel: "Good Channel",
						commandQueue: "Commands to run",
					})
					contentInfo[5] = {
						label: "Record Pan IDs and Channels",
						value: ["badPanId", "badChannel", "goodPanId", "goodChannel"],
					}
					contentInfo[6] = {
						label: "Notes",
					}
					contentInfo[7] = {
						label: "Review",
					}
				} else if (nodeIdMatch === "yes" && fixedWiring === "no" && isDamaged === "no" && channelPanIdGood === "yes") {
					contentInfo[5] = {
						label: "Check E-stop and node fuse.",
						value: ["eStopPulled", "nodeFuse"],
					}
				}

				if (eStopPulled === "yes" && nodeFuse === "yes") {
					contentInfo[6] = {
						label: "E-stop has been pulled and the node has a fuse.",
					}
				} else if (eStopPulled === "no" && nodeFuse === "yes") {
					contentInfo[6] = {
						label: "Pull E-stop.",
					}
				} else if (eStopPulled === "yes" && nodeFuse === "no") {
					contentInfo[6] = {
						label: "Replace missing fuse.",
					}
				} else if (eStopPulled === "no" && nodeFuse === "no") {
					contentInfo[6] = {
						label: "Replace missing fuse and pull E-stop.",
					}
				}

				if (nodeIdMatch === "yes" && eStopPulled && nodeFuse) {
					contentInfo[7] = {
						label: "Is the light on or blinking?",
						value: ["lightOn"],
					}
				}

				if (lightOn === "no") {
					contentInfo[8] = {
						label: "Voltage and Current Levels",
						value: ["currentVoltageGood", "current", "voltage"],
					}
					if (currentVoltageGood === "no") {
						createRMA("C")
						createRMA("B")

						addRequiredAttributes({
							eStopPulled: "E-stop Pulled?",
							nodeFuse: "Node had fuse?",
							lightOn: "Light on/blinking",
							currentVoltageGood: "Current and Voltage good?",
							voltage: "Voltage",
							current: "Current",
							fixedWiring: "Wiring fixed?",
							goodBatteryId: "Good Battery ID",
							badBatteryId: "Bad Battery ID",
							goodBatteryVendor: "Good Battery Vendor",
							badBatteryVendor: "Bad Battery Vendor",
							goodPanelId: "Good Panel ID",
							badPanelId: "Bad Panel ID",

							nodeIdMatch: "Node ID match",
							commandQueue: "Commands to run",
							rmaNumC: "RMA Number Charging Panel",
							//rmaNumB: 'RMA Number Battery',
						})
						if (useBatteryRMA) {
							requiredAttributes["rmaNumB"] = "RMA Number Battery"
						} else {
							delete requiredAttributes["rmaNumB"]
						}

						contentInfo[9] = {
							label: "Replace Charging Panel",
							value: ["goodPanelId", "badPanelId"],
						}

						contentInfo[10] = {
							label: "Replace Charging Panel Command",
							value: ["Replace Charging Panel"],
						}

						contentInfo[11] = {
							label: "Replace Battery",
							value: ["goodBatteryId", "badBatteryId", "goodBatteryVendor", "badBatteryVendor"],
						}

						contentInfo[12] = {
							label: "Label with RMA number",
						}

						contentInfo[13] = {
							label: "Notes",
						}

						contentInfo[14] = {
							label: "Review",
						}
					} else if (currentVoltageGood === "yes") {
						createRMA("N")

						addRequiredAttributes({
							eStopPulled: "E-stop Pulled?",
							nodeFuse: "Node had fuse?",
							lightOn: "Light on/blinking",
							currentVoltageGood: "Current and Voltage good?",
							newNodeId: "New Node ID",
							voltage: "Voltage",
							current: "Current",

							nodeIdMatch: "Node ID match",
							fixedWiring: "Wiring fixed?",
							commandQueue: "Commands to run",
							rmaNumN: "RMA Number Node",
						})

						contentInfo[9] = {
							label: "Replace Node, Record Node ID",
							value: ["newNodeId"],
						}

						contentInfo[10] = {
							label: "Label with RMA number",
						}

						contentInfo[11] = {
							label: "Notes",
						}

						contentInfo[12] = {
							label: "Review",
						}
					}
				}
				if (lightOn === "yes") {
					contentInfo[8] = {
						label: "Did the E-Stop respond?",
						value: ["eStopRespond"],
					}
					if (eStopRespond === "yes") {
						contentInfo[9] = {
							label: "Run Replace Node Commands",
							value: ["Replace Node"],
						}

						contentInfo[10] = {
							label: "Is it Tracking?",
							value: ["tracking"],
						}

						if (tracking === "yes") {
							addRequiredAttributes({
								eStopPulled: "E-stop Pulled?",
								nodeFuse: "Node had fuse?",
								lightOn: "Light on/blinking",
								eStopRespond: "Estop Responded",
								tracking: "Tracking",

								nodeIdMatch: "Node ID match",
								fixedWiring: "Wiring fixed?",
								commandQueue: "Commands to run",
							})

							contentInfo[11] = {
								label: "Notes",
							}

							contentInfo[12] = {
								label: "Review",
							}
						} else if (tracking === "no") {
							createRMA("N")

							addRequiredAttributes({
								eStopPulled: "E-stop Pulled?",
								nodeFuse: "Node had fuse?",
								lightOn: "Light on/blinking",
								eStopRespond: "Estop Responded",
								tracking: "Tracking",
								newNodeId: "New Node ID",

								nodeIdMatch: "Node ID match",
								fixedWiring: "Wiring fixed?",
								commandQueue: "Commands to run",
								rmaNumN: "RMA Number Node",
							})
							contentInfo[11] = {
								label: "Replace Node, Record Node ID",
								value: ["newNodeId"],
							}

							contentInfo[12] = {
								label: "Label with RMA number",
							}

							contentInfo[13] = {
								label: "Notes",
							}

							contentInfo[14] = {
								label: "Review",
							}
						}
					} else if (eStopRespond === "no") {
						contentInfo[9] = {
							label: "Reset Connection",
						}
						if (eStopRespondReset === "yes") {
							contentInfo[10] = {
								label: "Run Replace Node Commands",
								value: ["Replace Node"],
							}

							contentInfo[11] = {
								label: "Is it Tracking?",
								value: ["tracking"],
							}
							if (tracking === "yes") {
								addRequiredAttributes({
									eStopPulled: "E-stop Pulled?",
									nodeFuse: "Node had fuse?",
									lightOn: "Light on/blinking",
									eStopRespond: "Estop Responded",
									eStopRespondReset: "Estop Responded After Reset",
									tracking: "Tracking",

									nodeIdMatch: "Node ID match",
									fixedWiring: "Wiring fixed?",
									commandQueue: "Commands to run",
								})

								contentInfo[12] = {
									label: "Notes",
								}

								contentInfo[13] = {
									label: "Review",
								}
							} else if (tracking === "no") {
								createRMA("N")

								addRequiredAttributes({
									eStopPulled: "E-stop Pulled?",
									nodeFuse: "Node had fuse?",
									lightOn: "Light on/blinking",
									eStopRespond: "Estop Responded",
									eStopRespondReset: "Estop Responded After Reset",
									tracking: "Tracking",
									newNodeId: "New Node ID",

									nodeIdMatch: "Node ID match",
									fixedWiring: "Wiring fixed?",
									commandQueue: "Commands to run",
									rmaNumN: "RMA Number Node",
								})
								contentInfo[12] = {
									label: "Replace Node, Record Node ID",
									value: ["newNodeId"],
								}

								contentInfo[13] = {
									label: "Label with RMA number",
								}

								contentInfo[14] = {
									label: "Notes",
								}

								contentInfo[15] = {
									label: "Review",
								}
							}
						} else if (eStopRespondReset === "no") {
							createRMA("N")

							addRequiredAttributes({
								eStopPulled: "E-stop Pulled?",
								nodeFuse: "Node had fuse?",
								lightOn: "Light on/blinking",
								eStopRespond: "Estop Responded",
								eStopRespondReset: "Estop Responded After Reset",
								newNodeId: "New Node ID",

								nodeIdMatch: "Node ID match",
								fixedWiring: "Wiring fixed?",
								commandQueue: "Commands to run",
								rmaNumN: "RMA Number Node",
							})
							contentInfo[10] = {
								label: "Replace Node, Record Node ID",
								value: ["newNodeId"],
							}

							contentInfo[11] = {
								label: "Label with RMA number",
							}

							contentInfo[12] = {
								label: "Notes",
							}

							contentInfo[13] = {
								label: "Review",
							}
						}
					}
				}
				break
			case "BO":
				createRMA("N")

				addRequiredAttributes({
					newNodeId: "New Node ID",

					commandQueue: "Commands to run",
					rmaNumN: "RMA Number Node",
				})

				contentInfo[1] = {
					label: "Unplug Charging Panel",
					value: ["protectionGood"],
				}
				contentInfo[2] = {
					label: "Vent the node enclosure",
				}
				contentInfo[3] = {
					label: "Replace Node",
					value: ["newNodeId"],
				}
				contentInfo[4] = {
					label: "Label with RMA number",
				}
				contentInfo[5] = {
					label: "Notes",
				}

				contentInfo[6] = {
					label: "Review",
				}
				break
			case "EI":
				addRequiredAttributes({
					commandQueue: "Commands to run",
					tracking: "Tracking",
				})

				contentInfo[1] = {
					label: "Check E-Stop",
				}
				contentInfo[2] = {
					label: "Run Commands",
					value: ["Reset All Node Alarms", "Tracking-Enabled"],
				}
				contentInfo[3] = {
					label: "Is it Tracking?",
					value: ["tracking"],
				}

				if (tracking === "yes") {
					contentInfo[4] = {
						label: "Notes",
					}
					contentInfo[5] = {
						label: "Review",
					}
				} else if (tracking === "no") {
					contentInfo[4] = {
						label: "Repair or Replace Estop / Re-Run Commands",
						value: ["Reset All Node Alarms(2)", "Tracking-Enabled(2)"],
					}
					contentInfo[5] = {
						label: "Notes",
					}
					contentInfo[6] = {
						label: "Review",
					}
				}
				break
			case "RF":
				contentInfo[1] = {
					label: "Campaign Component",
				}

				if (retrofitComponent === "board") {
					addRequiredAttributes({
						commandQueue: "Commands to run",
						retrofitComponent: "Retrofit Component",
						newNodeId: "New Node ID",
					})
					contentInfo[2] = {
						label: "Replace Node",
						value: ["newNodeId"],
					}
					contentInfo[3] = {
						label: "Notes",
					}
					contentInfo[4] = {
						label: "Review",
					}
				} else if (retrofitComponent === "battery") {
					addRequiredAttributes({
						commandQueue: "Commands to run",
						retrofitComponent: "Retrofit Component",
						goodBatteryId: "Good Battery ID",
						badBatteryId: "Bad Battery ID",
						goodBatteryVendor: "Good Battery Vendor",
						badBatteryVendor: "Bad Battery Vendor",
					})
					contentInfo[2] = {
						label: "Replace Battery",
						value: ["goodBatteryId", "badBatteryId", "goodBatteryVendor", "badBatteryVendor"],
					}
					contentInfo[3] = {
						label: "Notes",
					}
					contentInfo[4] = {
						label: "Review",
					}
				} else if (retrofitComponent === "actuator") {
					addRequiredAttributes({
						commandQueue: "Commands to run",
						retrofitComponent: "Retrofit Component",
						goodActuatorModelNumber: "Good Actuator model number",
						goodActuatorProductionDate: "Good Actuator production date",
						goodActuatorVendorInitial: "Good Actuator vendor initial",
						goodActuatorSerialNum: "Good Actuator Serial Number",
						badActuatorModelNumber: "Bad Actuator model number",
						badActuatorProductionDate: "Bad Actuator production date",
						badActuatorVendorInitial: "Bad Actuator vendor initial",
						badActuatorSerialNum: "Bad Actuator Serial Number",
					})
					contentInfo[2] = {
						label: "Record good Actuator",
						value: [
							"badActuatorModelNumber",
							"badActuatorProductionDate",
							"badActuatorVendorInitial",
							"badActuatorSerialNum",
							"goodActuatorModelNumber",
							"goodActuatorProductionDate",
							"goodActuatorVendorInitial",
							"goodActuatorSerialNum",
						],
					}

					contentInfo[3] = {
						label: 'Send "Replace Actuator" command',
						value: ["Replace Actuator"],
					}
					contentInfo[4] = {
						label: "Notes",
					}
					contentInfo[5] = {
						label: "Review",
					}
				} else if (retrofitComponent === "2.8.2 board") {
					addRequiredAttributes({
						commandQueue: "Commands to run",
						retrofitComponent: "Retrofit Component",
						newNodeId: "New Node ID",
						boardVendor: "2.8.2 Board Vendor",
						bomAlternate: "2.8.2 BOM Alternate",
						bareBoardVersion: "2.8.2 Bare Board Version",
						generation: "Generation of site",
						buildDate: "Build date",
					})
					contentInfo[2] = {
						label: "Replace 2.8.2 board",
						value: ["boardVendor"],
					}
					contentInfo[3] = {
						label: "Replace Node",
						value: ["newNodeId"],
					}
					contentInfo[4] = {
						label: "2.8.2 BOM alternate",
						value: ["bomAlternate"],
					}
					contentInfo[5] = {
						label: "2.8.2 Bare Board Version",
						value: ["bareBoardVersion"],
					}
					contentInfo[6] = {
						label: "Miscellaneous",
						value: ["generation", "buildDate"],
					}
					contentInfo[7] = {
						label: "Notes",
					}
					contentInfo[8] = {
						label: "Review",
					}
				} else if (retrofitComponent === "2.8.3 remote inclinometer") {
					addRequiredAttributes({
						commandQueue: "Commands to run",
						retrofitComponent: "Retrofit Component",
						batchNumber: "Remote Inclinometer Batch Number",
					})
					contentInfo[2] = {
						label: "Replace 2.8.3 remote inclinometer",
						value: ["batchNumber"],
					}
					contentInfo[3] = {
						label: "Notes",
					}
				} else if (retrofitComponent === "2.8.3 board") {
					addRequiredAttributes({
						commandQueue: "Commands to run",
						retrofitComponent: "Retrofit Component",
						newNodeId: "New Node ID",
						boardVendor: "2.8.3 Board Vendor",
						bomAlternate: "2.8.3 BOM Alternate",
						bareBoardVersion: "2.8.3 Bare Board Version",
						generation: "Generation of site",
						buildDate: "Build date",
					})
					contentInfo[2] = {
						label: "Replace 2.8.3 board",
						value: ["boardVendor", "batchNumber"],
					}
					contentInfo[3] = {
						label: "Replace Node",
						value: ["newNodeId"],
					}
					contentInfo[4] = {
						label: "2.8.3 BOM alternate",
						value: ["bomAlternate"],
					}
					contentInfo[5] = {
						label: "2.8.3 Bare Board Version",
						value: ["bareBoardVersion"],
					}
					contentInfo[6] = {
						label: "Miscellaneous",
						value: ["generation", "buildDate"],
					}
					contentInfo[7] = {
						label: "Notes",
					}
					contentInfo[8] = {
						label: "Review",
					}
				}
				break
			default:
				return { contentInfo, requiredAttributes }
		}
		return { contentInfo, requiredAttributes }
	}

	let { contentInfo, requiredAttributes } = createContentInfo()
	let steps = Object.keys(contentInfo).map((step) => {
		return contentInfo[step].label
	})

	const handleClose = () => {
		setTaskInfo({ activeStep: 0 })
	}

	const handleNext = () => {
		setTaskInfo({ activeStep: activeStep + 1 })
	}

	const handleSubmit = () => {
		let timestamp = Math.round(new Date().getTime() / 1000)
		let input = { rowNum, fromSouth, layoutName, masterName, mLocId, timestamp }
		Object.keys(requiredAttributes).forEach((item) => {
			if (item === "rmaNumN" || item === "rmaNumA" || item === "rmaNumC" || item === "rmaNumB") {
				if (!input["rmaNumbers"]) {
					input["rmaNumbers"] = [taskInfo[item]]
				} else {
					input["rmaNumbers"].push(taskInfo[item])
				}
			} else {
				input[item] = taskInfo[item]
			}
		})

		createTask(input)
		setTaskInfo({ activeStep: activeStep + 1 })
	}

	const handleBack = () => {
		setTaskInfo({ activeStep: activeStep - 1 })
	}

	const handleReset = () => {
		Object.keys(taskInfo).forEach((item) => {
			setTaskInfo({ [item]: null })
		})
		setTaskInfo({ issueType: null, activeStep: 0, commandQueue: [] })
		setUseBatteryRMA(true)
	}

	return (
		<div>
			<Paper
				open={true}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className={classes.root}
			>
				<DialogTitle id="alert-dialog-title" className={classes.title}>
					Troubleshoot Wizard
				</DialogTitle>
				<DialogContent className={classes.content}>
					<div className={classes.root}>
						<Stepper activeStep={activeStep} orientation="vertical">
							{steps.map((label, index) => (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
									<StepContent className={classes.stepContent}>
										<Typography component={"span"}>
											{getStepContent(
												index,
												steps,
												taskInfo,
												setTaskInfo,
												validateStep,
												requiredAttributes,
												useBatteryRMA,
												setUseBatteryRMA,
												setWizardInfo,
											)}
										</Typography>
										<div className={classes.actionsContainer}>
											<div>
												<Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
													Back
												</Button>
												<Button
													variant="contained"
													color="primary"
													onClick={activeStep === steps.length - 1 && activeStep !== 0 ? handleSubmit : handleNext}
													disabled={
														contentInfo[activeStep] &&
														contentInfo[activeStep].value &&
														!validateStep(contentInfo[activeStep].value)
													}
													className={classes.button}
												>
													{activeStep === steps.length - 1 && activeStep !== 0 ? "Submit" : "Next"}
												</Button>
											</div>
										</div>
									</StepContent>
								</Step>
							))}
						</Stepper>
						{activeStep === steps.length && (
							<Paper square elevation={0} className={classes.resetContainer}>
								<Typography component={"span"}>All steps completed - you&apos;re finished</Typography>
								<Button onClick={handleReset} variant="contained" color="primary" className={classes.replaceAnotherBtn}>
									Start Again
								</Button>
							</Paper>
						)}
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleReset} variant="contained" color="primary" autoFocus>
						Reset
					</Button>
				</DialogActions>
			</Paper>
		</div>
	)
}

TaskWizard.propTypes = {
	taskInfo: PropTypes.object.isRequired,
	setTaskInfo: PropTypes.func.isRequired,
	nodeDetails: PropTypes.object.isRequired,
	masterDetails: PropTypes.object.isRequired,
	mLocId: PropTypes.string.isRequired,
	nLocId: PropTypes.any.isRequired,
	createTask: PropTypes.func.isRequired,
	layoutName: PropTypes.string.isRequired,
	layoutNumber: PropTypes.number.isRequired,
	setWizardInfo: PropTypes.func.isRequired,
	newDeviceId: PropTypes.string,
	processRunning: PropTypes.bool,
}

export default TaskWizard
