import {
	bootTypeDescriptions,
	getEnabledStowModesString,
	getMasterAlarmsString,
	getMasterFlagsString,
	getSmartStowModeString,
	masterAlarmEnums,
	masterGen3PowerReadStatus,
	stowModeDescriptions,
	trackingModeDescriptions,
	WindSensorTypeEnum,
} from "./statusBits"

import { deviceTypes } from "./deviceTypes"
import { formatTimestamp } from "utils/formatters"

const defaultFormat = (value) => {
	if (value !== null) {
		return value
	} else {
		return "---"
	}
}

/**
 * Named for clarity, but this is just a bitwise AND operation, checking if the mask completely covers the bitfield.
 * Given a bitfield and a mask, returns true if any bit in the mask is set in the bitfield.
 * For example:
 * isBitSet(0b1010, 0b1000) === true
 * isBitSet(0b1010, 0b0100) === false
 * @param bitfield Bitfield to check
 * @param mask Mask to check (typically just one bit, e.g. 0b00010000)
 * @returns {boolean} True if any bit in the mask is set in the bitfield, false otherwise.
 */
function isBitSet(bitfield, mask) {
	return (BigInt(bitfield ?? 0n) & mask) !== 0n
}

export const getMasterDetailsFormats = (userPrivileges, deviceType) => {
	const MasterDetailsFormats = {
		[deviceTypes.GAMECHANGE_GEN3]: [
			{
				id: "mId",
				label: "MasterID",
				args: ["mId"],
				formatString: defaultFormat,
			},
		],
		[deviceTypes.GAMECHANGE_GEN3_DUAL]: [
			{
				id: "mId",
				label: "MasterID",
				args: ["mId"],
				formatString: defaultFormat,
			},
		],
		[deviceTypes.GAMECHANGE_GEN4]: [
			{
				id: "mId",
				label: "MasterID",
				args: ["mId"],
				formatString: defaultFormat,
			},
		],
	}
	return MasterDetailsFormats[deviceType]
}

export const getMasterDataFormats = (userPrivileges, deviceType, tz) => {
	const MasterDataFormats = {
		[deviceTypes.P4Q]: [],
		[deviceTypes.GAMECHANGE_GEN3]: [
			{
				id: "lastUpdate",
				label: "Last Update",
				args: ["timestamp", "HoursSinceSuccess"],
				formatString: (time, hoursSinceSuccess) => {
					if (time) {
						if (hoursSinceSuccess > 0) {
							return time + " ( " + hoursSinceSuccess.toString() + " Hours Since Update )"
						} else {
							return formatTimestamp(time, tz)
						}
					} else {
						return "---"
					}
				},
			},
			{
				id: "activeAlarms",
				label: "Active Alarms",
				args: ["alarms"],
				formatString: getMasterAlarmsString(userPrivileges, deviceTypes.GAMECHANGE_GEN3),
			},
			{
				id: "activeFlags",
				label: "Active Flags",
				args: ["flags"],
				formatString: getMasterFlagsString(userPrivileges, deviceTypes.GAMECHANGE_GEN3),
			},
			{
				id: "trackingMode",
				label: "Tracking Mode",
				args: ["trackingMode"],
				formatString: (trackingMode) => {
					if (trackingMode !== null) {
						return trackingModeDescriptions[deviceTypes.GAMECHANGE_GEN3][trackingMode]
					} else {
						return null
					}
				},
			},
			{
				id: "stowModesActive",
				label: "Active Stow Mode",
				args: ["stowModesActive"],
				formatString: (activeStowMode) => {
					if (activeStowMode === null) return null
					return stowModeDescriptions[deviceTypes.GAMECHANGE_GEN3][parseInt(activeStowMode, 16)]
				},
			},
			{
				id: "stowModesEnabled",
				label: "Enabled Stow Modes",
				args: ["stowModesEnabled"],
				formatString: getEnabledStowModesString(deviceTypes.GAMECHANGE_GEN3),
			},
			{
				id: "smartStowMode",
				label: "AutoSmart Stow Status",
				args: ["smartStowMode"],
				formatString: getSmartStowModeString,
			},
			{
				id: "trackingEnvelope",
				label: "Tracking Envelope",
				args: ["stowLimitEast", "stowLimitWest"],
				formatString: (eastLimit, westLimit) => {
					if (eastLimit === null || westLimit === null) return null
					const eastEnvelope =
						parseInt(eastLimit, 10) > 0
							? eastLimit.toString() + " Degrees West"
							: Math.abs(eastLimit).toString() + " Degrees East"
					const westEnvelope =
						parseInt(westLimit, 10) > 0
							? westLimit.toString() + " Degrees West"
							: Math.abs(westLimit).toString() + " Degrees East"
					return eastEnvelope + " to " + westEnvelope
				},
			},
			{
				id: "battery",
				label: "Battery",
				args: ["batteryVoltage", "chargeCurrent", "powerReadStatus", "activeAlarms"],
				formatString: (voltage_V, current_A, powerReadSuccess, activeAlarms) => {
					if (voltage_V === null || current_A === null) return null
					activeAlarms = activeAlarms ?? 0n
					powerReadSuccess = powerReadSuccess ?? 0n
					const acStatus = isBitSet(activeAlarms, masterAlarmEnums[deviceTypes.GAMECHANGE_GEN3].AC_DISCONNECTED)
						? "AC: OFF"
						: "AC: ON"
					const voltage = !isBitSet(powerReadSuccess, masterGen3PowerReadStatus.VOTLAGE_READ_ERROR)
						? "Voltage: " + voltage_V.toFixed(2).toString() + " (V)"
						: "Voltage: --- (V)"
					const current = !isBitSet(powerReadSuccess, masterGen3PowerReadStatus.CURRENT_READ_ERROR)
						? "Current: " + current_A.toFixed(3).toString() + " (A)"
						: "Current: --- (A)"
					return voltage + " / " + current + " / " + acStatus
				},
			},
			{
				id: "snow",
				label: "Snow Reading",
				args: ["depth", "activeBaseline", "activeAlarms"],
				formatString: (depth_in, baseline_in, alarms) => {
					alarms = alarms ?? 0n
					if (depth_in === null || baseline_in === null) return null
					if (isBitSet(alarms, masterAlarmEnums[deviceTypes.GAMECHANGE_GEN3].SNOW_STOW_DISABLED)) {
						return "Disabled"
					} else {
						return (
							"Depth: " +
							depth_in.toFixed(2).toString() +
							" (inches) / Baseline: " +
							(baseline_in / 12).toFixed(2).toString() +
							" (ft)"
						)
					}
				},
			},
			{
				id: "windSensorType",
				label: "Wind Sensor Type",
				args: ["windSensorType"],
				formatString: (windSensorType) => {
					return WindSensorTypeEnum[windSensorType] || "UNKNOWN"
				},
			},
			{
				id: "wind",
				label: "Wind Reading",
				args: ["windPeak", "windAverage"],
				formatString: (windPeak, windAverage) => {
					function formatValue(value) {
						if (value === null) return "N/A"
						return value.toFixed(1).toString() + " (mph)"
					}
					return `Peak: ${formatValue(windPeak)} / Average: ${formatValue(windAverage)}`
				},
			},
			{
				id: "temps",
				label: "Temperature",
				args: ["tempBoard", "tempCharger", "powerReadStatus"],
				formatString: (tempBoard, tempCharger, powerReadSuccess) => {
					if (tempBoard === null || tempCharger === null) return null

					const temperatureBoard = !isBitSet(powerReadSuccess, masterGen3PowerReadStatus.BOARD_TEMP_READ_ERROR)
						? "Board: " + (tempBoard * (9 / 5) + 32).toFixed(2).toString() + " (F)"
						: "Board: --- (F)"

					const temperatureCharger = isBitSet(powerReadSuccess, masterGen3PowerReadStatus.CHARGER_TEMP_READ_ERROR)
						? "Charger: " + (tempCharger * (9 / 5) + 32).toFixed(2).toString() + " (F)"
						: "Charger: --- (F)"
					return temperatureBoard + " / " + temperatureCharger
				},
			},
			{
				id: "boot",
				label: "Last Boot",
				args: ["bootTime", "resetType"],
				formatString: (bootTime, bootType) => {
					if (bootTime === null) return null
					return (
						"Time: " +
						formatTimestamp(bootTime, tz) +
						" / Type: " +
						bootTypeDescriptions[deviceTypes.GAMECHANGE_GEN3][bootType]
					)
				},
			},
			{
				id: "fwMaster",
				label: "Master Firmware Version",
				args: ["fwMaster"],
				formatString: (fwMaster) => {
					return fwMaster ? `v${fwMaster}` : "Not specified"
				},
			},
			{
				id: "fwNode",
				label: "Node Firmware Version",
				args: ["fwNode"],
				formatString: (fwNode) => {
					if (fwNode === null) return null
					return `v${fwNode}`
				},
			},
			{
				id: "arrrDuration",
				label: "Call & Response Duration",
				args: ["arrrDuration"],
				formatString: (arrrDuration) => {
					if (arrrDuration === null) return null
					return arrrDuration.toString()
				},
			},
		],
		[deviceTypes.GAMECHANGE_GEN3_DUAL]: [
			{
				id: "lastUpdate",
				label: "Last Update",
				args: ["timestamp", "HoursSinceSuccess"],
				formatString: (time, hoursSinceSuccess) => {
					if (time) {
						if (hoursSinceSuccess > 0) {
							return time + " ( " + hoursSinceSuccess.toString() + " Hours Since Update )"
						} else {
							return formatTimestamp(time, tz)
						}
					} else {
						return "---"
					}
				},
			},
			{
				id: "activeAlarms",
				label: "Active Alarms",
				args: ["alarms"],
				formatString: getMasterAlarmsString(userPrivileges, deviceTypes.GAMECHANGE_GEN3),
			},
			{
				id: "activeFlags",
				label: "Active Flags",
				args: ["flags"],
				formatString: getMasterFlagsString(userPrivileges, deviceTypes.GAMECHANGE_GEN3),
			},
			{
				id: "trackingMode",
				label: "Tracking Mode",
				args: ["trackingMode"],
				formatString: (trackingMode) => {
					if (trackingMode !== null) {
						return trackingModeDescriptions[deviceTypes.GAMECHANGE_GEN3][trackingMode]
					} else {
						return "---"
					}
				},
			},
			{
				id: "stowModesActive",
				label: "Active Stow Mode",
				args: ["stowModesActive"],
				formatString: (activeStowMode) => {
					if (activeStowMode !== null) {
						return stowModeDescriptions[deviceTypes.GAMECHANGE_GEN3][parseInt(activeStowMode, 16)]
					} else {
						return "---"
					}
				},
			},
			{
				id: "stowModesEnabled",
				label: "Enabled Stow Modes",
				args: ["stowModesEnabled"],
				formatString: getEnabledStowModesString(deviceTypes.GAMECHANGE_GEN3),
			},
			{
				id: "trackingEnvelope",
				label: "Tracking Envelope",
				args: ["stowLimitEast", "stowLimitWest"],
				formatString: (eastLimit, westLimit) => {
					if (eastLimit !== null) {
						const eastEnvelope =
							parseInt(eastLimit, 10) > 0
								? eastLimit.toString() + " Degrees West"
								: Math.abs(eastLimit).toString() + " Degrees East"
						const westEnvelope =
							parseInt(westLimit, 10) > 0
								? westLimit.toString() + " Degrees West"
								: Math.abs(westLimit).toString() + " Degrees East"
						return eastEnvelope + " to " + westEnvelope
					} else {
						return "---"
					}
				},
			},
			{
				id: "battery",
				label: "Battery",
				args: ["batteryVoltage", "chargeCurrent", "powerReadStatus", "activeAlarms"],
				formatString: (voltage_V, current_A, powerReadSuccess, activeAlarms) => {
					if (voltage_V !== null && current_A !== null) {
						const acStatus = isBitSet(activeAlarms, masterAlarmEnums[deviceTypes.GAMECHANGE_GEN3].AC_DISCONNECTED)
							? "AC: OFF"
							: "AC: ON"
						const voltage = !isBitSet(powerReadSuccess, masterGen3PowerReadStatus.VOTLAGE_READ_ERROR)
							? "Voltage: " + voltage_V.toFixed(2).toString() + " (V)"
							: "Voltage: --- (V)"
						const current = !isBitSet(powerReadSuccess, masterGen3PowerReadStatus.CURRENT_READ_ERROR)
							? "Current: " + current_A.toFixed(3).toString() + " (A)"
							: "Current: --- (A)"
						return voltage + " / " + current + " / " + acStatus
					} else {
						return "---"
					}
				},
			},
			{
				id: "snow",
				label: "Snow Reading",
				args: ["depth", "activeBaseline", "activeAlarms"],
				formatString: (depth_in, baseline_in, alarms) => {
					if (depth_in !== null && baseline_in !== null) {
						alarms = alarms ?? 0n
						if ((alarms & masterAlarmEnums[deviceTypes.GAMECHANGE_GEN3].SNOW_STOW_DISABLED) !== 0n) {
							return "Disabled"
						} else {
							return (
								"Depth: " +
								depth_in.toFixed(2).toString() +
								" (inches) / Baseline: " +
								(baseline_in / 12).toFixed(2).toString() +
								" (ft)"
							)
						}
					} else {
						return "---"
					}
				},
			},
			{
				id: "wind",
				label: "Wind Reading",
				args: ["windPeak", "windAverage"],
				formatString: (windPeak, windAverage) => {
					if (windPeak !== null) {
						return (
							"Peak: " +
							windPeak.toFixed(1).toString() +
							" (mph) / Average: " +
							windAverage.toFixed(1).toString() +
							" (mph)"
						)
					} else {
						return "---"
					}
				},
			},
			{
				id: "temps",
				label: "Temperature",
				args: ["tempBoard", "tempCharger", "powerReadStatus"],
				formatString: (tempBoard, tempCharger, powerReadSuccess) => {
					if (tempBoard !== null && tempCharger !== null) {
						const temperatureBoard = !isBitSet(powerReadSuccess, masterGen3PowerReadStatus.BOARD_TEMP_READ_ERROR)
							? "Board: " + (tempBoard * (9 / 5) + 32).toFixed(2).toString() + " (F)"
							: "Board: --- (F)"
						const temperatureCharger = !isBitSet(powerReadSuccess, masterGen3PowerReadStatus.CHARGER_TEMP_READ_ERROR)
							? "Charger: " + (tempCharger * (9 / 5) + 32).toFixed(2).toString() + " (F)"
							: "Charger: --- (F)"
						return temperatureBoard + " / " + temperatureCharger
					} else {
						return "---"
					}
				},
			},
			{
				id: "boot",
				label: "Last Boot",
				args: ["bootTime", "resetType"],
				formatString: (bootTime, bootType) => {
					if (bootTime !== null) {
						return (
							"Time: " +
							formatTimestamp(bootTime, tz) +
							" / Type: " +
							bootTypeDescriptions[deviceTypes.GAMECHANGE_GEN3][bootType]
						)
					} else {
						return "---"
					}
				},
			},
			{
				id: "fwMaster",
				label: "Master Firmware Version",
				args: ["fwMaster"],
				formatString: (fwMaster) => {
					return fwMaster ? `v${fwMaster}` : "Not specified"
				},
			},
			{
				id: "fwNode",
				label: "Node Firmware Version",
				args: ["fwNode"],
				formatString: (fwNode) => {
					if (fwNode) {
						return `v${fwNode}`
					} else {
						return "---"
					}
				},
			},
		],
		[deviceTypes.GAMECHANGE_GEN4]: [
			{
				id: "lastUpdate",
				label: "Last Update",
				args: ["timestamp", "HoursSinceSuccess"],
				formatString: (time, hoursSinceSuccess) => {
					if (time) {
						if (hoursSinceSuccess > 0) {
							return time + " ( " + hoursSinceSuccess.toString() + " Hours Since Update )"
						} else {
							return formatTimestamp(time, tz)
						}
					} else {
						return "---"
					}
				},
			},
			{
				id: "activeAlarms",
				label: "Active Alarms",
				args: ["alarms"],
				formatString: getMasterAlarmsString(userPrivileges, deviceTypes.GAMECHANGE_GEN4),
			},
			{
				id: "activeFlags",
				label: "Active Flags",
				args: ["flags"],
				formatString: getMasterFlagsString(userPrivileges, deviceTypes.GAMECHANGE_GEN4),
			},
			{
				id: "trackingMode",
				label: "Tracking Mode",
				args: ["trackingMode"],
				formatString: (trackingMode) => {
					if (trackingMode !== null) {
						return trackingModeDescriptions[deviceTypes.GAMECHANGE_GEN4][trackingMode]
					} else {
						return "---"
					}
				},
			},
			{
				id: "stowModesActive",
				label: "Active Stow Mode",
				args: ["stowModesActive"],
				formatString: (activeStowMode) => {
					if (activeStowMode !== null) {
						return stowModeDescriptions[deviceTypes.GAMECHANGE_GEN4][activeStowMode]
					} else {
						return "---"
					}
				},
			},
			{
				id: "stowModesEnabled",
				label: "Enabled Stow Modes",
				args: ["stowModesEnabled"],
				formatString: getEnabledStowModesString(deviceTypes.GAMECHANGE_GEN4),
			},
			{
				id: "smartStowMode",
				label: "AutoSmart Stow Status",
				args: ["smartStowMode"],
				formatString: getSmartStowModeString,
			},
			{
				id: "trackingEnvelope",
				label: "Tracking Envelope",
				args: ["stowLimitEast", "stowLimitWest"],
				formatString: (eastLimit, westLimit) => {
					if (eastLimit !== null) {
						const eastEnvelope =
							parseInt(eastLimit, 10) > 0
								? eastLimit.toString() + " Degrees West"
								: Math.abs(eastLimit).toString() + " Degrees East"
						const westEnvelope =
							parseInt(westLimit, 10) > 0
								? westLimit.toString() + " Degrees West"
								: Math.abs(westLimit).toString() + " Degrees East"
						return eastEnvelope + " to " + westEnvelope
					} else {
						return "---"
					}
				},
			},
			{
				id: "battery",
				label: "Battery",
				args: ["batteryVoltage", "chargeCurrent", "powerReadStatus", "activeAlarms"],
				formatString: (voltage_V, current_A, powerReadSuccess, activeAlarms) => {
					if (voltage_V !== null && current_A !== null) {
						const acStatus =
							(activeAlarms & masterAlarmEnums[deviceTypes.GAMECHANGE_GEN4].AC_DISCONNECTED) !== 0
								? "AC: OFF"
								: "AC: ON"
						const voltage = !isBitSet(powerReadSuccess, masterGen3PowerReadStatus.VOTLAGE_READ_ERROR)
							? "Voltage: " + voltage_V.toFixed(2).toString() + " (V)"
							: "Voltage: --- (V)"
						const current = !isBitSet(powerReadSuccess, masterGen3PowerReadStatus.CURRENT_READ_ERROR)
							? "Current: " + current_A.toFixed(3).toString() + " (A)"
							: "Current: --- (A)"
						return voltage + " / " + current + " / " + acStatus
					} else {
						return "---"
					}
				},
			},
			{
				id: "snow",
				label: "Snow Reading",
				args: ["depth", "activeBaseline", "activeAlarms"],
				formatString: (depth_in, baseline_in, alarms) => {
					if (depth_in !== null && baseline_in !== null) {
						if ((alarms & masterAlarmEnums[deviceTypes.GAMECHANGE_GEN4].SNOW_STOW_DISABLED) !== 0) {
							return "Disabled"
						} else {
							return (
								"Depth: " +
								depth_in.toFixed(2).toString() +
								" (inches) / Baseline: " +
								(baseline_in / 12).toFixed(2).toString() +
								" (ft)"
							)
						}
					} else {
						return "---"
					}
				},
			},
			{
				id: "windSensorType",
				label: "Wind Sensor Type",
				args: ["windSensorType"],
				formatString: (windSensorType) => {
					return WindSensorTypeEnum[windSensorType] || "UNKNOWN"
				},
			},
			{
				id: "wind",
				label: "Wind Reading",
				args: ["windPeak", "windAverage"],
				formatString: (windPeak, windAverage) => {
					if (windPeak !== null) {
						return (
							"Peak: " +
							windPeak.toFixed(1).toString() +
							" (mph) / Average: " +
							windAverage.toFixed(1).toString() +
							" (mph)"
						)
					} else {
						return "---"
					}
				},
			},
			{
				id: "temps",
				label: "Temperature",
				args: ["tempBoard", "tempCharger", "powerReadStatus"],
				formatString: (tempBoard, tempCharger, powerReadSuccess) => {
					if (tempBoard !== null && tempCharger !== null) {
						const temperatureBoard = !isBitSet(powerReadSuccess, masterGen3PowerReadStatus.BOARD_TEMP_READ_ERROR)
							? "Board: " + (tempBoard * (9 / 5) + 32).toFixed(2).toString() + " (F)"
							: "Board: --- (F)"
						const temperatureCharger = !isBitSet(powerReadSuccess, masterGen3PowerReadStatus.CHARGER_TEMP_READ_ERROR)
							? "Charger: " + (tempCharger * (9 / 5) + 32).toFixed(2).toString() + " (F)"
							: "Charger: --- (F)"
						return temperatureBoard + " / " + temperatureCharger
					} else {
						return "---"
					}
				},
			},
			{
				id: "boot",
				label: "Last Boot",
				args: ["bootTime", "resetType"],
				formatString: (bootTime, bootType) => {
					if (bootTime !== null) {
						return (
							"Time: " +
							formatTimestamp(bootTime, tz) +
							" / Type: " +
							bootTypeDescriptions[deviceTypes.GAMECHANGE_GEN4][bootType]
						)
					} else {
						return "---"
					}
				},
			},
			{
				id: "fwMaster",
				label: "Master Firmware Version",
				args: ["fwMaster"],
				formatString: (fwMaster) => {
					return fwMaster ? `v${fwMaster}` : "Not specified"
				},
			},
			{
				id: "fwNode",
				label: "Node Firmware Version",
				args: ["fwNode"],
				formatString: (fwNode) => {
					if (fwNode) {
						return `v${fwNode}`
					} else {
						return "---"
					}
				},
			},
		],
	}
	return MasterDataFormats[deviceType]
}
