import { deviceTypes } from "./deviceTypes"
import { privileges } from "./auth"
import semver from "semver"

export const alarmTypeProperties = {
	WD_RESET: {
		description: "WD Reset",
		privilege: privileges.GAMECHANGE,
	},
	NO_TIME_SINCE_RESET: {
		description: "No Time Since Reset",
		privilege: privileges.GAMECHANGE,
	},
	NO_COMM_WITH_MASTER: {
		description: "No Communications",
		privilege: privileges.VIEW,
	},
	MOTOR_OVERCURRENT_HW: {
		description: "Motor Overcurrent (HW)",
		privilege: privileges.VIEW,
	},
	MOTOR_OVERCURRENT_SW: {
		description: "Motor Overcurrent (SW)",
		privilege: privileges.VIEW,
	},
	AXIS_BLOCKED: {
		description: "Axis Blocked",
		privilege: privileges.VIEW,
	},
	LOW_SPEED: {
		description: "Motor Low Speed",
		privilege: privileges.VIEW,
	},
	MOTOR_OVERCURRENT: {
		description: "Motor Overcurrent",
		privilege: privileges.VIEW,
	},
	BATTERY_LIMITED: {
		description: "Battery Limited",
		privilege: privileges.VIEW,
	},
	BATTERY_NOT_ENOUGH: {
		description: "Battery Not Enough",
		privilege: privileges.VIEW,
	},
	BATTERY_LOW_STOW: {
		description: "Battery Low",
		privilege: privileges.VIEW,
	},
	BATTERY_CRITICAL_STOW: {
		description: "Battery Critical",
		privilege: privileges.VIEW,
	},
	INVALID_TRACKING_PARAM: {
		description: "Invalid Parameters",
		privilege: privileges.GAMECHANGE,
	},
	READ_FLASH_ERROR: {
		description: "Memory Read Error",
		privilege: privileges.GAMECHANGE,
	},
	WRITE_FLASH_ERROR: {
		description: "Memory Write Error",
		privilege: privileges.GAMECHANGE,
	},
	TRACKING_DISABLED: {
		description: "Tracking Disabled",
		privilege: privileges.VIEW,
	},
	ESTOP_PUSHED: {
		description: "E-Stop Pushed",
		privilege: privileges.VIEW,
	},
	NO_MOTION: {
		description: "No Motion",
		privilege: privileges.VIEW,
	},
	WRONG_DIRECTION: {
		description: "Wrong Direction",
		privilege: privileges.VIEW,
	},
	BATTERY_OVERCHARGING: {
		description: "Battery Overcharge Shutdown",
		privilege: privileges.VIEW,
	},
	OTA_CRC_CHECK_FAILED: {
		description: "OTA CRC Check Failed",
		privilege: privileges.GAMECHANGE,
	},
	NODE_ASSIGNED_INIT_VALUES: {
		description: "No Data Available (Node)",
		privilege: privileges.GAMECHANGE,
	},
	NOT_CHARGING: {
		description: "Not Charging",
		privilege: privileges.VIEW,
	},
	ANGLE_RCV_OUT_OF_RANGE: {
		description: "Angle Received Out of Range",
		privilege: privileges.DEV,
	},
	OTA_FLASH_MEMORY_ERROR: {
		description: "OTA Flash Memory Error",
		privilege: privileges.GAMECHANGE,
	},
	OTA_IMG_PARAM_SAVE_ERROR: {
		description: "OTA IMG Param Save",
		privilege: privileges.GAMECHANGE,
	},
	BAD_CHARGING_PANEL: {
		description: "Bad Charging Panel",
		privilege: privileges.GAMECHANGE,
	},
	TABLE_OUTSIDE_TRACKING_LIMITS: {
		description: "Table Outside Tracking Limits",
		privilege: privileges.GAMECHANGE,
	},
	MASTER_ASSIGNED_INIT_VALUES: {
		description: "No Data Available (Master)",
		privilege: privileges.CONTROL_CX,
	},
	OTA_RETRIES_REACHED: {
		description: "OTA Retries Reached",
		privilege: privileges.GAMECHANGE,
	},
	LOW_TEMP_STOW: {
		description: "Low Temperature Stow",
		privilege: privileges.VIEW,
	},
	ANGLE_DEVIATION_FROM_MASTER: {
		description: "Angle Deviation",
		privilege: privileges.DEV,
	},
	ALARM_CHARGER_FIRMWARE_BROKEN: {
		description: "Alarm Charger Firmware Broken",
		privilege: privileges.VIEW,
	},
	ALARM_CHARGER_COMM_ERROR: {
		description: "Alarm Charger Communication Error",
		privilege: privileges.VIEW,
	},
	DUPLICATE_NODEID: {
		description: "Duplicate Node ID",
		privilege: privileges.VIEW,
	},
	OLD_DATA: {
		description: "Old Data",
		privilege: privileges.VIEW,
	},
	BATTERY_LOW_DURING_MOTION: {
		description: "Battery Low During Motion",
		privilege: privileges.VIEW,
	},
	NO_DATA_IN_DATABASE: {
		description: "No Data Available in Server",
		privilege: privileges.VIEW,
	},
	UNKNOWN_ACCELEROMETER_TYPE: {
		description: "Unknown Accelerometer Type",
		privilege: privileges.GAMECHANGE,
	},
	BATTERY_MAINTENANCE: {
		description: "Battery Maintenance",
		privilege: privileges.VIEW,
	},
	ALARM_INCLINOMETER_DISCONNECTED: {
		description: "Inclinometer Disconnected",
		privilege: privileges.GAMECHANGE,
	},
}

// NOTE: These are Node alarms, not Master alarms. For Master alarms, see gameChangeMasterAlarmEnums.
const gameChangeAlarmEnums = {
	NONE: 0n,
	WD_RESET: 1n,
	NO_TIME_SINCE_RESET: 1n << 1n,
	NO_COMM_WITH_MASTER: 1n << 2n,
	MOTOR_OVERCURRENT: 1n << 3n,
	BATTERY_LOW_STOW: 1n << 4n,
	BATTERY_CRITICAL_STOW: 1n << 5n,
	INVALID_TRACKING_PARAM: 1n << 6n,
	READ_FLASH_ERROR: 1n << 7n,
	WRITE_FLASH_ERROR: 1n << 8n,
	TRACKING_DISABLED: 1n << 9n,
	ESTOP_PUSHED: 1n << 10n,
	NO_MOTION: 1n << 11n,
	WRONG_DIRECTION: 1n << 12n,
	BATTERY_OVERCHARGING: 1n << 13n,
	OTA_CRC_CHECK_FAILED: 1n << 14n,
	NODE_ASSIGNED_INIT_VALUES: 1n << 15n,
	NOT_CHARGING: 1n << 16n,
	ANGLE_RCV_OUT_OF_RANGE: 1n << 17n,
	OTA_FLASH_MEMORY_ERROR: 1n << 18n,
	OTA_IMG_PARAM_SAVE_ERROR: 1n << 19n,
	BAD_CHARGING_PANEL: 1n << 20n,
	TABLE_OUTSIDE_TRACKING_LIMITS: 1n << 21n,
	MASTER_ASSIGNED_INIT_VALUES: 1n << 22n,
	OTA_RETRIES_REACHED: 1n << 23n,
	LOW_TEMP_STOW: 1n << 24n,
	ANGLE_DEVIATION_FROM_MASTER: 1n << 25n,
	BATTERY_LOW_DURING_MOTION: 1n << 26n,
	UNKNOWN_ACCELEROMETER_TYPE: 1n << 27n,
	BATTERY_MAINTENANCE: 1n << 28n,
	ALARM_INCLINOMETER_DISCONNECTED: 1n << 29n,
}

export const allNodeAlarmTypes = Object.keys(gameChangeAlarmEnums)

export const alarmEnums = {
	[deviceTypes.P4Q]: {
		ESTOP_PUSHED: 1n << 4n,
		NO_COMM_WITH_MASTER: 1n << 7n,
		BATTERY_LIMITED: 1n << 11n,
		BATTERY_NOT_ENOUGH: 1n << 12n,
		BATTERY_LOW_STOW: 1n << 13n,
		BATTERY_CRITICAL_STOW: 1n << 14n,
		NO_TIME_SINCE_RESET: 1n << 18n,
		MOTOR_OVERCURRENT_HW: 1n << 20n,
		MOTOR_OVERCURRENT_SW: 1n << 21n,
		AXIS_BLOCKED: 1n << 24n,
		LOW_SPEED: 1n << 30n,
		OLD_DATA: 1n << 31n,
	},
	[deviceTypes.GAMECHANGE_GEN2]: gameChangeAlarmEnums,
	[deviceTypes.GAMECHANGE_GEN3]: gameChangeAlarmEnums,
	[deviceTypes.GAMECHANGE_GEN4]: {
		NONE: 0n,
		WD_RESET: 1n,
		NO_TIME_SINCE_RESET: 1n << 1n,
		NO_COMM_WITH_MASTER: 1n << 2n,
		MOTOR_OVERCURRENT: 1n << 3n,
		BATTERY_LOW_STOW: 1n << 4n,
		BATTERY_CRITICAL_STOW: 1n << 5n,
		INVALID_TRACKING_PARAM: 1n << 6n,
		READ_FLASH_ERROR: 1n << 7n,
		WRITE_FLASH_ERROR: 1n << 8n,
		TRACKING_DISABLED: 1n << 9n,
		ESTOP_PUSHED: 1n << 10n,
		NO_MOTION: 1n << 11n,
		WRONG_DIRECTION: 1n << 12n,
		BATTERY_OVERCHARGING: 1n << 13n,
		OTA_CRC_CHECK_FAILED: 1n << 14n,
		NODE_ASSIGNED_INIT_VALUES: 1n << 15n,
		NOT_CHARGING: 1n << 16n,
		ANGLE_RCV_OUT_OF_RANGE: 1n << 17n,
		OTA_FLASH_MEMORY_ERROR: 1n << 18n,
		OTA_IMG_PARAM_SAVE_ERROR: 1n << 19n,
		BAD_CHARGING_PANEL: 1n << 20n,
		TABLE_OUTSIDE_TRACKING_LIMITS: 1n << 21n,
		MASTER_ASSIGNED_INIT_VALUES: 1n << 22n,
		OTA_RETRIES_REACHED: 1n << 23n,
		LOW_TEMP_STOW: 1n << 24n,
		ANGLE_DEVIATION_FROM_MASTER: 1n << 25n,
		ALARM_CHARGER_FIRMWARE_BROKEN: 1n << 26n,
		ALARM_CHARGER_COMM_ERROR: 1n << 27n,
	},
	[deviceTypes.GAMECHANGE_GEN3_DUAL_PRIMARY]: gameChangeAlarmEnums,
	[deviceTypes.GAMECHANGE_GEN3_DUAL_AUX]: {
		WD_RESET: 1n << 0n,
		NO_COMM_WITH_MASTER: 1n << 1n,
		INVALID_TRACKING_PARAM: 1n << 2n,
		READ_FLASH_ERROR: 1n << 3n,
		WRITE_FLASH_ERROR: 1n << 4n,
		MOTOR_OVERCURRENT: 1n << 5n,
		NO_MOTION: 1n << 6n,
		WRONG_DIRECTION: 1n << 7n,
		NODE_ASSIGNED_INIT_VALUES: 1n << 8n,
		OTA_CRC_CHECK_FAILED: 1n << 9n,
		OTA_FLASH_MEMORY_ERROR: 1n << 10n,
		OTA_IMG_PARAM_SAVE_ERROR: 1n << 11n,
		BAD_CHARGING_PANEL: 1n << 12n,
		TABLE_OUTSIDE_TRACKING_LIMITS: 1n << 13n,
	},
}

// The maximum safe number a JavaScript Number can hold has 52 bits.
const _javaScriptNumberSize = 52n
export const NO_DATA_IN_DATABASE = 1n << (_javaScriptNumberSize - 1n)
export const DUPLICATE_NODEID = 1n << (_javaScriptNumberSize - 2n)
export const OLD_DATA = 1n << (_javaScriptNumberSize - 3n)

export const getDeviceTypeFilter = (genericFilter, deviceType) => {
	// TODO: Doesn't this just reconstruct genericFilter?
	if (!genericFilter) return genericFilter
	if (typeof genericFilter !== "bigint") {
		throw new Error("Invalid genericFilter type (expected BigInt)")
	}
	let mask = 0n
	for (let i = 0; i < allNodeAlarmTypes.length; i++) {
		const bit = 1n << BigInt(i)
		if ((genericFilter & bit) !== 0n) {
			mask |= alarmEnums[deviceType][allNodeAlarmTypes[i]]
		}
	}
	return mask
}

const gameChangeFlagEnums = {
	FAILED_EAST_LIMIT: 1n << 0n,
	CHARGER_TEMPERATURE_SHUTDOWN: 1n << 1n,
	HIGH_PRIORITY_STOW: 1n << 2n,
	LOW_PRIORITY_STOW: 1n << 3n,
	MISSED_CHECK_IN: 1n << 4n,
	BOD_RESET: 1n << 5n,
	ESTOP_POLL_DISABLED_TRACKING: 1n << 6n,
	ESTOP_IRQ_DISABLED_TRACKING: 1n << 7n,
	FAILED_WEST_LIMIT: 1n << 8n,
	ZIGBEE_REBROADCAST_DISABLED: 1n << 9n,
	BATTERY_CHARGE_CONDITIONING: 1n << 10n,
	DATA_REFRESHING: 1n << 11n,
	BATT_SOC_STOW_ENABLED: 1n << 12n,
	CHARGER_DISABLE_MOVE: 1n << 13n,
	WATCHDOG_RESET: 1n << 14n,
	INVALID_TRACKING_PARAM: 1n << 15n,
	OTA_CRC_CHECK_FAILED: 1n << 16n,
	OTA_FLASH_MEMORY_ERROR: 1n << 17n,
	OTA_IMG_BLOCKS_OVERFLOW: 1n << 18n,
	OTA_UNLOCK_FLASH_ERROR: 1n << 19n,
	CLEANING_MODE: 1n << 20n,
	TEMPERATURE_CALIBRATED: 1n << 21n,
	POWERBOOST_CALIBRATION_RUNNING: 1n << 22n,
	POWERBOOST_TARGET_ANGLE: 1n << 23n,
	POWERBOOST_DISABLE_CHARGER: 1n << 24n,
	FAILED_TO_MOVE_EAST: 1n << 25n,
	FAILED_TO_MOVE_WEST: 1n << 26n,
	OTA_RETRIES_REACHED: 1n << 27n, //Flag to indicate the node have failed too many OTA attempts
	NO_ANGLE_RCV_SINCE_RESET: 1n << 28n,
	LOW_TEMP_STOW: 1n << 29n, //This flag will be set when the system temperature is below the -30C threshold for the battery/
	READ_FLASH_ERROR: 1n << 30n,
	WRITE_FLASH_ERROR: 1n << 31n,
	ANGLE_RCV_OUT_OF_RANGE: 1n << 32n,
	BATT_LOW_DURING_MOTION: 1n << 33n,
	LOW_TEMP_FLAT: 1n << 34n,
	PANEL_OVER_VOLTAGE: 1n << 35n,
	EXTERNAL_INCLINOMETER: 1n << 36n,
	POWERBOOST_HEIGHT_OUT_OF_RANGE: 1n << 37n,
	WEATHERSMART_MODE: 1n << 38n,
	SECURITY_KEY_SWITCHED: 1n << 39n,
	BATTERY_OVER_VOLT_CHARGER_DISABLED: 1n << 40n,
	OTA_SECURE_BOOT_AUTHENTICATION_FAILED: 1n << 52n,
	SMART_CHARGER_OTA_UPDATE_FAILED: 1n << 53n,
	NO_COMMUNICATION_WITH_SMART_CHARGER: 1n << 54n,
	LCD_POSSIBLE_DEFECTED: 1n << 55n,
	KEY_PAD_STOW_MODE: 1n << 56n,
}

export const flagEnums = {
	[deviceTypes.P4Q]: {
		LIMIT_REACHED_SW_TOP: 1n << 0n,
		LIMIT_REACHED_SW_BOTTOM: 1n << 1n,
		MOVEMENT_DISABLED: 1n << 11n,
		WORKING_PROPERLY: 1n << 15n,
	},
	[deviceTypes.GAMECHANGE_GEN2]: gameChangeFlagEnums,
	[deviceTypes.GAMECHANGE_GEN3]: gameChangeFlagEnums,
	[deviceTypes.GAMECHANGE_GEN4]: gameChangeFlagEnums,
	[deviceTypes.GAMECHANGE_GEN3_DUAL]: gameChangeFlagEnums,
}

const gameChangeAlarmModes = [
	{
		bit: gameChangeAlarmEnums.NONE,
		description: "None",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeAlarmEnums.WD_RESET,
		description: "WD Reset",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeAlarmEnums.NO_TIME_SINCE_RESET,
		description: "No Time Since Reset",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeAlarmEnums.NO_COMM_WITH_MASTER,
		description: "No Communications",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeAlarmEnums.MOTOR_OVERCURRENT,
		description: "Over Current",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeAlarmEnums.BATTERY_LOW_STOW,
		description: "Battery Low",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeAlarmEnums.BATTERY_CRITICAL_STOW,
		description: "Battery Critical",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeAlarmEnums.INVALID_TRACKING_PARAM,
		description: "Invalid Parameters",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeAlarmEnums.READ_FLASH_ERROR,
		description: "Memory Read Error",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeAlarmEnums.WRITE_FLASH_ERROR,
		description: "Memory Write Error",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeAlarmEnums.TRACKING_DISABLED,
		description: "Tracking Disabled",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeAlarmEnums.ESTOP_PUSHED,
		description: "E-Stop Pushed",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeAlarmEnums.NO_MOTION,
		description: "No Motion",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeAlarmEnums.WRONG_DIRECTION,
		description: "Wrong Direction",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeAlarmEnums.BATTERY_OVERCHARGING,
		description: "Battery Overcharge Shutdown",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeAlarmEnums.OTA_CRC_CHECK_FAILED,
		description: "OTA CRC Check Failed",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeAlarmEnums.NODE_ASSIGNED_INIT_VALUES,
		description: "No Data Available (Node)",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeAlarmEnums.NOT_CHARGING,
		description: "Not Charging",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeAlarmEnums.ANGLE_RCV_OUT_OF_RANGE,
		description: "Angle Received Out of Range",
		privilege: privileges.DEV,
	},
	{
		bit: gameChangeAlarmEnums.OTA_FLASH_MEMORY_ERROR,
		description: "OTA Flash Memory Error",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeAlarmEnums.OTA_IMG_PARAM_SAVE_ERROR,
		description: "OTA IMG Param Save",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeAlarmEnums.BAD_CHARGING_PANEL,
		description: "Bad Charging Panel",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeAlarmEnums.TABLE_OUTSIDE_TRACKING_LIMITS,
		description: "Table Outside Tracking Limits",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeAlarmEnums.MASTER_ASSIGNED_INIT_VALUES,
		description: "No Data Available (Master)",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeAlarmEnums.OTA_RETRIES_REACHED,
		description: "OTA Retries Reached",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeAlarmEnums.LOW_TEMP_STOW,
		description: "Low Temperature Stow",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeAlarmEnums.ANGLE_DEVIATION_FROM_MASTER,
		description: "Angle Deviation From Master",
		privilege: privileges.DEV,
	},
	{
		bit: gameChangeAlarmEnums.BATTERY_LOW_DURING_MOTION,
		description: "Battery Low During Motion",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeAlarmEnums.UNKNOWN_ACCELEROMETER_TYPE,
		description: "Unknown Accelerometer Type",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeAlarmEnums.BATTERY_MAINTENANCE,
		description: "Battery Maintenance",
		privilege: privileges.GAMECHANGE,
	},
	// Synthetic alarms (not reported by firmware, but used as a default)
	{
		bit: NO_DATA_IN_DATABASE,
		description: "No Data Available in Server",
		privilege: privileges.VIEW,
	},
	{
		bit: DUPLICATE_NODEID,
		description: "Duplicate Node ID",
		privilege: privileges.VIEW,
	},
	{
		bit: OLD_DATA,
		description: "Old Data",
		privilege: privileges.VIEW,
	},
]

const alarmModes = {
	[deviceTypes.P4Q]: [
		{
			bit: alarmEnums[deviceTypes.P4Q].ESTOP_PUSHED,
			description: "E-Stop Pushed",
			privilege: privileges.VIEW,
		},
		{
			bit: alarmEnums[deviceTypes.P4Q].NO_COMM_WITH_MASTER,
			description: "No Communications",
			privilege: privileges.VIEW,
		},
		{
			bit: alarmEnums[deviceTypes.P4Q].BATTERY_LIMITED,
			description: "Battery Limited",
			privilege: privileges.VIEW,
		},
		{
			bit: alarmEnums[deviceTypes.P4Q].BATTERY_NOT_ENOUGH,
			description: "Battery Not Enough",
			privilege: privileges.VIEW,
		},
		{
			bit: alarmEnums[deviceTypes.P4Q].BATTERY_LOW_STOW,
			description: "Battery Low",
			privilege: privileges.VIEW,
		},
		{
			bit: alarmEnums[deviceTypes.P4Q].BATTERY_CRITICAL_STOW,
			description: "Battery Critical",
			privilege: privileges.VIEW,
		},
		{
			bit: alarmEnums[deviceTypes.P4Q].NO_TIME_SINCE_RESET,
			description: "No Time Since Reset",
			privilege: privileges.VIEW,
		},
		{
			bit: alarmEnums[deviceTypes.P4Q].MOTOR_OVERCURRENT_HW,
			description: "Over Current - Hardware",
			privilege: privileges.VIEW,
		},
		{
			bit: alarmEnums[deviceTypes.P4Q].MOTOR_OVERCURRENT_SW,
			description: "Over Current - Software",
			privilege: privileges.VIEW,
		},
		{
			bit: alarmEnums[deviceTypes.P4Q].AXIS_BLOCKED,
			description: "Axis Blocked",
			privilege: privileges.VIEW,
		},
		{
			bit: alarmEnums[deviceTypes.P4Q].LOW_SPEED,
			description: "Low Speed",
			privilege: privileges.VIEW,
		},
		{
			// Synthetic alarm (not reported by firmware, but used as a default)
			OLD_DATA,
			description: "Old Data",
			privilege: privileges.VIEW,
		},
	],
	[deviceTypes.GAMECHANGE_GEN2]: gameChangeAlarmModes,
	[deviceTypes.GAMECHANGE_GEN3]: gameChangeAlarmModes,
	[deviceTypes.GAMECHANGE_GEN4]: [
		{
			bit: gameChangeAlarmEnums.NONE,
			description: "None",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.WD_RESET,
			description: "WD Reset",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.NO_TIME_SINCE_RESET,
			description: "No Time Since Reset",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.NO_COMM_WITH_MASTER,
			description: "No Communications",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.MOTOR_OVERCURRENT,
			description: "Over Current",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.BATTERY_LOW_STOW,
			description: "Battery Low",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.BATTERY_CRITICAL_STOW,
			description: "Battery Critical",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.INVALID_TRACKING_PARAM,
			description: "Invalid Parameters",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.READ_FLASH_ERROR,
			description: "Memory Read Error",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.WRITE_FLASH_ERROR,
			description: "Memory Write Error",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.TRACKING_DISABLED,
			description: "Tracking Disabled",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.ESTOP_PUSHED,
			description: "E-Stop Pushed",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.NO_MOTION,
			description: "No Motion",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.WRONG_DIRECTION,
			description: "Wrong Direction",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.BATTERY_OVERCHARGING,
			description: "Battery Overcharge Shutdown",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.OTA_CRC_CHECK_FAILED,
			description: "OTA CRC Check Failed",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.NODE_ASSIGNED_INIT_VALUES,
			description: "No Data Available (Node)",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.NOT_CHARGING,
			description: "Not Charging",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.ANGLE_RCV_OUT_OF_RANGE,
			description: "Angle Received Out of Range",
			privilege: privileges.DEV,
		},
		{
			bit: gameChangeAlarmEnums.OTA_FLASH_MEMORY_ERROR,
			description: "OTA Flash Memory Error",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.OTA_IMG_PARAM_SAVE_ERROR,
			description: "OTA IMG Param Save",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.BAD_CHARGING_PANEL,
			description: "Bad Charging Panel",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.TABLE_OUTSIDE_TRACKING_LIMITS,
			description: "Table Outside Tracking Limits",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.MASTER_ASSIGNED_INIT_VALUES,
			description: "No Data Available (Master)",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.OTA_RETRIES_REACHED,
			description: "OTA Retries Reached",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.LOW_TEMP_STOW,
			description: "Low Temperature Stow",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.ANGLE_DEVIATION_FROM_MASTER,
			description: "Angle Deviation From Master",
			privilege: privileges.DEV,
		},
		{
			bit: gameChangeAlarmEnums.ALARM_CHARGER_FIRMWARE_BROKEN,
			description: "Alarm Charger Firmware Broken",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.ALARM_CHARGER_COMM_ERROR,
			description: "Alarm Charger Communication Error",
			privilege: privileges.VIEW,
		},
		// Synthetic alarms (not reported by firmware, but used as a default)
		{
			bit: NO_DATA_IN_DATABASE,
			description: "No Data Available in Server",
			privilege: privileges.VIEW,
		},
		{
			bit: DUPLICATE_NODEID,
			description: "Duplicate Node ID",
			privilege: privileges.VIEW,
		},
		{
			bit: OLD_DATA,
			description: "Old Data",
			privilege: privileges.VIEW,
		},
	],
	[deviceTypes.GAMECHANGE_GEN3_DUAL_PRIMARY]: gameChangeAlarmModes,
	[deviceTypes.GAMECHANGE_GEN3_DUAL_AUX]: [
		{
			bit: gameChangeAlarmEnums.NONE,
			description: "None",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.WD_RESET,
			description: "WD Reset",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.NO_COMM_WITH_MASTER,
			description: "No Communications",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.MOTOR_OVERCURRENT,
			description: "Over Current",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.BATTERY_LOW_STOW,
			description: "Battery Low",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.BATTERY_CRITICAL_STOW,
			description: "Battery Critical",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.INVALID_TRACKING_PARAM,
			description: "Invalid Parameters",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.READ_FLASH_ERROR,
			description: "Memory Read Error",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.WRITE_FLASH_ERROR,
			description: "Memory Write Error",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.TRACKING_DISABLED,
			description: "Tracking Disabled",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.ESTOP_PUSHED,
			description: "E-Stop Pushed",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.NO_MOTION,
			description: "No Motion",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.WRONG_DIRECTION,
			description: "Wrong Direction",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.BATTERY_OVERCHARGING,
			description: "Battery Overcharge Shutdown",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.OTA_CRC_CHECK_FAILED,
			description: "OTA CRC Check Failed",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.NODE_ASSIGNED_INIT_VALUES,
			description: "No Data Available (Node)",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.NOT_CHARGING,
			description: "Not Charging",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.ANGLE_RCV_OUT_OF_RANGE,
			description: "Angle Received Out of Range",
			privilege: privileges.DEV,
		},
		{
			bit: gameChangeAlarmEnums.OTA_FLASH_MEMORY_ERROR,
			description: "OTA Flash Memory Error",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.OTA_IMG_PARAM_SAVE_ERROR,
			description: "OTA IMG Param Save",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.BAD_CHARGING_PANEL,
			description: "Bad Charging Panel",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.TABLE_OUTSIDE_TRACKING_LIMITS,
			description: "Table Outside Tracking Limits",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.MASTER_ASSIGNED_INIT_VALUES,
			description: "No Data Available (Master)",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.OTA_RETRIES_REACHED,
			description: "OTA Retries Reached",
			privilege: privileges.GAMECHANGE,
		},
		{
			bit: gameChangeAlarmEnums.LOW_TEMP_STOW,
			description: "Low Temperature Stow",
			privilege: privileges.VIEW,
		},
		{
			bit: gameChangeAlarmEnums.ANGLE_DEVIATION_FROM_MASTER,
			description: "Angle Deviation From Master",
			privilege: privileges.DEV,
		},
		// Synthetic alarms (not reported by firmware, but used as a default)
		{
			bit: NO_DATA_IN_DATABASE,
			description: "No Data Available in Server",
			privilege: privileges.VIEW,
		},
		{
			bit: DUPLICATE_NODEID,
			description: "Duplicate Node ID",
			privilege: privileges.VIEW,
		},
		{
			bit: OLD_DATA,
			description: "Old Data",
			privilege: privileges.VIEW,
		},
	],
}

export const getAlarmBitmask = (userPrivileges, deviceType) => {
	let alarmBitmask = 0n
	if (!alarmModes[deviceType]) return alarmBitmask
	alarmModes[deviceType].forEach((alarmMode) => {
		const bit = alarmMode.bit ?? alarmMode.OLD_DATA
		if ((userPrivileges & alarmMode.privilege) !== 0 && bit) {
			alarmBitmask = BigInt(BigInt(alarmBitmask) | BigInt(bit))
		}
	})
	return alarmBitmask
}

export const getAlarmModesList = (userPrivileges, deviceType) => {
	let alarmModesList = []
	if (!alarmModes[deviceType]) return []
	alarmModes[deviceType].forEach((alarmMode) => {
		if ((userPrivileges & alarmMode.privilege) !== 0) {
			alarmModesList.push(alarmMode)
		}
	})
	return alarmModesList
}

const getAlarmsArray = (userPrivileges, deviceType, alarmBits) => {
	if (typeof alarmBits !== "bigint") {
		throw new Error("alarmBits must be a BigInt")
	}
	const activeAlarms = []
	alarmModes[deviceType].forEach((alarmMode) => {
		if ((userPrivileges & alarmMode.privilege) !== 0 && (alarmBits & alarmMode.bit) !== 0n) {
			activeAlarms.push(alarmMode.description)
		}
	})
	return activeAlarms
}

export const getAlarmsString = (userPrivileges, deviceType) => (alarmBits) => {
	alarmBits = alarmBits ? BigInt(`0x${alarmBits}`) : 0n
	const activeAlarms = getAlarmsArray(userPrivileges, deviceType, alarmBits)
	if (activeAlarms.length !== 0) {
		return activeAlarms.join(", ")
	} else {
		return "None"
	}
}

const getTemperatureCalibratedDescription = (nodeFirmwareVersion) => {
	// NOTE: After 3.19.0, this flag means "temperature has not been calibrated", not "temperature HAS been calibrated"
	if (!nodeFirmwareVersion) {
		return "Temperature Calibration Status Unknown"
	}
	if (semver.gte(nodeFirmwareVersion, "3.19.0")) {
		return "Temperature Not Calibrated"
	}
	return "Temperature Calibrated"
}

const gameChangeFlagModes = [
	{
		bit: gameChangeFlagEnums.TEMPERATURE_CALIBRATED,
		description: getTemperatureCalibratedDescription,
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeFlagEnums.POWERBOOST_CALIBRATION_RUNNING,
		description: "PowerBoost Calibration Running",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.POWERBOOST_TARGET_ANGLE,
		description: "PowerBoost Target Angle",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeFlagEnums.POWERBOOST_DISABLE_CHARGER,
		description: "PowerBoost Disable Charger",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.FAILED_EAST_LIMIT,
		description: "Failed to Reach Limit (East)",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.FAILED_WEST_LIMIT,
		description: "Failed to Reach Limit (West)",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.FAILED_TO_MOVE_EAST,
		description: "Failed to Move (East)",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeFlagEnums.CHARGER_TEMPERATURE_SHUTDOWN,
		description: "Charger Temp Shutdown",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.HIGH_PRIORITY_STOW,
		description: "Stow - High Priority",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeFlagEnums.LOW_PRIORITY_STOW,
		description: "Stow - Low Priority",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeFlagEnums.MISSED_CHECK_IN,
		description: "Missed Status Check-in",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.BOD_RESET,
		description: "BOD Reset",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.ESTOP_POLL_DISABLED_TRACKING,
		description: "E-stop - Poll Disabled",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.ESTOP_IRQ_DISABLED_TRACKING,
		description: "E-stop - IRQ Disabled",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.FAILED_TO_MOVE_WEST,
		description: "Failed to Move (West)",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeFlagEnums.ZIGBEE_REBROADCAST_DISABLED,
		description: "Zigbee Rebroadcast Disabled",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.BATTERY_CHARGE_CONDITIONING,
		description: "Battery Charge Conditioning",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.DATA_REFRESHING,
		description: "Data Refreshing",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeFlagEnums.BATT_SOC_STOW_ENABLED,
		description: "Battery SOC Stow Enabled",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.CHARGER_DISABLE_MOVE,
		description: "Battery Charger Disable Move",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.WATCHDOG_RESET,
		description: "Watchdog Reset",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.INVALID_TRACKING_PARAM,
		description: "Invalid Parameters",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.OTA_CRC_CHECK_FAILED,
		description: "OTA CRC Check Failed",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.OTA_FLASH_MEMORY_ERROR,
		description: "OTA Flash Memory Error",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.OTA_IMG_BLOCKS_OVERFLOW,
		description: "OTA Image Blocks Overflow",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.OTA_UNLOCK_FLASH_ERROR,
		description: "OTA Unlock Flash Error",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.CLEANING_MODE,
		description: "Cleaning Mode",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeFlagEnums.OTA_RETRIES_REACHED,
		description: "OTA Retries Reached",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.NO_ANGLE_RCV_SINCE_RESET,
		description: "No Angle Received Since Reset",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.LOW_TEMP_STOW,
		description: "Low Temperature Stow",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeFlagEnums.READ_FLASH_ERROR,
		description: "Read Flash Error",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.WRITE_FLASH_ERROR,
		description: "Write Flash Error",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.ANGLE_RCV_OUT_OF_RANGE,
		description: "Angle Received Out of Range",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeFlagEnums.BATT_LOW_DURING_MOTION,
		description: "Battery Low During Motion",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.LOW_TEMP_FLAT,
		description: "Low Temperature Flat",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeFlagEnums.PANEL_OVER_VOLTAGE,
		description: "Panel Overvoltage",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.EXTERNAL_INCLINOMETER,
		description: "External Inclinometer",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.POWERBOOST_HEIGHT_OUT_OF_RANGE,
		description: "Powerboost Height Out of Range",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.WEATHERSMART_MODE,
		description: "Weathersmart Mode",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeFlagEnums.SECURITY_KEY_SWITCHED,
		description: "Security Key Switched",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.BATTERY_OVER_VOLT_CHARGER_DISABLED,
		description: "Battery Overvoltage - Charger Disabled",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.KEY_PAD_STOW_MODE,
		description: "Keypad Stow Mode",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeFlagEnums.OTA_SECURE_BOOT_AUTHENTICATION_FAILED,
		description: "OTA Secure Boot Authentication Failed",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.SMART_CHARGER_OTA_UPDATE_FAILED,
		description: "Smart Charger OTA Updated Failed",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.NO_COMMUNICATION_WITH_SMART_CHARGER,
		description: "No Communication With Smart Charger",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeFlagEnums.LCD_POSSIBLE_DEFECTED,
		description: "LCD Possibly Defective",
		privilege: privileges.GAMECHANGE,
	},
]

export const flagModes = {
	[deviceTypes.P4Q]: [
		{
			bit: flagEnums[deviceTypes.P4Q].LIMIT_REACHED_SW_TOP,
			description: "Limit Reached - Top",
			privilege: privileges.VIEW,
		},
		{
			bit: flagEnums[deviceTypes.P4Q].LIMIT_REACHED_SW_BOTTOM,
			description: "Limit Reached - Bottom",
			privilege: privileges.VIEW,
		},
		{
			bit: flagEnums[deviceTypes.P4Q].MOVEMENT_DISABLED,
			description: "Movement Disabled",
			privilege: privileges.VIEW,
		},
		{
			bit: flagEnums[deviceTypes.P4Q].WORKING_PROPERLY,
			description: "System Working Properly",
			privilege: privileges.VIEW,
		},
	],
	[deviceTypes.GAMECHANGE_GEN2]: gameChangeFlagModes,
	[deviceTypes.GAMECHANGE_GEN3]: gameChangeFlagModes,
	[deviceTypes.GAMECHANGE_GEN3_DUAL]: gameChangeFlagModes,
	[deviceTypes.GAMECHANGE_GEN4]: gameChangeFlagModes,
}

export const getFlagBitmask = (userPrivileges, deviceType) => {
	let flagBitmask = 0n
	if (!flagModes[deviceType]) return 0n
	flagModes[deviceType].forEach((flagMode) => {
		if ((userPrivileges & flagMode.privilege) !== 0) {
			flagBitmask = flagBitmask | flagMode.bit
		}
	})
	return flagBitmask
}

export const getFlagModesList = (userPrivileges, deviceType) => {
	let flagModesList = []
	if (!flagModes[deviceType]) return []
	flagModes[deviceType].forEach((flagMode) => {
		if ((userPrivileges & flagMode.privilege) !== 0) {
			flagModesList.push(flagMode)
		}
	})
	return flagModesList
}

export const getFlagsString =
	(userPrivileges, deviceType) =>
	(flagBits, nodeFirmwareVersion = undefined) => {
		if (flagBits === undefined) {
			return "Loading..."
		}
		flagBits = BigInt(`0x${flagBits}`)

		const activeFlags = []

		for (const flagMode of flagModes[deviceType]) {
			if ((userPrivileges & flagMode.privilege) !== 0 && (BigInt(flagBits) & flagMode.bit) !== 0n) {
				let description = flagMode.description
				if (typeof description === "function") description = description(nodeFirmwareVersion)
				activeFlags.push(description)
			}
		}

		if (activeFlags.length !== 0) {
			return activeFlags.join(", ")
		} else {
			return "None"
		}
	}

export const getP4QStatus = (statusBits) => {
	const status = []
	const states = { 0: "OFF", 1: "MANUAL", 2: "AUTO" }
	const mainState = (statusBits >> 8) & 0x3

	status.push("Main State: " + states[mainState])

	const safePosition = (statusBits >> 13) & 0x7
	if (safePosition !== 0) {
		status.push("Safe Postion " + safePosition.toString() + " Active")
	}

	const timesOfDay = { 0: "Nighttime", 1: "Daytime" }
	const timeOfDay = (statusBits >> 7) & 0x1
	status.push("Time of day: " + timesOfDay[timeOfDay])

	const sleepModes = { 0: "Normal", 1: "Endurance", 2: "Zen", 3: "Ultra Sleep" }
	const sleepMode = (statusBits >> 1) & 0x3
	if (sleepMode !== 0) {
		status.push("Sleep Mode: " + sleepModes[sleepMode])
	}

	const backtracking = statusBits & 0x1
	if (backtracking !== 0) {
		status.push("Backtracking")
	}

	if (status.length > 0) {
		return status.join(", ")
	}
	return "---"
}

export const gameChangeMasterAlarmEnums = {
	NONE: 0n,
	WRITE_FLASH_ERROR: 1n << 0n,
	NODE_ID_FLASH_ERROR: 1n << 1n,
	STOW_COORD_NAND_FLASH_ERROR: 1n << 2n,
	PARAM_NAND_FLASH_ERROR: 1n << 3n,
	UNKNOWN_4: 1n << 4n,
	UNKNOWN_5: 1n << 5n,
	UNKNOWN_6: 1n << 6n,
	UNKNOWN_7: 1n << 7n,
	SNOW_SENSOR_CALIBRATION_ERROR: 1n << 8n,
	ESTOP_PUSHED: 1n << 9n,
	UNKNOWN_10: 1n << 10n,
	SNOW_SENSOR_DISCONNECTED: 1n << 11n,
	WIND_SENSOR_DISCONNECTED: 1n << 12n,
	SNOW_STOW_DISABLED: 1n << 13n,
	CRITICAL_BATTERY: 1n << 14n,
	AC_DISCONNECTED: 1n << 15n,
}

export const masterAlarmEnums = {
	[deviceTypes.P4Q]: {},
	[deviceTypes.GAMECHANGE_GEN2]: gameChangeMasterAlarmEnums,
	[deviceTypes.GAMECHANGE_GEN3]: gameChangeMasterAlarmEnums,
	[deviceTypes.GAMECHANGE_GEN4]: gameChangeMasterAlarmEnums,
	[deviceTypes.GAMECHANGE_GEN3_DUAL]: gameChangeMasterAlarmEnums,
}

const gameChangeMasterAlarmModes = [
	{
		bit: gameChangeMasterAlarmEnums.NONE,
		description: "None",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeMasterAlarmEnums.NODE_ID_FLASH_ERROR,
		description: "NodeID Flash Error",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeMasterAlarmEnums.STOW_COORD_NAND_FLASH_ERROR,
		description: "Stow Coordinator Flash Error",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeMasterAlarmEnums.PARAM_NAND_FLASH_ERROR,
		description: "Param Flash Error",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeMasterAlarmEnums.SNOW_SENSOR_CALIBRATION_ERROR,
		description: "Snow Sensor Calibration Error",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeMasterAlarmEnums.WIND_SENSOR_DISCONNECTED,
		description: "Wind Sensor Disconnected",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeMasterAlarmEnums.SNOW_SENSOR_DISCONNECTED,
		description: "Snow Sensor Disconnected",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeMasterAlarmEnums.SNOW_STOW_DISABLED,
		description: "Snow Stow Disabled",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeMasterAlarmEnums.CRITICAL_BATTERY,
		description: "Critical Battery",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeMasterAlarmEnums.AC_DISCONNECTED,
		description: "AC Disconnected",
		privilege: privileges.VIEW,
	},
]

const masterAlarmModes = {
	[deviceTypes.P4Q]: [],
	[deviceTypes.GAMECHANGE_GEN2]: gameChangeMasterAlarmModes,
	[deviceTypes.GAMECHANGE_GEN3]: gameChangeMasterAlarmModes,
	[deviceTypes.GAMECHANGE_GEN4]: gameChangeMasterAlarmModes,
	[deviceTypes.GAMECHANGE_GEN3_DUAL]: gameChangeMasterAlarmModes,
}

const getMasterAlarmsArray = (userPrivileges, deviceType, alarmBits) => {
	let activeAlarms = []
	masterAlarmModes[deviceType].forEach((alarmMode) => {
		if ((userPrivileges & alarmMode.privilege) !== 0 && (alarmBits & BigInt(alarmMode.bit)) !== 0n) {
			activeAlarms.push(alarmMode.description)
		}
	})
	return activeAlarms
}

/**
 * Get the index of the first bit set in a bitmask. For example, converts 0b1000 to 3.
 * @param mask
 * @returns {bigint}
 */
export function getBitIndex(mask) {
	const binaryString = mask.toString(2)
	const reversed = binaryString.split("").reverse().join("")
	return BigInt(reversed.indexOf("1"))
}

export const getMasterAlarmBitmask = (userPrivileges, deviceType) => {
	let masterAlarmBitmask = 0n
	if (!masterAlarmModes[deviceType]) return 0n
	masterAlarmModes[deviceType].forEach((alarmMode) => {
		if ((userPrivileges & alarmMode.privilege) !== 0) {
			masterAlarmBitmask = masterAlarmBitmask | alarmMode.bit
		}
	})
	return masterAlarmBitmask
}

export const getMasterAlarmModesList = (userPrivileges, deviceType) => {
	let masterModesList = []
	if (!masterAlarmModes[deviceType]) return []
	masterAlarmModes[deviceType].forEach((alarmMode) => {
		if ((userPrivileges & alarmMode.privilege) !== 0) {
			masterModesList.push(alarmMode)
		}
	})
	return masterModesList
}

export const getMasterAlarmsString = (userPrivileges, deviceType) => (alarmBits) => {
	alarmBits = BigInt(`0x${alarmBits}`)
	const activeAlarms = getMasterAlarmsArray(userPrivileges, deviceType, alarmBits)
	if (activeAlarms.length !== 0) {
		return activeAlarms.join(", ")
	} else {
		return "None"
	}
}

const gameChangeMasterFlagEnums = {
	NONE: 0n,
	MASTER_WIND_DATA_FROM_SERVER: 1n << 8n,
	MASTER_POWERBOOST_ENABLED: 1n << 9n,
	MASTER_NIGHTLY_OTA_CHECK_ENABLED: 1n << 10n,
	MASTER_NODE_CHARGER_CONTROL_ENABLED: 1n << 11n,
	MASTER_WEATHERSMART: 1n << 12n,
	MASTER_CLEANING_MODE: 1n << 13n,
	MASTER_SNOW_BASELINE_ADJUSTED: 1n << 14n,
	MASTER_SNOW_STOW_DISABLED: 1n << 15n,
}

export const masterFlagEnums = {
	[deviceTypes.P4Q]: {},
	[deviceTypes.GAMECHANGE_GEN2]: gameChangeMasterFlagEnums,
	[deviceTypes.GAMECHANGE_GEN3]: gameChangeMasterFlagEnums,
	[deviceTypes.GAMECHANGE_GEN4]: gameChangeMasterFlagEnums,
	[deviceTypes.GAMECHANGE_GEN3_DUAL]: gameChangeMasterFlagEnums,
}

const gameChangeMasterFlagModes = [
	{
		bit: gameChangeMasterFlagEnums.NONE,
		description: "None",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeMasterFlagEnums.MASTER_WIND_DATA_FROM_SERVER,
		description: "Wind Data From Server",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeMasterFlagEnums.MASTER_POWERBOOST_ENABLED,
		description: "PowerBoost Enabled",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeMasterFlagEnums.MASTER_NIGHTLY_OTA_CHECK_ENABLED,
		description: "Nightly OTA Check Enabled",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeMasterFlagEnums.MASTER_NODE_CHARGER_CONTROL_ENABLED,
		description: "Node Charger Control Enabled",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeMasterFlagEnums.MASTER_WEATHERSMART,
		description: "Weathersmart",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeMasterFlagEnums.MASTER_CLEANING_MODE,
		description: "Cleaning Mode",
		privilege: privileges.GAMECHANGE,
	},
	{
		bit: gameChangeMasterFlagEnums.MASTER_SNOW_BASELINE_ADJUSTED,
		description: "Snow Baseline Adjusted",
		privilege: privileges.VIEW,
	},
	{
		bit: gameChangeMasterFlagEnums.MASTER_SNOW_STOW_DISABLED,
		description: "Snow Stow Disabled",
		privilege: privileges.VIEW,
	},
]

const masterFlagModes = {
	[deviceTypes.P4Q]: [],
	[deviceTypes.GAMECHANGE_GEN2]: gameChangeMasterFlagModes,
	[deviceTypes.GAMECHANGE_GEN3]: gameChangeMasterFlagModes,
	[deviceTypes.GAMECHANGE_GEN4]: gameChangeMasterFlagModes,
	[deviceTypes.GAMECHANGE_GEN3_DUAL]: gameChangeMasterFlagModes,
}

const getMasterFlagsArray = (userPrivileges, deviceType, flagBits) => {
	let activeFlags = []
	masterFlagModes[deviceType].forEach((flagMode) => {
		if ((userPrivileges & flagMode.privilege) !== 0 && (BigInt(flagBits) & flagMode.bit) !== 0n) {
			activeFlags.push(flagMode.description, undefined)
		}
	})
	return activeFlags
}

export const getMasterFlagBitmask = (userPrivileges, deviceType) => {
	let masterFlagBitmask = 0n
	if (!masterFlagModes[deviceType]) return 0n
	masterFlagModes[deviceType].forEach((flagMode) => {
		if ((userPrivileges & flagMode.privilege) !== 0) {
			masterFlagBitmask = masterFlagBitmask | flagMode.bit
		}
	})
	return masterFlagBitmask
}

export const getMasterFlagModesList = (userPrivileges, deviceType) => {
	let masterModesList = []
	if (!masterFlagModes[deviceType]) return []
	masterFlagModes[deviceType].forEach((flagMode) => {
		if ((userPrivileges & flagMode.privilege) !== 0) {
			masterModesList.push(flagMode)
		}
	})
	return masterModesList
}

export const getMasterFlagsString = (userPrivileges, deviceType) => (flagBits) => {
	flagBits = parseInt(flagBits, 16)
	const activeFlags = getMasterFlagsArray(userPrivileges, deviceType, flagBits)
	if (activeFlags.length !== 0) {
		return activeFlags.join(", ")
	} else {
		return "None"
	}
}

const gameChangeMasterBootTypes = {
	GENERAL_RESET: 0,
	BACKUP_RESET: 1,
	WATCHDOG_RESET: 2,
	SOFTWARE_RESET: 3,
	USER_RESET: 4,
}

const gameChangeBootTypeDescriptions = {
	[gameChangeMasterBootTypes.GENERAL_RESET]: "General Reset",
	[gameChangeMasterBootTypes.BACKUP_RESET]: "Backup Reset",
	[gameChangeMasterBootTypes.WATCHDOG_RESET]: "Watchdog Reset",
	[gameChangeMasterBootTypes.SOFTWARE_RESET]: "Software Reset",
	[gameChangeMasterBootTypes.USER_RESET]: "User Reset",
}

export const bootTypeDescriptions = {
	[deviceTypes.P4Q]: {},
	[deviceTypes.GAMECHANGE_GEN2]: gameChangeBootTypeDescriptions,
	[deviceTypes.GAMECHANGE_GEN3]: gameChangeBootTypeDescriptions,
	[deviceTypes.GAMECHANGE_GEN4]: gameChangeBootTypeDescriptions,
	[deviceTypes.GAMECHANGE_GEN3_DUAL]: gameChangeBootTypeDescriptions,
}

const gameChangeTrackingModes = {
	UNKNOWN: 0,
	TRACKING: 1,
	ANTISHADING: 2,
	NIGHT: 3,
	SMART_STOW: 4,
	KEEP_ENABLED: 5,
}

export const trackingModes = {
	[deviceTypes.P4Q]: {},
	[deviceTypes.GAMECHANGE_GEN2]: gameChangeTrackingModes,
	[deviceTypes.GAMECHANGE_GEN3]: gameChangeTrackingModes,
	[deviceTypes.GAMECHANGE_GEN4]: gameChangeTrackingModes,
	[deviceTypes.GAMECHANGE_GEN3_DUAL]: gameChangeTrackingModes,
}

const gameChangeTrackingModeDescriptions = {
	[gameChangeTrackingModes.UNKNOWN]: "Unknown State",
	[gameChangeTrackingModes.TRACKING]: "Tracking",
	[gameChangeTrackingModes.ANTISHADING]: "Anti-shading",
	[gameChangeTrackingModes.NIGHT]: "Night",
	[gameChangeTrackingModes.SMART_STOW]: "Stow",
	[gameChangeTrackingModes.KEEP_ENABLED]: "Manual Stow",
}

export const trackingModeDescriptions = {
	[deviceTypes.P4Q]: {},
	[deviceTypes.GAMECHANGE_GEN2]: gameChangeTrackingModeDescriptions,
	[deviceTypes.GAMECHANGE_GEN3]: gameChangeTrackingModeDescriptions,
	[deviceTypes.GAMECHANGE_GEN4]: gameChangeTrackingModeDescriptions,
	[deviceTypes.GAMECHANGE_GEN3_DUAL]: gameChangeTrackingModeDescriptions,
}

const gameChangeMasterStowModes = {
	NONE: 0n,
	UNKNOWN: 1n << 0n,
	MANUAL_OVERRIDE: 1n << 1n,
	WIND: 1n << 2n,
	HAIL: 1n << 3n,
	SENSOR_DISCONNECT_WIND: 1n << 4n,
	SENSOR_DISCONNECT_SNOW: 1n << 5n,
	MASTER_LOW_BATTERY: 1n << 6n,
	SNOW: 1n << 7n,
	WATER: 1n << 8n,
	THUNDERSMART: 1n << 9n,
	MASTER_HARDWARE_FAULT: 1n << 10n,
	MANUAL_OVERRIDE_LOW_PRIORITY: 1n << 11n,
	CLEANING_MODE: 1n << 12n,
	WEATHERSMART: 1n << 13n,
	STOW_ANGLE_OVERNIGHT_HOLD: 1n << 14n,
}

export const masterStowModes = {
	[deviceTypes.P4Q]: {},
	[deviceTypes.GAMECHANGE_GEN2]: gameChangeMasterStowModes,
	[deviceTypes.GAMECHANGE_GEN3]: gameChangeMasterStowModes,
	[deviceTypes.GAMECHANGE_GEN4]: gameChangeMasterStowModes,
	[deviceTypes.GAMECHANGE_GEN3_DUAL]: gameChangeMasterStowModes,
}

const gameChangeStowModeDescriptions = {
	[gameChangeMasterStowModes.NONE]: "None",
	[gameChangeMasterStowModes.UNKNOWN]: "Unknown",
	[gameChangeMasterStowModes.MANUAL_OVERRIDE]: "Manual Override - High Priority",
	[gameChangeMasterStowModes.WIND]: "Wind",
	[gameChangeMasterStowModes.HAIL]: "Hail",
	[gameChangeMasterStowModes.SENSOR_DISCONNECT_WIND]: "Wind Sensor Disconnect",
	[gameChangeMasterStowModes.SENSOR_DISCONNECT_SNOW]: "Snow Sensor Disconnect",
	[gameChangeMasterStowModes.MASTER_LOW_BATTERY]: "Master Low Battery",
	[gameChangeMasterStowModes.SNOW]: "Snow",
	[gameChangeMasterStowModes.WATER]: "Water",
	[gameChangeMasterStowModes.THUNDERSMART]: "Thundersmart",
	[gameChangeMasterStowModes.MASTER_HARDWARE_FAULT]: "Master Hardware Fault",
	[gameChangeMasterStowModes.MANUAL_OVERRIDE_LOW_PRIORITY]: "Manual Override - Low Priority",
	[gameChangeMasterStowModes.CLEANING_MODE]: "Cleaning Mode",
	[gameChangeMasterStowModes.WEATHERSMART]: "Weathersmart",
	[gameChangeMasterStowModes.STOW_ANGLE_OVERNIGHT_HOLD]: "Overnight Hold",
}

export const stowModeDescriptions = {
	[deviceTypes.P4Q]: {},
	[deviceTypes.GAMECHANGE_GEN2]: gameChangeStowModeDescriptions,
	[deviceTypes.GAMECHANGE_GEN3]: gameChangeStowModeDescriptions,
	[deviceTypes.GAMECHANGE_GEN4]: gameChangeStowModeDescriptions,
	[deviceTypes.GAMECHANGE_GEN3_DUAL]: gameChangeStowModeDescriptions,
}

const getEnabledStowModesArray = (deviceType, enabledStowModes) => {
	const enabledStowModesList = []

	Object.values(masterStowModes[deviceType]).forEach((stowMode) => {
		if ((enabledStowModes & stowMode) !== 0n) {
			enabledStowModesList.push(stowModeDescriptions[deviceType][stowMode])
		}
	})

	return enabledStowModesList
}

export const getEnabledStowModesString = (deviceType) => (enabledStowModes) => {
	const enabledStowModesArray = getEnabledStowModesArray(deviceType, BigInt(`0x${enabledStowModes}`))
	if (enabledStowModesArray.length !== 0) {
		return enabledStowModesArray.join(", ")
	} else {
		return "None"
	}
}

export const getSmartStowModeString = (smartStowMode) => {
	const autoSmartStowMode = BigInt(smartStowMode || 0)
	if (autoSmartStowMode === BigInt("0x7859")) {
		return "Enabled"
	} else {
		return "Disabled"
	}
}

export const WindSensorTypeEnum = {
	0: "InSpeed wind sensor",
	1: "ET-40RS wind sensor",
	2: "ET-3250 wind sensor",
	3: "Barani - MeteoWind Compact sensor",
}

export const masterGen3PowerReadStatus = {
	VOTLAGE_READ_ERROR: 1n << 1n,
	CURRENT_READ_ERROR: 1n << 2n,
	CHARGER_TEMP_READ_ERROR: 1n << 3n,
	BOARD_TEMP_READ_ERROR: 1n << 4n,
}
