/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGraphsData = /* GraphQL */ `
  query GetGraphsData(
    $graphTypes: [String]!
    $startDate: Int!
    $endDate: Int!
    $mLocIds: [ID]!
    $mNames: [String]!
  ) {
    getGraphsData(
      graphTypes: $graphTypes
      startDate: $startDate
      endDate: $endDate
      mLocIds: $mLocIds
      mNames: $mNames
    ) {
      graphTypes
      data
      timestamps
      labelIndexes
      labels
      __typename
    }
  }
`;
export const getLatestNodeDataByMLocId = /* GraphQL */ `
  query GetLatestNodeDataByMLocId($layoutId: ID!, $mLocId: ID!) {
    getLatestNodeDataByMLocId(layoutId: $layoutId, mLocId: $mLocId) {
      layoutId
      mLocId
      mId
      timestamp
      nodeData {
        nLocId
        alarms
        anglePanel
        angleTableIndex
        angleTarget
        batteryVoltage
        batteryVoltageChargerOff
        chargeCurrent
        chargeStatus
        checkinRound
        firstHop
        flags
        hopCount
        motorCurrentAve
        motorCurrentPeak
        nId
        sleepCounter
        tempMax
        tempMin
        timestamp
        solarPanelCurrentDraw
        batteryBidirectionalCurrentDraw
        solarPanelVoltage
        firmwareVersion
        hardwareVersion
        responseTime
        linkQualityIndicator
        receivedSignalStrengthIndicator
        batteryStateOfCharge
        batteryStateOfHealth
        __typename
      }
      __typename
    }
  }
`;
export const getNodeDataByMLocId = /* GraphQL */ `
  query GetNodeDataByMLocId($layoutId: ID!, $mLocId: ID!, $timestamp: Int!) {
    getNodeDataByMLocId(
      layoutId: $layoutId
      mLocId: $mLocId
      timestamp: $timestamp
    ) {
      layoutId
      mLocId
      mId
      timestamp
      nodeData {
        nLocId
        alarms
        anglePanel
        angleTableIndex
        angleTarget
        batteryVoltage
        batteryVoltageChargerOff
        chargeCurrent
        chargeStatus
        checkinRound
        firstHop
        flags
        hopCount
        motorCurrentAve
        motorCurrentPeak
        nId
        sleepCounter
        tempMax
        tempMin
        timestamp
        solarPanelCurrentDraw
        batteryBidirectionalCurrentDraw
        solarPanelVoltage
        firmwareVersion
        hardwareVersion
        responseTime
        linkQualityIndicator
        receivedSignalStrengthIndicator
        batteryStateOfCharge
        batteryStateOfHealth
        __typename
      }
      __typename
    }
  }
`;
export const getLayoutDetails = /* GraphQL */ `
  query GetLayoutDetails($id: ID!) {
    getLayoutDetails(id: $id) {
      address1
      address2
      city
      coordinates {
        latitude
        longitude
        __typename
      }
      deltaAction
      developerName
      id
      installerName
      labelZoomThreshold
      layoutUrl
      mLocIds
      markerRadius
      name
      nameAlternate
      customerName
      oAndMName
      ownerName
      powerCapacityDc
      published
      scadaName
      state
      utcOffset
      zip
      demo
      layoutNumber
      tz
      weathersmartEnabled
      __typename
    }
  }
`;
export const getMasterConfigByMLocId = /* GraphQL */ `
  query GetMasterConfigByMLocId($layoutId: ID!, $mLocId: ID!) {
    getMasterConfigByMLocId(layoutId: $layoutId, mLocId: $mLocId) {
      layoutId
      mId
      mLocId
      __typename
    }
  }
`;
export const getMasterLocation = /* GraphQL */ `
  query GetMasterLocation($id: ID!, $layoutId: ID!) {
    getMasterLocation(id: $id, layoutId: $layoutId) {
      area
      channel
      deltaAction
      deviceType
      id
      layoutId
      mId
      name
      label
      panid
      spaParamId
      nodeParamId
      xLoc
      yLoc
      localIp
      __typename
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($mLocId: ID!, $timestamp: Int!) {
    getTask(mLocId: $mLocId, timestamp: $timestamp) {
      mLocId
      timestamp
      userId
      username
      nId
      issueType
      layoutName
      masterName
      actuatorOn
      movesEastWest
      newNodeId
      commandQueue
      comment
      panelOn
      currentVoltageGood
      batteryGood
      goodPanelId
      badPanelId
      voltage
      current
      overCurrent
      lightOn
      eStopRespond
      eStopRespondReset
      tracking
      rmaNumbers
      rowNum
      fromSouth
      badActuatorModelNumber
      badActuatorProductionDate
      badActuatorVendorInitial
      badActuatorSerialNum
      goodActuatorModelNumber
      goodActuatorProductionDate
      goodActuatorVendorInitial
      goodActuatorSerialNum
      failPosition
      failAngle
      actuatorAbnormalities
      nodeIdMatch
      nodeFuse
      eStopPulled
      goodBatteryId
      badBatteryId
      goodBatteryVendor
      badBatteryVendor
      mechanicalIssues
      fixedWiring
      damageType
      goodChannel
      goodPanId
      badChannel
      badPanId
      targetedBattery
      retrofitComponent
      boardVendor
      bomAlternate
      bareBoardVersion
      generation
      buildDate
      batchNumber
      __typename
    }
  }
`;
export const getNodeLocations = /* GraphQL */ `
  query GetNodeLocations($layoutId: ID!, $mLocId: ID!) {
    getNodeLocations(layoutId: $layoutId, mLocId: $mLocId) {
      mLocId
      layoutId
      nIds {
        index
        id
        __typename
      }
      syncBitmasks
      nLocs {
        index
        xLoc
        yLoc
        rowNum
        fromSouth
        distance
        role
        relative
        __typename
      }
      deltaAction
      __typename
    }
  }
`;
export const getReplacementCampaignInfo = /* GraphQL */ `
  query GetReplacementCampaignInfo($layoutId: ID!, $mLocId: ID!) {
    getReplacementCampaignInfo(layoutId: $layoutId, mLocId: $mLocId) {
      layoutId
      mLocId
      timestamp
      replacements {
        index
        id
        __typename
      }
      __typename
    }
  }
`;
export const getMasterLocationByMasterId = /* GraphQL */ `
  query GetMasterLocationByMasterId(
    $filter: MasterLocationFilterInput
    $limit: Int
    $mId: String!
    $nextToken: String
  ) {
    getMasterLocationByMasterId(
      filter: $filter
      limit: $limit
      mId: $mId
      nextToken: $nextToken
    ) {
      items {
        area
        channel
        deltaAction
        deviceType
        id
        layoutId
        mId
        name
        label
        panid
        spaParamId
        nodeParamId
        xLoc
        yLoc
        localIp
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNodeConfigByNLocId = /* GraphQL */ `
  query GetNodeConfigByNLocId($nLocId: ID!) {
    getNodeConfigByNLocId(nLocId: $nLocId) {
      layoutId
      mLocId
      nLocId
      __typename
    }
  }
`;
export const getSpaParameters = /* GraphQL */ `
  query GetSpaParameters($id: ID!) {
    getSpaParameters(id: $id) {
      id
      label
      mLocIds
      utcOffset
      deltaUt1
      deltaT
      longitude
      latitude
      elevation
      pressure
      aveTemp
      slope
      azmRotation
      atmosRefraction
      rowSpacing
      panelLength
      planeInclinationAngle
      slopeDirection
      mechLimitWest
      mechLimitEast
      overnightAngle
      overnightMoveStartHour
      overnightMoveEndHour
      antishadingEnabled
      strongestAngle
      snowStowAngle
      floodStowAngle
      fastPollingInterval
      nodeTemperatureCalibrationEnabled
      nodeTemperatureCalibrationInterval
      windStowTable
      __typename
    }
  }
`;
export const getNodeParameters = /* GraphQL */ `
  query GetNodeParameters($id: ID!) {
    getNodeParameters(id: $id) {
      id
      label
      newNode
      lowBatteryLimit
      criticalBatteryLimit
      overvoltageThreshold
      highCurrentLimit
      lowBatteryStow
      numberofAngles
      motorOnTime
      motorOffTime
      motorOnTimeStow
      motorOffTimeStow
      angleTolerance
      startTolerance
      startToleranceAntishade
      motionTolerance
      noMotionTime
      directionTolerance
      hardwareCurrentLimit
      otaTimeout
      hotTempHiLimit
      hotTempLowLimit
      coldTempHiLimit
      coldTempLowLimit
      motionFailures
      motorMinSpeed
      rampOnTime
      rampDownPropCons
      overcurrentTries
      overcurrentTime
      arrrStatusIntervalMinute
      directionTime
      waitTimeMotorOff
      waitTimeChargerOff
      limitFailures
      limitTolerance
      directionFailures
      estopDisableTime
      lowBattThresholdDuringMotion
      nodeBattSocStowEnable
      nodeSocBattVoltOffset
      nodeBattSocStowTempHysteresis
      __typename
    }
  }
`;
export const getUserGroups = /* GraphQL */ `
  query GetUserGroups($username: ID!) {
    getUserGroups(username: $username) {
      username
      groups
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($username: ID!) {
    getUser(username: $username) {
      username
      userCreateDate
      userLastModifiedDate
      enabled
      userStatus
      nameLast
      nameFirst
      email
      company
      companyRole
      allLayouts
      expiration
      __typename
    }
  }
`;
export const getNodeIdHistoryLessThanTimestamp = /* GraphQL */ `
  query GetNodeIdHistoryLessThanTimestamp(
    $filter: NodeIdHistoryFilterInput
    $nLocId: ID!
    $mLocId: ID!
    $layoutId: ID!
    $startTimestamp: Int!
    $limit: Int
    $nextToken: String
  ) {
    getNodeIdHistoryLessThanTimestamp(
      filter: $filter
      nLocId: $nLocId
      mLocId: $mLocId
      layoutId: $layoutId
      startTimestamp: $startTimestamp
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        layoutId
        mLocId
        nLocId
        nId
        timestamp
        serverTimestamp
        userId
        username
        mId
        masterName
        layoutName
        deltaAction
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMasterDataHistory = /* GraphQL */ `
  query ListMasterDataHistory($layoutId: ID!, $timestamp: Int!) {
    listMasterDataHistory(layoutId: $layoutId, timestamp: $timestamp) {
      layoutId
      timestamp
      items {
        layoutId
        mId
        mLocId
        activeBaseline
        alarms
        batteryVoltage
        bootTime
        chargeCurrent
        depth
        flags
        readStatus
        resetType
        stowLimitEast
        stowLimitWest
        stowModesActive
        stowModesEnabled
        tempBoard
        tempCharger
        timestamp
        trackingMode
        trueBaseline
        windAverage
        windPeak
        fwMaster
        fwNode
        arrrDuration
        windSensorType
        smartStowMode
        __typename
      }
      __typename
    }
  }
`;
export const listUserLayoutIds = /* GraphQL */ `
  query ListUserLayoutIds($limit: Int, $nextToken: String, $username: ID!) {
    listUserLayoutIds(
      limit: $limit
      nextToken: $nextToken
      username: $username
    ) {
      items
      nextToken
      __typename
    }
  }
`;
export const listUserLayouts = /* GraphQL */ `
  query ListUserLayouts($limit: Int, $nextToken: String) {
    listUserLayouts(limit: $limit, nextToken: $nextToken) {
      items {
        address1
        address2
        city
        deltaAction
        developerName
        id
        installerName
        labelZoomThreshold
        layoutUrl
        mLocIds
        markerRadius
        name
        nameAlternate
        customerName
        oAndMName
        ownerName
        powerCapacityDc
        published
        scadaName
        state
        utcOffset
        zip
        demo
        layoutNumber
        tz
        weathersmartEnabled
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($groupName: String) {
    listUsers(groupName: $groupName) {
      items {
        username
        userCreateDate
        userLastModifiedDate
        enabled
        userStatus
        nameLast
        nameFirst
        email
        company
        companyRole
        allLayouts
        expiration
        __typename
      }
      __typename
    }
  }
`;
export const listCommandsByMasterLocationAndTimestamp = /* GraphQL */ `
  query ListCommandsByMasterLocationAndTimestamp(
    $filter: CommandFilterInput
    $limit: Int
    $mLocId: ID!
    $nextToken: String
    $timestamp: String!
  ) {
    listCommandsByMasterLocationAndTimestamp(
      filter: $filter
      limit: $limit
      mLocId: $mLocId
      nextToken: $nextToken
      timestamp: $timestamp
    ) {
      items {
        layoutId
        mLocId
        mId
        masterName
        layoutName
        name
        userId
        username
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCommandsByMLocId = /* GraphQL */ `
  query ListCommandsByMLocId(
    $filter: CommandFilterInput
    $limit: Int
    $mLocId: ID!
    $nextToken: String
  ) {
    listCommandsByMLocId(
      filter: $filter
      limit: $limit
      mLocId: $mLocId
      nextToken: $nextToken
    ) {
      items {
        layoutId
        mLocId
        mId
        masterName
        layoutName
        name
        userId
        username
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCommandsByStatusCodeAndTimestamp = /* GraphQL */ `
  query ListCommandsByStatusCodeAndTimestamp(
    $filter: CommandFilterInput
    $limit: Int
    $nextToken: String
    $timestamp: String!
  ) {
    listCommandsByStatusCodeAndTimestamp(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      timestamp: $timestamp
    ) {
      items {
        layoutId
        mLocId
        mId
        masterName
        layoutName
        name
        userId
        username
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCommandsByTimestamp = /* GraphQL */ `
  query ListCommandsByTimestamp(
    $filter: CommandFilterInput
    $limit: Int
    $nextToken: String
    $timestamp: String!
  ) {
    listCommandsByTimestamp(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      timestamp: $timestamp
    ) {
      items {
        layoutId
        mLocId
        mId
        masterName
        layoutName
        name
        userId
        username
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLatestMasterDataByLayoutId = /* GraphQL */ `
  query ListLatestMasterDataByLayoutId(
    $filter: LatestMasterDataFilterInput
    $layoutId: String!
    $limit: Int
    $nextToken: String
  ) {
    listLatestMasterDataByLayoutId(
      filter: $filter
      layoutId: $layoutId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        layoutId
        mId
        mLocId
        activeBaseline
        alarms
        batteryVoltage
        bootTime
        chargeCurrent
        depth
        flags
        readStatus
        resetType
        stowLimitEast
        stowLimitWest
        stowModesActive
        stowModesEnabled
        tempBoard
        tempCharger
        timestamp
        trackingMode
        trueBaseline
        windAverage
        windPeak
        fwMaster
        fwNode
        arrrDuration
        windSensorType
        smartStowMode
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLatestMasterDataByMLocId = /* GraphQL */ `
  query ListLatestMasterDataByMLocId(
    $filter: LatestMasterDataFilterInput
    $layoutId: String!
    $limit: Int
    $mLocId: ID!
    $nextToken: String
  ) {
    listLatestMasterDataByMLocId(
      filter: $filter
      layoutId: $layoutId
      limit: $limit
      mLocId: $mLocId
      nextToken: $nextToken
    ) {
      items {
        layoutId
        mId
        mLocId
        activeBaseline
        alarms
        batteryVoltage
        bootTime
        chargeCurrent
        depth
        flags
        readStatus
        resetType
        stowLimitEast
        stowLimitWest
        stowModesActive
        stowModesEnabled
        tempBoard
        tempCharger
        timestamp
        trackingMode
        trueBaseline
        windAverage
        windPeak
        fwMaster
        fwNode
        arrrDuration
        windSensorType
        smartStowMode
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLatestNodeDailySummaries = /* GraphQL */ `
  query ListLatestNodeDailySummaries(
    $filter: LatestNodeDailySummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLatestNodeDailySummaries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        layoutId
        mId
        mLocId
        oAndMName
        ownerName
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLatestNodeDailySummariesByLayoutId = /* GraphQL */ `
  query ListLatestNodeDailySummariesByLayoutId(
    $filter: LatestNodeDailySummaryFilterInput
    $layoutId: String!
    $limit: Int
    $nextToken: String
  ) {
    listLatestNodeDailySummariesByLayoutId(
      filter: $filter
      layoutId: $layoutId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        layoutId
        mId
        mLocId
        oAndMName
        ownerName
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLatestNodeDailySummariesByMasterId = /* GraphQL */ `
  query ListLatestNodeDailySummariesByMasterId(
    $filter: LatestNodeDailySummaryFilterInput
    $limit: Int
    $mId: String!
    $nextToken: String
  ) {
    listLatestNodeDailySummariesByMasterId(
      filter: $filter
      limit: $limit
      mId: $mId
      nextToken: $nextToken
    ) {
      items {
        layoutId
        mId
        mLocId
        oAndMName
        ownerName
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLatestNodeDailySummariesByOAndMName = /* GraphQL */ `
  query ListLatestNodeDailySummariesByOAndMName(
    $filter: LatestNodeDailySummaryFilterInput
    $limit: Int
    $nextToken: String
    $oAndMName: String!
  ) {
    listLatestNodeDailySummariesByOAndMName(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      oAndMName: $oAndMName
    ) {
      items {
        layoutId
        mId
        mLocId
        oAndMName
        ownerName
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLatestNodeDailySummariesByOwnerName = /* GraphQL */ `
  query ListLatestNodeDailySummariesByOwnerName(
    $filter: LatestNodeDailySummaryFilterInput
    $limit: Int
    $nextToken: String
    $ownerName: String!
  ) {
    listLatestNodeDailySummariesByOwnerName(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      ownerName: $ownerName
    ) {
      items {
        layoutId
        mId
        mLocId
        oAndMName
        ownerName
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLatestNodeDataByMLocId = /* GraphQL */ `
  query ListLatestNodeDataByMLocId(
    $filter: LatestNodeDataFilterInput
    $layoutId: ID!
    $limit: Int
    $mLocId: ID!
    $nextToken: String
  ) {
    listLatestNodeDataByMLocId(
      filter: $filter
      layoutId: $layoutId
      limit: $limit
      mLocId: $mLocId
      nextToken: $nextToken
    ) {
      items {
        layoutId
        mLocId
        nId
        nLocId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLatestNodeDataByNLocId = /* GraphQL */ `
  query ListLatestNodeDataByNLocId(
    $filter: LatestNodeDataFilterInput
    $layoutId: ID!
    $limit: Int
    $mLocId: ID!
    $nextToken: String
    $nLocId: ID!
  ) {
    listLatestNodeDataByNLocId(
      filter: $filter
      layoutId: $layoutId
      limit: $limit
      mLocId: $mLocId
      nextToken: $nextToken
      nLocId: $nLocId
    ) {
      items {
        layoutId
        mLocId
        nId
        nLocId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLatestNodeDataSummaries = /* GraphQL */ `
  query ListLatestNodeDataSummaries(
    $filter: LatestNodeDataSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLatestNodeDataSummaries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        layoutId
        mId
        mLocId
        oAndMName
        ownerName
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLatestNodeDataSummariesByLayoutId = /* GraphQL */ `
  query ListLatestNodeDataSummariesByLayoutId(
    $filter: LatestNodeDataSummaryFilterInput
    $layoutId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listLatestNodeDataSummariesByLayoutId(
      filter: $filter
      layoutId: $layoutId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        layoutId
        mId
        mLocId
        oAndMName
        ownerName
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLatestNodeDataSummariesByMasterId = /* GraphQL */ `
  query ListLatestNodeDataSummariesByMasterId(
    $filter: LatestNodeDataSummaryFilterInput
    $limit: Int
    $mId: String!
    $nextToken: String
  ) {
    listLatestNodeDataSummariesByMasterId(
      filter: $filter
      limit: $limit
      mId: $mId
      nextToken: $nextToken
    ) {
      items {
        layoutId
        mId
        mLocId
        oAndMName
        ownerName
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLatestNodeDataSummariesByOAndMName = /* GraphQL */ `
  query ListLatestNodeDataSummariesByOAndMName(
    $filter: LatestNodeDataSummaryFilterInput
    $limit: Int
    $nextToken: String
    $oAndMName: String!
  ) {
    listLatestNodeDataSummariesByOAndMName(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      oAndMName: $oAndMName
    ) {
      items {
        layoutId
        mId
        mLocId
        oAndMName
        ownerName
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLatestNodeDataSummariesByOwnerName = /* GraphQL */ `
  query ListLatestNodeDataSummariesByOwnerName(
    $filter: LatestNodeDataSummaryFilterInput
    $limit: Int
    $nextToken: String
    $ownerName: String!
  ) {
    listLatestNodeDataSummariesByOwnerName(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      ownerName: $ownerName
    ) {
      items {
        layoutId
        mId
        mLocId
        oAndMName
        ownerName
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMasterConfigsByLayoutId = /* GraphQL */ `
  query ListMasterConfigsByLayoutId(
    $filter: MasterConfigFilterInput
    $layoutId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listMasterConfigsByLayoutId(
      filter: $filter
      layoutId: $layoutId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        layoutId
        mId
        mLocId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMasterIdHistoriesByMasterId = /* GraphQL */ `
  query ListMasterIdHistoriesByMasterId(
    $filter: MasterIdHistoryFilterInput
    $limit: Int
    $mId: String
    $nextToken: String
  ) {
    listMasterIdHistoriesByMasterId(
      filter: $filter
      limit: $limit
      mId: $mId
      nextToken: $nextToken
    ) {
      items {
        id
        layoutId
        mId
        mLocId
        timestamp
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMasterIdHistoriesByMLocId = /* GraphQL */ `
  query ListMasterIdHistoriesByMLocId(
    $filter: MasterIdHistoryFilterInput
    $limit: Int
    $mLocId: ID!
    $nextToken: String
  ) {
    listMasterIdHistoriesByMLocId(
      filter: $filter
      limit: $limit
      mLocId: $mLocId
      nextToken: $nextToken
    ) {
      items {
        id
        layoutId
        mId
        mLocId
        timestamp
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMasterLocations = /* GraphQL */ `
  query ListMasterLocations(
    $filter: MasterLocationFilterInput
    $layoutId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listMasterLocations(
      filter: $filter
      layoutId: $layoutId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        area
        channel
        deltaAction
        deviceType
        id
        layoutId
        mId
        name
        label
        panid
        spaParamId
        nodeParamId
        xLoc
        yLoc
        localIp
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listNodeLocationsByLayoutId = /* GraphQL */ `
  query ListNodeLocationsByLayoutId(
    $limit: Int
    $nextToken: String
    $layoutId: ID!
  ) {
    listNodeLocationsByLayoutId(
      limit: $limit
      nextToken: $nextToken
      layoutId: $layoutId
    ) {
      items {
        mLocId
        layoutId
        syncBitmasks
        deltaAction
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listReplacementCampaignInfoByLayoutId = /* GraphQL */ `
  query ListReplacementCampaignInfoByLayoutId(
    $limit: Int
    $nextToken: String
    $layoutId: ID!
  ) {
    listReplacementCampaignInfoByLayoutId(
      limit: $limit
      nextToken: $nextToken
      layoutId: $layoutId
    ) {
      items {
        layoutId
        mLocId
        timestamp
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMasterLocationsBySpaParamId = /* GraphQL */ `
  query ListMasterLocationsBySpaParamId(
    $filter: MasterLocationFilterInput
    $limit: Int
    $nextToken: String
    $spaParamId: ID!
  ) {
    listMasterLocationsBySpaParamId(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      spaParamId: $spaParamId
    ) {
      items {
        area
        channel
        deltaAction
        deviceType
        id
        layoutId
        mId
        name
        label
        panid
        spaParamId
        nodeParamId
        xLoc
        yLoc
        localIp
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listNodeConfigsByLayoutId = /* GraphQL */ `
  query ListNodeConfigsByLayoutId(
    $filter: NodeConfigFilterInput
    $layoutId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listNodeConfigsByLayoutId(
      filter: $filter
      layoutId: $layoutId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        layoutId
        mLocId
        nLocId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listNodeConfigsByMLocId = /* GraphQL */ `
  query ListNodeConfigsByMLocId(
    $filter: NodeConfigFilterInput
    $limit: Int
    $mLocId: ID!
    $nextToken: String
  ) {
    listNodeConfigsByMLocId(
      filter: $filter
      limit: $limit
      mLocId: $mLocId
      nextToken: $nextToken
    ) {
      items {
        layoutId
        mLocId
        nLocId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listNodeIdHistoriesByLayoutId = /* GraphQL */ `
  query ListNodeIdHistoriesByLayoutId(
    $filter: NodeIdHistoryFilterInput
    $layoutId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listNodeIdHistoriesByLayoutId(
      filter: $filter
      layoutId: $layoutId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        layoutId
        mLocId
        nLocId
        nId
        timestamp
        serverTimestamp
        userId
        username
        mId
        masterName
        layoutName
        deltaAction
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listNodeIdHistoriesByMLocId = /* GraphQL */ `
  query ListNodeIdHistoriesByMLocId(
    $filter: NodeIdHistoryFilterInput
    $limit: Int
    $mLocId: ID!
    $nextToken: String
  ) {
    listNodeIdHistoriesByMLocId(
      filter: $filter
      limit: $limit
      mLocId: $mLocId
      nextToken: $nextToken
    ) {
      items {
        layoutId
        mLocId
        nLocId
        nId
        timestamp
        serverTimestamp
        userId
        username
        mId
        masterName
        layoutName
        deltaAction
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listNodeIdHistoriesByNodeId = /* GraphQL */ `
  query ListNodeIdHistoriesByNodeId(
    $filter: NodeIdHistoryFilterInput
    $limit: Int
    $nextToken: String
    $nId: Int
  ) {
    listNodeIdHistoriesByNodeId(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      nId: $nId
    ) {
      items {
        layoutId
        mLocId
        nLocId
        nId
        timestamp
        serverTimestamp
        userId
        username
        mId
        masterName
        layoutName
        deltaAction
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listNodeIdHistoriesByNLocId = /* GraphQL */ `
  query ListNodeIdHistoriesByNLocId(
    $filter: NodeIdHistoryFilterInput
    $nLocId: ID!
    $mLocId: ID!
    $layoutId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listNodeIdHistoriesByNLocId(
      filter: $filter
      nLocId: $nLocId
      mLocId: $mLocId
      layoutId: $layoutId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        layoutId
        mLocId
        nLocId
        nId
        timestamp
        serverTimestamp
        userId
        username
        mId
        masterName
        layoutName
        deltaAction
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listNodeIdHistoriesByNLocIdAndTimestamp = /* GraphQL */ `
  query ListNodeIdHistoriesByNLocIdAndTimestamp(
    $filter: NodeIdHistoryFilterInput
    $nLocId: ID!
    $mLocId: ID!
    $layoutId: ID!
    $startTimestamp: Int!
    $endTimestamp: Int!
    $limit: Int
    $nextToken: String
  ) {
    listNodeIdHistoriesByNLocIdAndTimestamp(
      filter: $filter
      nLocId: $nLocId
      mLocId: $mLocId
      layoutId: $layoutId
      startTimestamp: $startTimestamp
      endTimestamp: $endTimestamp
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        layoutId
        mLocId
        nLocId
        nId
        timestamp
        serverTimestamp
        userId
        username
        mId
        masterName
        layoutName
        deltaAction
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listSpaParameters = /* GraphQL */ `
  query ListSpaParameters(
    $filter: SpaParametersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpaParameters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        mLocIds
        utcOffset
        deltaUt1
        deltaT
        longitude
        latitude
        elevation
        pressure
        aveTemp
        slope
        azmRotation
        atmosRefraction
        rowSpacing
        panelLength
        planeInclinationAngle
        slopeDirection
        mechLimitWest
        mechLimitEast
        overnightAngle
        overnightMoveStartHour
        overnightMoveEndHour
        antishadingEnabled
        strongestAngle
        snowStowAngle
        floodStowAngle
        fastPollingInterval
        nodeTemperatureCalibrationEnabled
        nodeTemperatureCalibrationInterval
        windStowTable
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listNodeParameters = /* GraphQL */ `
  query ListNodeParameters(
    $filter: NodeParametersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNodeParameters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        newNode
        lowBatteryLimit
        criticalBatteryLimit
        overvoltageThreshold
        highCurrentLimit
        lowBatteryStow
        numberofAngles
        motorOnTime
        motorOffTime
        motorOnTimeStow
        motorOffTimeStow
        angleTolerance
        startTolerance
        startToleranceAntishade
        motionTolerance
        noMotionTime
        directionTolerance
        hardwareCurrentLimit
        otaTimeout
        hotTempHiLimit
        hotTempLowLimit
        coldTempHiLimit
        coldTempLowLimit
        motionFailures
        motorMinSpeed
        rampOnTime
        rampDownPropCons
        overcurrentTries
        overcurrentTime
        arrrStatusIntervalMinute
        directionTime
        waitTimeMotorOff
        waitTimeChargerOff
        limitFailures
        limitTolerance
        directionFailures
        estopDisableTime
        lowBattThresholdDuringMotion
        nodeBattSocStowEnable
        nodeSocBattVoltOffset
        nodeBattSocStowTempHysteresis
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks($filter: TaskFilterInput, $limit: Int, $nextToken: String) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        mLocId
        timestamp
        userId
        username
        nId
        issueType
        layoutName
        masterName
        actuatorOn
        movesEastWest
        newNodeId
        commandQueue
        comment
        panelOn
        currentVoltageGood
        batteryGood
        goodPanelId
        badPanelId
        voltage
        current
        overCurrent
        lightOn
        eStopRespond
        eStopRespondReset
        tracking
        rmaNumbers
        rowNum
        fromSouth
        badActuatorModelNumber
        badActuatorProductionDate
        badActuatorVendorInitial
        badActuatorSerialNum
        goodActuatorModelNumber
        goodActuatorProductionDate
        goodActuatorVendorInitial
        goodActuatorSerialNum
        failPosition
        failAngle
        actuatorAbnormalities
        nodeIdMatch
        nodeFuse
        eStopPulled
        goodBatteryId
        badBatteryId
        goodBatteryVendor
        badBatteryVendor
        mechanicalIssues
        fixedWiring
        damageType
        goodChannel
        goodPanId
        badChannel
        badPanId
        targetedBattery
        retrofitComponent
        boardVendor
        bomAlternate
        bareBoardVersion
        generation
        buildDate
        batchNumber
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTasksByMLocId = /* GraphQL */ `
  query ListTasksByMLocId(
    $filter: TaskFilterInput
    $limit: Int
    $nextToken: String
    $mLocId: ID!
  ) {
    listTasksByMLocId(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      mLocId: $mLocId
    ) {
      items {
        mLocId
        timestamp
        userId
        username
        nId
        issueType
        layoutName
        masterName
        actuatorOn
        movesEastWest
        newNodeId
        commandQueue
        comment
        panelOn
        currentVoltageGood
        batteryGood
        goodPanelId
        badPanelId
        voltage
        current
        overCurrent
        lightOn
        eStopRespond
        eStopRespondReset
        tracking
        rmaNumbers
        rowNum
        fromSouth
        badActuatorModelNumber
        badActuatorProductionDate
        badActuatorVendorInitial
        badActuatorSerialNum
        goodActuatorModelNumber
        goodActuatorProductionDate
        goodActuatorVendorInitial
        goodActuatorSerialNum
        failPosition
        failAngle
        actuatorAbnormalities
        nodeIdMatch
        nodeFuse
        eStopPulled
        goodBatteryId
        badBatteryId
        goodBatteryVendor
        badBatteryVendor
        mechanicalIssues
        fixedWiring
        damageType
        goodChannel
        goodPanId
        badChannel
        badPanId
        targetedBattery
        retrofitComponent
        boardVendor
        bomAlternate
        bareBoardVersion
        generation
        buildDate
        batchNumber
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTasksByMLocIdAndTimestamp = /* GraphQL */ `
  query ListTasksByMLocIdAndTimestamp(
    $filter: TaskFilterInput
    $limit: Int
    $nextToken: String
    $mLocId: ID!
    $timestamp: Int!
  ) {
    listTasksByMLocIdAndTimestamp(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      mLocId: $mLocId
      timestamp: $timestamp
    ) {
      items {
        mLocId
        timestamp
        userId
        username
        nId
        issueType
        layoutName
        masterName
        actuatorOn
        movesEastWest
        newNodeId
        commandQueue
        comment
        panelOn
        currentVoltageGood
        batteryGood
        goodPanelId
        badPanelId
        voltage
        current
        overCurrent
        lightOn
        eStopRespond
        eStopRespondReset
        tracking
        rmaNumbers
        rowNum
        fromSouth
        badActuatorModelNumber
        badActuatorProductionDate
        badActuatorVendorInitial
        badActuatorSerialNum
        goodActuatorModelNumber
        goodActuatorProductionDate
        goodActuatorVendorInitial
        goodActuatorSerialNum
        failPosition
        failAngle
        actuatorAbnormalities
        nodeIdMatch
        nodeFuse
        eStopPulled
        goodBatteryId
        badBatteryId
        goodBatteryVendor
        badBatteryVendor
        mechanicalIssues
        fixedWiring
        damageType
        goodChannel
        goodPanId
        badChannel
        badPanId
        targetedBattery
        retrofitComponent
        boardVendor
        bomAlternate
        bareBoardVersion
        generation
        buildDate
        batchNumber
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listIncompleteTasks = /* GraphQL */ `
  query ListIncompleteTasks(
    $filter: TaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncompleteTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        mLocId
        timestamp
        userId
        username
        nId
        issueType
        layoutName
        masterName
        actuatorOn
        movesEastWest
        newNodeId
        commandQueue
        comment
        panelOn
        currentVoltageGood
        batteryGood
        goodPanelId
        badPanelId
        voltage
        current
        overCurrent
        lightOn
        eStopRespond
        eStopRespondReset
        tracking
        rmaNumbers
        rowNum
        fromSouth
        badActuatorModelNumber
        badActuatorProductionDate
        badActuatorVendorInitial
        badActuatorSerialNum
        goodActuatorModelNumber
        goodActuatorProductionDate
        goodActuatorVendorInitial
        goodActuatorSerialNum
        failPosition
        failAngle
        actuatorAbnormalities
        nodeIdMatch
        nodeFuse
        eStopPulled
        goodBatteryId
        badBatteryId
        goodBatteryVendor
        badBatteryVendor
        mechanicalIssues
        fixedWiring
        damageType
        goodChannel
        goodPanId
        badChannel
        badPanId
        targetedBattery
        retrofitComponent
        boardVendor
        bomAlternate
        bareBoardVersion
        generation
        buildDate
        batchNumber
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listIncompleteTasksByMLocId = /* GraphQL */ `
  query ListIncompleteTasksByMLocId(
    $filter: TaskFilterInput
    $limit: Int
    $nextToken: String
    $mLocId: ID!
  ) {
    listIncompleteTasksByMLocId(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      mLocId: $mLocId
    ) {
      items {
        mLocId
        timestamp
        userId
        username
        nId
        issueType
        layoutName
        masterName
        actuatorOn
        movesEastWest
        newNodeId
        commandQueue
        comment
        panelOn
        currentVoltageGood
        batteryGood
        goodPanelId
        badPanelId
        voltage
        current
        overCurrent
        lightOn
        eStopRespond
        eStopRespondReset
        tracking
        rmaNumbers
        rowNum
        fromSouth
        badActuatorModelNumber
        badActuatorProductionDate
        badActuatorVendorInitial
        badActuatorSerialNum
        goodActuatorModelNumber
        goodActuatorProductionDate
        goodActuatorVendorInitial
        goodActuatorSerialNum
        failPosition
        failAngle
        actuatorAbnormalities
        nodeIdMatch
        nodeFuse
        eStopPulled
        goodBatteryId
        badBatteryId
        goodBatteryVendor
        badBatteryVendor
        mechanicalIssues
        fixedWiring
        damageType
        goodChannel
        goodPanId
        badChannel
        badPanId
        targetedBattery
        retrofitComponent
        boardVendor
        bomAlternate
        bareBoardVersion
        generation
        buildDate
        batchNumber
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTasksByUser = /* GraphQL */ `
  query ListTasksByUser(
    $filter: TaskFilterInput
    $limit: Int
    $nextToken: String
    $userId: ID!
  ) {
    listTasksByUser(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      userId: $userId
    ) {
      items {
        mLocId
        timestamp
        userId
        username
        nId
        issueType
        layoutName
        masterName
        actuatorOn
        movesEastWest
        newNodeId
        commandQueue
        comment
        panelOn
        currentVoltageGood
        batteryGood
        goodPanelId
        badPanelId
        voltage
        current
        overCurrent
        lightOn
        eStopRespond
        eStopRespondReset
        tracking
        rmaNumbers
        rowNum
        fromSouth
        badActuatorModelNumber
        badActuatorProductionDate
        badActuatorVendorInitial
        badActuatorSerialNum
        goodActuatorModelNumber
        goodActuatorProductionDate
        goodActuatorVendorInitial
        goodActuatorSerialNum
        failPosition
        failAngle
        actuatorAbnormalities
        nodeIdMatch
        nodeFuse
        eStopPulled
        goodBatteryId
        badBatteryId
        goodBatteryVendor
        badBatteryVendor
        mechanicalIssues
        fixedWiring
        damageType
        goodChannel
        goodPanId
        badChannel
        badPanId
        targetedBattery
        retrofitComponent
        boardVendor
        bomAlternate
        bareBoardVersion
        generation
        buildDate
        batchNumber
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTasksByUserAndMLocId = /* GraphQL */ `
  query ListTasksByUserAndMLocId(
    $filter: TaskFilterInput
    $limit: Int
    $nextToken: String
    $userId: ID!
    $mLocId: ID!
  ) {
    listTasksByUserAndMLocId(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      userId: $userId
      mLocId: $mLocId
    ) {
      items {
        mLocId
        timestamp
        userId
        username
        nId
        issueType
        layoutName
        masterName
        actuatorOn
        movesEastWest
        newNodeId
        commandQueue
        comment
        panelOn
        currentVoltageGood
        batteryGood
        goodPanelId
        badPanelId
        voltage
        current
        overCurrent
        lightOn
        eStopRespond
        eStopRespondReset
        tracking
        rmaNumbers
        rowNum
        fromSouth
        badActuatorModelNumber
        badActuatorProductionDate
        badActuatorVendorInitial
        badActuatorSerialNum
        goodActuatorModelNumber
        goodActuatorProductionDate
        goodActuatorVendorInitial
        goodActuatorSerialNum
        failPosition
        failAngle
        actuatorAbnormalities
        nodeIdMatch
        nodeFuse
        eStopPulled
        goodBatteryId
        badBatteryId
        goodBatteryVendor
        badBatteryVendor
        mechanicalIssues
        fixedWiring
        damageType
        goodChannel
        goodPanId
        badChannel
        badPanId
        targetedBattery
        retrofitComponent
        boardVendor
        bomAlternate
        bareBoardVersion
        generation
        buildDate
        batchNumber
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTasksByUserAndTimestamp = /* GraphQL */ `
  query ListTasksByUserAndTimestamp(
    $filter: TaskFilterInput
    $limit: Int
    $nextToken: String
    $userId: ID!
    $timestamp: Int!
  ) {
    listTasksByUserAndTimestamp(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      userId: $userId
      timestamp: $timestamp
    ) {
      items {
        mLocId
        timestamp
        userId
        username
        nId
        issueType
        layoutName
        masterName
        actuatorOn
        movesEastWest
        newNodeId
        commandQueue
        comment
        panelOn
        currentVoltageGood
        batteryGood
        goodPanelId
        badPanelId
        voltage
        current
        overCurrent
        lightOn
        eStopRespond
        eStopRespondReset
        tracking
        rmaNumbers
        rowNum
        fromSouth
        badActuatorModelNumber
        badActuatorProductionDate
        badActuatorVendorInitial
        badActuatorSerialNum
        goodActuatorModelNumber
        goodActuatorProductionDate
        goodActuatorVendorInitial
        goodActuatorSerialNum
        failPosition
        failAngle
        actuatorAbnormalities
        nodeIdMatch
        nodeFuse
        eStopPulled
        goodBatteryId
        badBatteryId
        goodBatteryVendor
        badBatteryVendor
        mechanicalIssues
        fixedWiring
        damageType
        goodChannel
        goodPanId
        badChannel
        badPanId
        targetedBattery
        retrofitComponent
        boardVendor
        bomAlternate
        bareBoardVersion
        generation
        buildDate
        batchNumber
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTasksByUserAndMLocIdAndTimestamp = /* GraphQL */ `
  query ListTasksByUserAndMLocIdAndTimestamp(
    $filter: TaskFilterInput
    $limit: Int
    $nextToken: String
    $userId: ID!
    $mLocId: ID!
    $timestamp: Int!
  ) {
    listTasksByUserAndMLocIdAndTimestamp(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      userId: $userId
      mLocId: $mLocId
      timestamp: $timestamp
    ) {
      items {
        mLocId
        timestamp
        userId
        username
        nId
        issueType
        layoutName
        masterName
        actuatorOn
        movesEastWest
        newNodeId
        commandQueue
        comment
        panelOn
        currentVoltageGood
        batteryGood
        goodPanelId
        badPanelId
        voltage
        current
        overCurrent
        lightOn
        eStopRespond
        eStopRespondReset
        tracking
        rmaNumbers
        rowNum
        fromSouth
        badActuatorModelNumber
        badActuatorProductionDate
        badActuatorVendorInitial
        badActuatorSerialNum
        goodActuatorModelNumber
        goodActuatorProductionDate
        goodActuatorVendorInitial
        goodActuatorSerialNum
        failPosition
        failAngle
        actuatorAbnormalities
        nodeIdMatch
        nodeFuse
        eStopPulled
        goodBatteryId
        badBatteryId
        goodBatteryVendor
        badBatteryVendor
        mechanicalIssues
        fixedWiring
        damageType
        goodChannel
        goodPanId
        badChannel
        badPanId
        targetedBattery
        retrofitComponent
        boardVendor
        bomAlternate
        bareBoardVersion
        generation
        buildDate
        batchNumber
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listIncompleteTasksByUser = /* GraphQL */ `
  query ListIncompleteTasksByUser(
    $filter: TaskFilterInput
    $limit: Int
    $nextToken: String
    $userId: ID!
  ) {
    listIncompleteTasksByUser(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      userId: $userId
    ) {
      items {
        mLocId
        timestamp
        userId
        username
        nId
        issueType
        layoutName
        masterName
        actuatorOn
        movesEastWest
        newNodeId
        commandQueue
        comment
        panelOn
        currentVoltageGood
        batteryGood
        goodPanelId
        badPanelId
        voltage
        current
        overCurrent
        lightOn
        eStopRespond
        eStopRespondReset
        tracking
        rmaNumbers
        rowNum
        fromSouth
        badActuatorModelNumber
        badActuatorProductionDate
        badActuatorVendorInitial
        badActuatorSerialNum
        goodActuatorModelNumber
        goodActuatorProductionDate
        goodActuatorVendorInitial
        goodActuatorSerialNum
        failPosition
        failAngle
        actuatorAbnormalities
        nodeIdMatch
        nodeFuse
        eStopPulled
        goodBatteryId
        badBatteryId
        goodBatteryVendor
        badBatteryVendor
        mechanicalIssues
        fixedWiring
        damageType
        goodChannel
        goodPanId
        badChannel
        badPanId
        targetedBattery
        retrofitComponent
        boardVendor
        bomAlternate
        bareBoardVersion
        generation
        buildDate
        batchNumber
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listIncompleteTasksByUserAndMLocId = /* GraphQL */ `
  query ListIncompleteTasksByUserAndMLocId(
    $filter: TaskFilterInput
    $limit: Int
    $nextToken: String
    $userId: ID!
    $mLocId: ID!
  ) {
    listIncompleteTasksByUserAndMLocId(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      userId: $userId
      mLocId: $mLocId
    ) {
      items {
        mLocId
        timestamp
        userId
        username
        nId
        issueType
        layoutName
        masterName
        actuatorOn
        movesEastWest
        newNodeId
        commandQueue
        comment
        panelOn
        currentVoltageGood
        batteryGood
        goodPanelId
        badPanelId
        voltage
        current
        overCurrent
        lightOn
        eStopRespond
        eStopRespondReset
        tracking
        rmaNumbers
        rowNum
        fromSouth
        badActuatorModelNumber
        badActuatorProductionDate
        badActuatorVendorInitial
        badActuatorSerialNum
        goodActuatorModelNumber
        goodActuatorProductionDate
        goodActuatorVendorInitial
        goodActuatorSerialNum
        failPosition
        failAngle
        actuatorAbnormalities
        nodeIdMatch
        nodeFuse
        eStopPulled
        goodBatteryId
        badBatteryId
        goodBatteryVendor
        badBatteryVendor
        mechanicalIssues
        fixedWiring
        damageType
        goodChannel
        goodPanId
        badChannel
        badPanId
        targetedBattery
        retrofitComponent
        boardVendor
        bomAlternate
        bareBoardVersion
        generation
        buildDate
        batchNumber
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUserTasks = /* GraphQL */ `
  query ListUserTasks(
    $filter: TaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        mLocId
        timestamp
        userId
        username
        nId
        issueType
        layoutName
        masterName
        actuatorOn
        movesEastWest
        newNodeId
        commandQueue
        comment
        panelOn
        currentVoltageGood
        batteryGood
        goodPanelId
        badPanelId
        voltage
        current
        overCurrent
        lightOn
        eStopRespond
        eStopRespondReset
        tracking
        rmaNumbers
        rowNum
        fromSouth
        badActuatorModelNumber
        badActuatorProductionDate
        badActuatorVendorInitial
        badActuatorSerialNum
        goodActuatorModelNumber
        goodActuatorProductionDate
        goodActuatorVendorInitial
        goodActuatorSerialNum
        failPosition
        failAngle
        actuatorAbnormalities
        nodeIdMatch
        nodeFuse
        eStopPulled
        goodBatteryId
        badBatteryId
        goodBatteryVendor
        badBatteryVendor
        mechanicalIssues
        fixedWiring
        damageType
        goodChannel
        goodPanId
        badChannel
        badPanId
        targetedBattery
        retrofitComponent
        boardVendor
        bomAlternate
        bareBoardVersion
        generation
        buildDate
        batchNumber
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUserTasksByMLocId = /* GraphQL */ `
  query ListUserTasksByMLocId(
    $filter: TaskFilterInput
    $limit: Int
    $nextToken: String
    $mLocId: ID!
  ) {
    listUserTasksByMLocId(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      mLocId: $mLocId
    ) {
      items {
        mLocId
        timestamp
        userId
        username
        nId
        issueType
        layoutName
        masterName
        actuatorOn
        movesEastWest
        newNodeId
        commandQueue
        comment
        panelOn
        currentVoltageGood
        batteryGood
        goodPanelId
        badPanelId
        voltage
        current
        overCurrent
        lightOn
        eStopRespond
        eStopRespondReset
        tracking
        rmaNumbers
        rowNum
        fromSouth
        badActuatorModelNumber
        badActuatorProductionDate
        badActuatorVendorInitial
        badActuatorSerialNum
        goodActuatorModelNumber
        goodActuatorProductionDate
        goodActuatorVendorInitial
        goodActuatorSerialNum
        failPosition
        failAngle
        actuatorAbnormalities
        nodeIdMatch
        nodeFuse
        eStopPulled
        goodBatteryId
        badBatteryId
        goodBatteryVendor
        badBatteryVendor
        mechanicalIssues
        fixedWiring
        damageType
        goodChannel
        goodPanId
        badChannel
        badPanId
        targetedBattery
        retrofitComponent
        boardVendor
        bomAlternate
        bareBoardVersion
        generation
        buildDate
        batchNumber
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUserTasksByTimestamp = /* GraphQL */ `
  query ListUserTasksByTimestamp(
    $filter: TaskFilterInput
    $limit: Int
    $nextToken: String
    $timestamp: Int!
  ) {
    listUserTasksByTimestamp(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      timestamp: $timestamp
    ) {
      items {
        mLocId
        timestamp
        userId
        username
        nId
        issueType
        layoutName
        masterName
        actuatorOn
        movesEastWest
        newNodeId
        commandQueue
        comment
        panelOn
        currentVoltageGood
        batteryGood
        goodPanelId
        badPanelId
        voltage
        current
        overCurrent
        lightOn
        eStopRespond
        eStopRespondReset
        tracking
        rmaNumbers
        rowNum
        fromSouth
        badActuatorModelNumber
        badActuatorProductionDate
        badActuatorVendorInitial
        badActuatorSerialNum
        goodActuatorModelNumber
        goodActuatorProductionDate
        goodActuatorVendorInitial
        goodActuatorSerialNum
        failPosition
        failAngle
        actuatorAbnormalities
        nodeIdMatch
        nodeFuse
        eStopPulled
        goodBatteryId
        badBatteryId
        goodBatteryVendor
        badBatteryVendor
        mechanicalIssues
        fixedWiring
        damageType
        goodChannel
        goodPanId
        badChannel
        badPanId
        targetedBattery
        retrofitComponent
        boardVendor
        bomAlternate
        bareBoardVersion
        generation
        buildDate
        batchNumber
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUserTasksByMLocIdAndTimestamp = /* GraphQL */ `
  query ListUserTasksByMLocIdAndTimestamp(
    $filter: TaskFilterInput
    $limit: Int
    $nextToken: String
    $mLocId: ID!
    $timestamp: Int!
  ) {
    listUserTasksByMLocIdAndTimestamp(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      mLocId: $mLocId
      timestamp: $timestamp
    ) {
      items {
        mLocId
        timestamp
        userId
        username
        nId
        issueType
        layoutName
        masterName
        actuatorOn
        movesEastWest
        newNodeId
        commandQueue
        comment
        panelOn
        currentVoltageGood
        batteryGood
        goodPanelId
        badPanelId
        voltage
        current
        overCurrent
        lightOn
        eStopRespond
        eStopRespondReset
        tracking
        rmaNumbers
        rowNum
        fromSouth
        badActuatorModelNumber
        badActuatorProductionDate
        badActuatorVendorInitial
        badActuatorSerialNum
        goodActuatorModelNumber
        goodActuatorProductionDate
        goodActuatorVendorInitial
        goodActuatorSerialNum
        failPosition
        failAngle
        actuatorAbnormalities
        nodeIdMatch
        nodeFuse
        eStopPulled
        goodBatteryId
        badBatteryId
        goodBatteryVendor
        badBatteryVendor
        mechanicalIssues
        fixedWiring
        damageType
        goodChannel
        goodPanId
        badChannel
        badPanId
        targetedBattery
        retrofitComponent
        boardVendor
        bomAlternate
        bareBoardVersion
        generation
        buildDate
        batchNumber
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUserIncompleteTasks = /* GraphQL */ `
  query ListUserIncompleteTasks(
    $filter: TaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserIncompleteTasks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        mLocId
        timestamp
        userId
        username
        nId
        issueType
        layoutName
        masterName
        actuatorOn
        movesEastWest
        newNodeId
        commandQueue
        comment
        panelOn
        currentVoltageGood
        batteryGood
        goodPanelId
        badPanelId
        voltage
        current
        overCurrent
        lightOn
        eStopRespond
        eStopRespondReset
        tracking
        rmaNumbers
        rowNum
        fromSouth
        badActuatorModelNumber
        badActuatorProductionDate
        badActuatorVendorInitial
        badActuatorSerialNum
        goodActuatorModelNumber
        goodActuatorProductionDate
        goodActuatorVendorInitial
        goodActuatorSerialNum
        failPosition
        failAngle
        actuatorAbnormalities
        nodeIdMatch
        nodeFuse
        eStopPulled
        goodBatteryId
        badBatteryId
        goodBatteryVendor
        badBatteryVendor
        mechanicalIssues
        fixedWiring
        damageType
        goodChannel
        goodPanId
        badChannel
        badPanId
        targetedBattery
        retrofitComponent
        boardVendor
        bomAlternate
        bareBoardVersion
        generation
        buildDate
        batchNumber
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUserIncompleteTasksByMLocId = /* GraphQL */ `
  query ListUserIncompleteTasksByMLocId(
    $filter: TaskFilterInput
    $limit: Int
    $nextToken: String
    $mLocId: ID!
  ) {
    listUserIncompleteTasksByMLocId(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      mLocId: $mLocId
    ) {
      items {
        mLocId
        timestamp
        userId
        username
        nId
        issueType
        layoutName
        masterName
        actuatorOn
        movesEastWest
        newNodeId
        commandQueue
        comment
        panelOn
        currentVoltageGood
        batteryGood
        goodPanelId
        badPanelId
        voltage
        current
        overCurrent
        lightOn
        eStopRespond
        eStopRespondReset
        tracking
        rmaNumbers
        rowNum
        fromSouth
        badActuatorModelNumber
        badActuatorProductionDate
        badActuatorVendorInitial
        badActuatorSerialNum
        goodActuatorModelNumber
        goodActuatorProductionDate
        goodActuatorVendorInitial
        goodActuatorSerialNum
        failPosition
        failAngle
        actuatorAbnormalities
        nodeIdMatch
        nodeFuse
        eStopPulled
        goodBatteryId
        badBatteryId
        goodBatteryVendor
        badBatteryVendor
        mechanicalIssues
        fixedWiring
        damageType
        goodChannel
        goodPanId
        badChannel
        badPanId
        targetedBattery
        retrofitComponent
        boardVendor
        bomAlternate
        bareBoardVersion
        generation
        buildDate
        batchNumber
        __typename
      }
      nextToken
      __typename
    }
  }
`;
